import styled from 'styled-components'
import { Colors } from "../../core/brand/themes"

export const FilterButton = styled.div(({
    width: "12px",
    height: "12px",
    backgroundColor: Colors.white(),
    color: "blue",
    textAlign: "center",
    margin: "0 6px 0 0",
    boxSizing: "border-box",
    cursor: "pointer",
    lineHeight: "12px",
}))
