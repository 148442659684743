import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { Navigate, useLocation } from "react-router-dom";
import Role from "../enums/Role";
import { ProgressionSteps } from "../enums/ProgressionSteps"
import Permission from "../enums/Permission";
import { useRedirectToNewWebApp } from "./useRedirectToNewWebApp";

const UnauthorizedRedirect = ({
    children,
    roles,
    permission
}: { children: JSX.Element, roles: number[] | null, permission?: Permission }) => {
    const { loggedInUser, loading } = useLoggedInUser();
    const shouldRedirect = useRedirectToNewWebApp(loggedInUser);
    const location = useLocation();

    if (loading) return null

    if (shouldRedirect) return null;

    if (!loggedInUser) {
        return (
            <Navigate to="/Account/Login" replace />
        )
    }

    if (location.pathname !== '/Account/UpdatedTermsOfUse'
        && loggedInUser
        && !Role.isSiteRole(loggedInUser?.roleID)
        && !loggedInUser?.completedSteps?.includes(ProgressionSteps.TermsOfUse20230101)) {
        return (
            <Navigate to="/Account/UpdatedTermsOfUse" replace />
        )
    }

    if ((loggedInUser?.roleID || 0) >= 100 && permission && loggedInUser.permissions && (loggedInUser.permissions[permission]?.accessLevel || 0) < 1) {
        const link = Role.getDashLink(loggedInUser.roleID);

        return (
            <Navigate to={link.link!} replace />
        )
    }

    // TODO: Clean up these if statements
    if (roles && loggedInUser?.roleID && (!permission || loggedInUser.roleID < 100) && !roles.includes(loggedInUser?.roleID)) {
        const link = Role.getDashLink(loggedInUser.roleID);

        return (
            <Navigate to={link.link!} replace />
        )
    }

    return children
};

export default UnauthorizedRedirect;
