import React from "react"
import { strings } from "../../content/strings"
import { Display, H1, H2, PBold } from "../brand/typography"

const ExceptionPage = () => {
    return (
        <div style={{ width: "100%", padding: "24px", boxSizing: "border-box" }}>
            <Display>
                {strings.sorry}
            </Display>
            <H1>
                {strings.somethingWentWrongLong}
            </H1>
            <H2 style={{ marginTop: "40px" }}>
                {strings.weAreNotified}
            </H2>
        </div>
    )
}

export const ExceptionForm = () => {
    return (
        <div style={{ width: "100%", padding: "24px", boxSizing: "border-box" }}>
            <H1>
                {strings.sorry}
            </H1>
            <PBold>
                {strings.somethingWentWrongLong}
            </PBold>
            <PBold style={{ margin: "16px auto 0 auto", width: "80%" }}>
                {strings.weAreNotified}
            </PBold>
        </div>
    )
}

export default ExceptionPage
