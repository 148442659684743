import React from "react";
import { Tooltip } from "@mui/material";

export const ConditionalTooltip = ({ children, enabled, message, placement }: { children: any; enabled: boolean; message: string; placement?: "left" | "right" | "bottom" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start"; }) => {
    if (enabled) {
        return (
            <Tooltip
                title={message}
                placement={placement}
                arrow
                componentsProps={{
                    tooltip: { height: "40px", sx: { fontSize: "0.9rem", my: 0.5 }, }
                }}
            >
                {children}
            </Tooltip>
        );
    }
    return children;
};
