interface PaginationResult {
    paginationRange: number[],
    showRightDots: boolean,
    showLeftDots: boolean,
}

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = (currentPage: number, itemsPerPage: number, itemsTotal: number): PaginationResult => {
    const totalRange = Math.ceil(itemsTotal / itemsPerPage); // ==10
    const showRightDots = Math.min(currentPage + 1, itemsTotal) < totalRange - 2; // false
    const showLeftDots = Math.max(currentPage - 1, 1) > 2;
    if (totalRange <= 5) {
        return { paginationRange: range(1, totalRange), showRightDots: false, showLeftDots }
    }

    if (showRightDots && !showLeftDots) {
        return { paginationRange: range(1, 5), showRightDots, showLeftDots }
    }

    if (showLeftDots && !showRightDots) {
        return ({ paginationRange: range(totalRange - 4, totalRange), showRightDots, showLeftDots })
    }

    if (showLeftDots && showRightDots) {
        return ({ paginationRange: range(currentPage - 2, currentPage + 2), showRightDots, showLeftDots })
    }

    return { paginationRange: range(1, totalRange), showRightDots, showLeftDots }
}
