
export const events = {
    allowEditUnlockCode: "Allow Edit Unlock code",
    closeForm: "closeForm",
    downloadCSV: "Download CSV",
    nextStep: "Next Step",
    previousStep: "Previous Step",
    printLocks: "Print Locks",
    refreshUnitNumbers: "Refresh Unit Numbers",
    selectFacility: "Select Facility",
    selectNoReport: "Select No Report",
    selectReceivingUser: "Select Receiving User",
    selectReport: "Select Report",
    showAddApiKeyForm: "Show Add API Key Form",
    showAddFacilityForm: "Show Add Facility Form",
    showAddUserForm: "Show Add User Form",
    showEditApiKeyForm: "Show Edit API Key Form",
    showEditFacilityForm: "Show Edit Facility Form",
    showEditLock: "Show Edit Lock",
    showEditUserForm: "Show Edit User Form",
    submit: "Submit",
    transferLock: "Transfer Lock",
};
