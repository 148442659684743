import axios from "axios"

const getBaseUrl = () => {
    if (location.host.includes("localhost")) {
        return "https://localhost:44300"
    }

    if (location.host.includes("orange-river")) {
        return "https://www.davincilock.com"
    }

    return location.origin
}

const getDotNet7Url = () => {
    if (location.host.includes("localhost")) {
        return "https://localhost:44327"
    }

    if (location.host.includes("staging")) {
        return "https://dvl-new-staging.azurewebsites.net"
    }

    if (location.host.includes("test")) {
        return "https://api.davincilocktest.com"
    }

    if (location.host.includes("dev")) {
        return "https://api.davincilockdev.com"
    }

    return "https://api.davincilock.com"
}

const getNewWebServerUrl = () => {
    if (location.host.includes("localhost")) {
        return "http://localhost:5173"
    }

    if (location.host.includes("staging")) {
        return "https://ashy-moss-05395b410.4.azurestaticapps.net"
    }

    if (location.host.includes("test")) {
        return "https://app.davincilocktest.com"
    }

    if (location.host.includes("dev")) {
        return "https://app.davincilockdev.com"
    }

    return "https://app.davincilock.com"
}

const getSentryKey = () => {
    if (
        location.host.includes("localhost")
        || location.host.includes("ngrok")
        || location.host.includes("dev")
        || location.host.includes("test")) {
        // dev key
        return "https://e1cc9887b4e2431c860b0e9e36b2adbd@o4504499185647616.ingest.sentry.io/4504499198361600"
    }

    if (location.host.includes("staging")) {
        return "https://4c00e30a8e0c67cb02f9767472437761@o4504499185647616.ingest.sentry.io/4505782696345600"
    }

    // production key
    return "https://a3f6d09d41cd4961bdcdc6fdd446c6c4@o4504499185647616.ingest.sentry.io/4504499195805696"
}

const environment = {
    baseURL: getBaseUrl(),
    dotnet7Url: getDotNet7Url(),
    newWebServerUrl: getNewWebServerUrl(),
    local: location.host.includes("localhost"),
    sentryDNS: "",
    prod: location.host.includes("davincilock.com")
}

const loadLocal = () => {
    environment.local = true
}

export const loadEnvironment = () => {
    environment.baseURL = getBaseUrl()
    axios.defaults.baseURL = environment.baseURL
    environment.sentryDNS = getSentryKey()
    if (location.host.includes("localhost")) {
        loadLocal()
    }
}

export default environment;
