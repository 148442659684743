import React from "react";
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import logo from "../../../assets/images/logo.png";
import disc from "../../../assets/images/disc.png";
import highcollar from "../../../assets/images/highcollar.png";
import { PrimaryLink } from "../../../components/button"
import links from "../../../routes/links"
import { strings } from "../../../content/strings"
import { HorizontalGrid, GridItem } from "../../../components/styled/boxes"
import { H2, H3, PThin } from "../../../core/brand/typography";
import { Section, SectionProps } from "../../../core/layout/layouts";
import styled from "styled-components";
import Box from "@mui/material/Box";

const MarketingSection = () => {
    const [showFullButtons] = useMediaQuery(768);
    const locks = [disc, highcollar]
    return (
        <Container>
            <SectionWrapper>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 0 56px 0",
                    width: "100%"
                }}
                >
                    <img src={logo} />
                </Box>
                <Box sx={{ m: "4px 8px 24px 8px" }}>
                    <H2>{strings.slogan}</H2>
                </Box>

                {showFullButtons ? (
                    <Box sx={{ width: "100%", mt: "16px" }}>
                        <PrimaryLink fullWidth {...links.HOWITWORKS}>{strings.howItWorks} </PrimaryLink>
                        <PrimaryLink fullWidth {...links.LEADFORM}> {strings.requestDemo} </PrimaryLink>
                        <PrimaryLink fullWidth {...links.SUPPORT}> {strings.support} </PrimaryLink>
                    </Box>
                ) : (
                    <HorizontalGrid id="" items={[
                        GridItem({ el: <div />, span: 0.25 }),
                        GridItem({ el: <PrimaryLink {...links.HOWITWORKS}>{strings.howItWorks} </PrimaryLink>, span: 3 }),
                        GridItem({ el: <div />, span: 1 }),
                        GridItem({ el: <PrimaryLink {...links.LEADFORM}> {strings.requestDemo} </PrimaryLink>, span: 3 }),
                        GridItem({ el: <div />, span: 1 }),
                        GridItem({ el: <PrimaryLink {...links.SUPPORT}> {strings.support} </PrimaryLink>, span: 3 }),
                        GridItem({ el: <div />, span: 0.25 }),
                    ]} />
                )}
            </SectionWrapper>

            <Box sx={{ textAlign: "center", mt: "16px" }} >
                <Box sx={{ m: "42px 0 16px 0" }} >
                    <H3 >{strings.bestLock}</H3>
                </Box>
                <TextContainer >
                    <PThin>{strings.lockSystem}</PThin>
                </TextContainer>
            </Box>
            <HorizontalGrid fixedWidth="80%" id="umm" items={
                locks.map((_data, idx) => {
                    return (<img style={{ margin: "auto", objectFit: "contain", width: "75%", maxHeight: "364px" }} key={idx} alt="" src={_data} />)
                })
            }
            />
        </Container>
    )
}

const Container = styled(Section)<SectionProps>((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    marginBottom: '24px',
    backgroundSize: '100%',
    backgroundPositionX: '50%',
    backgroundPositionY: '28px',
    backgroundRepeat: 'repeat',
    backgroundImage: `url(${props.backgroundImage})top`
}));

const TextContainer = styled.div({
    width: "60%",
    margin: "0 auto",
    "@media (max-width:768px)": {
        width: "100%"
    },
})

const SectionWrapper = styled.div({
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justify: "center",
    width: "100%",
    margin: "28px 0 0 0",
    padding: "0",
    alignItems: "center"
})

export default MarketingSection
