import React from "react"
import { usePagination } from "../../hooks/usePagination";
import PaginationDropDown from "./PaginationDropDown";
import styled from "styled-components";
import { Colors } from "../brand/themes";
import { strings } from "../../content/strings";
import { faForward, faForwardFast, faBackward, faBackwardFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IPaginationData {
    page: number,
    itemsPerPage: number,
}

interface PaginationProps {
    itemsTotal: number,
    setPaginationData: (arg0: any) => void,
    paginationData: IPaginationData,
    options?: number[]
}

const Pagination = ({ paginationData, itemsTotal, setPaginationData, options }: PaginationProps) => {
    const { page, itemsPerPage } = paginationData
    const { paginationRange, showRightDots, showLeftDots } = usePagination(page, itemsPerPage, itemsTotal)
    const lastPage = Math.ceil(itemsTotal / itemsPerPage);

    const handlePageNumClick = (number: number) => {
        setPaginationData({
            ...paginationData,
            page: number
        })
    }

    const handleRowsPerPageChange = (newItemsPerPage: number) => {
        const currentStartingRow = (paginationData.page - 1) * paginationData.itemsPerPage
        const newCurrentPage = Math.floor((
            currentStartingRow / newItemsPerPage
        )) + 1

        setPaginationData({ page: newCurrentPage, itemsPerPage: newItemsPerPage })
    }
    const PaginationIcon = ({ disabled, onClick, icon }: { disabled: boolean, onClick: () => void, icon: any }) => {
        return (
            <span onClick={onClick} style={{ cursor: "pointer", padding: "4px" }}>
                <FontAwesomeIcon icon={icon} color={disabled ? Colors.grey() : Colors.red()}></FontAwesomeIcon>
            </span>
        )
    }
    const disableBackwards = paginationData.page == 1
    const disableForwards = lastPage == paginationData.page

    return (
        <PaginatedSpan>
            <PaginationIcon icon={faBackwardFast} disabled={disableBackwards} onClick={() => setPaginationData((prevState: IPaginationData) => ({ ...prevState, page: 1 }))} />
            <PaginationIcon icon={faBackward} disabled={disableBackwards} onClick={() => paginationData.page != 1 &&
                setPaginationData((prevState: IPaginationData) => ({
                    ...prevState,
                    page: prevState.page -= 1
                }))} />
            {showLeftDots && <PageNumberDiv
                onClick={() => setPaginationData((prevState: IPaginationData) => ({
                    ...prevState,
                    page: Math.max(1, prevState.page -= 5)
                }))}>...</PageNumberDiv>}
            {paginationRange.map((num, idx) => {
                return (<PageNumberDiv onClick={() => handlePageNumClick(num)} data-number={num} active={num === paginationData.page} key={idx}>{num}</PageNumberDiv>)
            })}
            {showRightDots && <PageNumberDiv onClick={() => setPaginationData((prevState: IPaginationData) => ({
                ...prevState,
                page: Math.min(lastPage, prevState.page += 5)
            }))}>...</PageNumberDiv>}

            <PaginationIcon icon={faForward} disabled={disableForwards} onClick={() => paginationData.page != lastPage &&
                setPaginationData((prevState: IPaginationData) => ({
                    ...prevState,
                    page: prevState.page += 1
                }))} />
            <PaginationIcon icon={faForwardFast} disabled={disableForwards}
                onClick={() => setPaginationData((prevState: IPaginationData) => ({ ...prevState, page: Math.ceil(itemsTotal / prevState.itemsPerPage) }))}
            />
            {options && <PaginationDropDown selectedItem={itemsPerPage}
                leftMargin
                dropDownText={strings.itemsPerPage}
                dropDownData={options}
                setter={handleRowsPerPageChange} ></PaginationDropDown>}
            {itemsTotal &&
                <TotalItems>
                    {strings.itemsTotal(itemsTotal)}
                </TotalItems>
            }
        </PaginatedSpan>
    )
}

const PaginatedSpan = styled.span({
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    alignSelf: "end",
    borderTop: "1px solid black",
    padding: "12px 4px 0 4px",
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
})

const PageNumberDiv = styled.div<{ active?: boolean }>((props) => ({
    padding: "4px 8px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    ...(props.active ? { background: Colors.yellow() } : {})
}))

const TotalItems = styled.div({
    position: "absolute",
    right: 16,
    color: Colors.black(),
    padding: "4px"
})

export default Pagination;
