import { Card } from "../../../core/surfaces/Card";
import styled from "styled-components";
import HubSpotForm from "./HubSpotForm";
import { H2 } from "../../../core/brand/typography";
import { strings } from "../../../content/strings";

const LeadFormContainer = () => {

    return (
        <FormCard>
            <TitleContainer>
                <H2>{strings.getStarted}</H2>
            </TitleContainer>
            <HubSpotForm />
        </FormCard>
    )
}

const FormCard = styled(Card)({
    width: "480px",
    position: "relative",
    paddingBottom: "24px",
    padding: "24px"
});

export const TwoColumnGrid = styled.div({
    paddingTop: "16px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "30px",
    rowGap: "16px",
    "@media(max-width:575px)": {
        gridTemplateColumns: "1fr",
    }
});

const TitleContainer = styled.div({
    display: "grid",
    rowGap: "8px",
    marginBottom: "24px",
    textAlign: "center"
})

export default LeadFormContainer;
