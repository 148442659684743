export enum MessagePreference {
    "DISABLED" = 0,
    "SMS" = 1,
    "EMAIL" = 2,
    "SMS AND EMAIL" = 3,
}

export enum MessagesEnum {
    "New Move In" = 1,
    "Delinquent Bill" = 2,
    "Text to Release Approved" = 3,
    "Text to Release Denied" = 4,
}

export type MessagesEnumKeys = keyof typeof MessagesEnum;
