import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomRole } from "../../models/CustomRole"
import agents from '../../api/agent';
import { strings } from '../../content/strings';
import { RootState } from '../store';

interface ICustomRolesState {
    customRoles: CustomRole[],
    status: "awaiting" | "fetched",
    pending?: boolean,
    error?: any,
}

const initialState: ICustomRolesState = {
    customRoles: [],
    status: 'awaiting',
}

// HERE
const fetchCustomRoles = async (store: any, companyID?: number) => {
    const response = await agents.Settings.getRolesByCompanyId(companyID);

    if (response.data) {
        return response.data;
    }
    return store.rejectWithValue(response.error || strings.somethingWentWrong)
}

export const fetchCustomRolesAsync = createAsyncThunk(
    "CustomRoles/fetchCustomRolesAsync",
    (_, store) => fetchCustomRoles(store)
)

export const fetchCompanyRolesAsync = createAsyncThunk(
    "CustomRoles/fetchCustomRolesAsync",
    (companyID: number, store) => fetchCustomRoles(store, companyID)
)

export const CustomRolesSlice = createSlice({
    name: "CustomRoles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomRolesAsync.pending, (state) => {
                return { ...state, pending: true }
            })
            .addCase(fetchCustomRolesAsync.fulfilled, (_, action: PayloadAction<any>) => {
                return { customRoles: action.payload, status: "fetched", pending: false }
            })
            .addCase(fetchCustomRolesAsync.rejected, (state, action: PayloadAction<any>) => {
                return { ...state, error: action.payload }
            })
    }
})

export const getCustomRoles = (state: RootState) => state.customRoles;
