import React from "react";
import Routes from "./routes/Routes"
import "./index.css"
import "./fonts.css"
import Providers from "./contexts/Providers";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import CompanyDetailsMiddleware from "./middleware/CompanyDetailsMiddleware";
import { GoogleScriptWrapper } from "./middleware/GoogleScriptWrapper";
import { CountryProvider } from "./contexts/CountryProvider"

function App() {
    return (
        <div className="App">
            <CountryProvider>
                <GoogleScriptWrapper>
                    <Router>
                        <CompanyDetailsMiddleware>
                            <Providers>
                                <Routes />
                            </Providers>
                        </CompanyDetailsMiddleware>
                    </Router>
                </GoogleScriptWrapper>
            </CountryProvider>
        </div >
    )
}

export default App;
