import React from 'react';
import { useMediaQuery } from "../../hooks/hooks";
import { H1 } from "../../core/brand/typography"

const TableTitle = ({ title }: { title: string }) => {
    const [mobile] = useMediaQuery(414);

    return (
        <H1 style={{ textAlign: "start", marginLeft: '8px', marginRight: mobile ? 0 : "auto" }}>{title}</H1>

    );
}

export default TableTitle;
