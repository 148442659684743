import React, { useState } from "react";
import Input from "../../../core/form-components/Input";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip/Tooltip"
import { PrimaryButton } from "../../../components/button";
import { strings } from "../../../content/strings";
import { events as analyticsEvents } from "../../../mixPanelEvents";

const ButtonWithToolTip = ({ tooltip, disabled, onClick, ...props }: {
    tooltip: NonNullable<React.ReactNode>,
    disabled: boolean,
    onClick?: () => void,
    children?: any
}) => {
    const adjustedButtonProps = {
        disabled: disabled,
        component: "div",
        onClick: disabled ? undefined : onClick
    }

    return (
        <Tooltip title={tooltip}
            enterDelay={0}
            leaveDelay={100}
            placement="top" arrow>
            <span>
                <EditButtonWithTooltip {...props} {...adjustedButtonProps} />
            </span>
        </Tooltip>
    )
}

export const ProtectedInput = ({ trackClickEvent, ...props }: any) => {
    const [disabled, setDisabled] = useState(true);

    return (
        <InputContainer>
            <Input {...props} disabled={disabled} />
            {
                !props.available ?
                    <ButtonWithToolTip disabled={!props.available} tooltip={strings.combinationEditTooltip}>{strings.edit} </ButtonWithToolTip>
                    :
                    <EditDisabledField
                        disabled={!props.available && disabled}
                        onClick={() => {
                            trackClickEvent(analyticsEvents.allowEditUnlockCode);
                            setDisabled(!disabled)
                        }}
                    >
                        {strings.edit}</EditDisabledField>
            }
        </InputContainer>
    );
};

const InputContainer = styled.div({
    position: "relative",
    display: "flex",
    "input": {
        marginBottom: 0,
    }
});

const EditDisabledField = styled(PrimaryButton)({
    fontSize: "0.75rem",
});

const EditButtonWithTooltip = styled(PrimaryButton)({
    fontSize: "0.75rem",
    root: {
        "&.Mui-disabled": {
            pointerEvents: "auto"
        }
    }
});
