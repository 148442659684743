import React from 'react'
import styled from "styled-components";
import { Card } from "../../../core/surfaces/Card";
import { strings } from "../../../content/strings";
import { H2, PMedium } from '../../../core/brand/typography';

const ThankYouContainer = () => {
    return (

        <SuccessCard>
            <H2>{strings.thankYou}</H2>
            <PMedium>{strings.thankYouSubText}</PMedium>
        </SuccessCard>
    )
}

export default ThankYouContainer;

const SuccessCard = styled(Card)({
    width: "450px",
    position: "relative",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "20px"
})
