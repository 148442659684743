import { IPermission } from "../models/IPermission";
import Navigation, { ILink, INavigation } from "../routes/Navigation";
import Permission from "./Permission";

enum Role {
    "Super Admin" = 1,
    "Site Admin" = 2,
    "Site Personnel" = 3,
    Admin = 4,
    Personnel = 5,
    "Site Call Center Agent" = 6,
    "Call Center Agent" = 7,
    "Manufacturing QA" = 8,
    "Restricted Personnel" = 9,
    "Owner" = 10,
    "Vendor" = 11,
}

export const clientRoles = [
    Role[10],
    Role[4],
    Role[5],
    Role[7],
    Role[9],
    Role[11],
];

export const siteLevelRoles = [
    Role[1],
    Role[2],
    Role[3],
    Role[6],
    Role[8],
]
namespace Role {
    export function isVendor(roleID?: number): boolean {
        return roleID === Role.Vendor
    }

    export function isSiteRole(roleID?: number): boolean {
        return roleID === Role["Super Admin"] ||
            roleID === Role["Site Admin"] ||
            roleID === Role["Site Personnel"] ||
            roleID === Role["Site Call Center Agent"]
    }

    export const isAdministrator = (roleID: number) => {
        return roleID === Role["Super Admin"] ||
            roleID === Role["Site Admin"] ||
            roleID === Role["Admin"]
    }

    export function getDashLink(roleID?: number): ILink {
        if (!roleID) return { href: "" }
        switch (roleID) {
            case Role["Site Personnel"]:
            case Role["Site Call Center Agent"]:
                return { link: "/Company" }
            case Role["Super Admin"]:
            case Role["Site Admin"]:
            case Role["Owner"]:
            case Role.Admin:
            case Role.Personnel:
            case Role["Restricted Personnel"]:
                return { link: "/Users/Dashboard" }
            case Role["Call Center Agent"]:
                return { link: "/Users/ClientCallCenter" }
            case Role["Manufacturing QA"]:
                return { link: "/SerialCodes_Locks/FreeCodes" }
            case Role["Vendor"]:
                return { link: "/SerialCodes_Locks/Vendor" }
            default:
                return { link: "/Users/Dashboard" }
        }
    }

    export function navigationPermissions(roleID?: number, permissions?: { [key: number]: IPermission }) {
        switch (roleID) {
            case Role["Super Admin"]:
                return [
                    Navigation.dash(getDashLink(roleID)),
                    Navigation.companies(),
                    Navigation.freeSerialCodes(),
                    // Navigation.serialCodes(),
                    // Navigation.billingPlans(),
                    Navigation.settings(
                        Navigation.apiSettings(),
                        Navigation.davinciUsers(),
                        Navigation.profile(),
                    ),
                    Navigation.logs(),
                    Navigation.jobs(),
                    Navigation.logout()
                ]
            case Role["Site Admin"]:
                return [
                    Navigation.dash(getDashLink(roleID)),
                    Navigation.companies(),
                    Navigation.freeSerialCodes(),
                    // Navigation.billingPlans(),
                    Navigation.settings(
                        Navigation.davinciUsers(),
                        Navigation.profile(),
                    ),
                    Navigation.logs(),
                    Navigation.jobs(),
                    Navigation.logout()
                ]
            case Role["Owner"]:
            case Role.Admin:
                return [
                    Navigation.premium(),
                    Navigation.dash(getDashLink(roleID)),
                    Navigation.serialCodes(),
                    Navigation.processReturnLocks(),
                    Navigation.activateSerialCode(),
                    Navigation.settings(
                        Navigation.apiSettings(),
                        Navigation.facilities(),
                        Navigation.subdomains(),
                        Navigation.users(),
                        Navigation.profile(),
                        Navigation.RolesAndPermissions(),
                        Navigation.AutoNotifications(),
                        Navigation.Integrations(),
                    ),
                    Navigation.howToVideos(),
                    Navigation.logout()
                ]

            case Role.Personnel:
            case Role["Restricted Personnel"]:
                return [
                    Navigation.premium(),
                    Navigation.dash(getDashLink(roleID)),
                    Navigation.serialCodes(),
                    Navigation.processReturnLocks(),
                    Navigation.activateSerialCode(),
                    Navigation.settings(
                        Navigation.profile(),
                    ),
                    Navigation.howToVideos(),
                    Navigation.logout(),
                ]
            default:
                if (permissions) {
                    return [
                        Navigation.dash({ link: "/Users/Dashboard" }),
                        ...(getRoutePermission(permissions, Permission.Locks)),
                        ...(getRoutePermission(permissions, Permission["Lock Returns"])),
                        ...(getRoutePermission(permissions, Permission["Activate Locks"])),
                        Navigation.settings(
                            ...(getRoutePermission(permissions, Permission.ApiSettings)),
                            ...(getRoutePermission(permissions, Permission.Facilities)),
                            ...(getRoutePermission(permissions, Permission.Users)),
                            ...(getRoutePermission(permissions, Permission.Subdomains)),
                            Navigation.profile(),
                        ),
                        Navigation.howToVideos(),
                        Navigation.logout(),
                    ]
                }
                return []
        }
    }
}

const PermissionNavigationMap: { [key: number]: INavigation } = {
    [Permission.Locks]: Navigation.serialCodes(),
    [Permission["Lock Returns"]]: Navigation.processReturnLocks(),
    [Permission["Activate Locks"]]: Navigation.activateSerialCode(),
    [Permission.ApiSettings]: Navigation.apiSettings(),
    [Permission.Facilities]: Navigation.facilities(),
    [Permission.Users]: Navigation.users(),
    [Permission.Subdomains]: Navigation.subdomains(),
}

const getRoutePermission = (
    permissions: { [key: number]: IPermission },
    id: Permission
) => permissions[id]?.accessLevel ? [PermissionNavigationMap[id]] : []

export default Role
