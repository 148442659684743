import styled from "styled-components";

export type SectionProps = {
    column?: boolean;
    paddingX?: number;
    paddingY?: [number, number] | number;
    backgroundColor?: string;
    backgroundImage?: any
}

export const Section = styled.div<SectionProps>(({ column = true, paddingX = 8, paddingY = 24, backgroundColor }) => ({
    display: "flex",
    flexDirection: column ? "column" : "row",
    alignItems: "center",
    ...(typeof (paddingY) === "object" ? {
        padding: `${paddingY[0]}px ${paddingX}px ${paddingY[1]}px ${paddingX}px`,
    } : {
        padding: `${paddingY}px ${paddingX}px`,
    }),
    width: `calc(100% - ${paddingX * 2}px)`,
    backgroundColor
}));
