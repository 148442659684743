import React from "react";
import gears from "../../assets/images/gears.png";
import { strings } from "../../content/strings";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate";
import CompanySubdomainsContainer from "./components/CompanySubdomainsContainer";

const CompanySubdomainsPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.subdomains}
            pageDescription=""
            pageCanonical="www.davincilock.com/Company/Subdomains">
            <CompanySubdomainsContainer />
        </LoggedInPageTemplate >
    );
};

export default CompanySubdomainsPage;
