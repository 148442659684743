import React from "react";
import gears from "../../assets/images/gears.png";
import ClientCallCenterContainer from "./components/ClientCallCenterContainer";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate";
import { strings } from "../../content/strings";

const ClientCallCenterPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.callCenterPage}
            pageDescription=""
            pageCanonical="www.davincilock.com/Users/ClientCallCenterPage">
            <ClientCallCenterContainer />
        </LoggedInPageTemplate >
    )
}

export default ClientCallCenterPage;
