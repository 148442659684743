import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { Navigate } from "react-router-dom";
import Role from "../enums/Role";
import { useRedirectToNewWebApp } from "./useRedirectToNewWebApp";

const AuthorizedRedirect = ({ children }: { children: JSX.Element }) => {
    const { loggedInUser, loading } = useLoggedInUser();
    const shouldRedirect = useRedirectToNewWebApp(loggedInUser);

    if (loading) return null

    if (shouldRedirect) return null;

    if (loggedInUser) {
        const link = Role.getDashLink(loggedInUser.roleID);
        return (
            <Navigate to={link.link!} replace />
        )
    }

    return children
};

export default AuthorizedRedirect;
