import React, { useEffect } from "react"
import styled from "styled-components"
import Pagination from "./Pagination"
import TableHeader from "./TableHeader"
import TableRowComponent from "./TableRow"
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import styledMui from "@mui/material/styles/styled"
import { H4 } from "../brand/typography"
import { strings } from "../../content/strings"
import { SelectionFilterOptions } from "../../models/SelectionFilterOptions"
import { useTableSortAndFilter } from "./useTableSortAndFilter"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import MobileDisplay from "./MobileDisplay"
import { Obj } from "../../models/obj"

export type HeaderProps = {
    text: string,
    key?: string,
    date?: boolean,
    content?: any,
    normalSort?: boolean,
    endOfRow?: boolean
    filterOptions?: string[] | { label: string, value: any }[]
}

interface TableProps<T> {
    data: T[],
    headers: HeaderProps[],
    rowsPerPage?: RowsPerPageProps,
    rowProps?: RowProps | null,
    styles?: any,
    selectedRow?: any,
    tooltip?: string,
    canEdit?: (row: any) => boolean
    children?: any,
    noDataMessage?: string,
    filterString?: string,
    filterKeys?: string[],
    useServer?: boolean,
    total?: number,
    fetchDataFromServer?: (payload: SelectionFilterOptions) => void,
    mobileIdentifier?: string,
    mobileHeaders?: Obj[];
    mobileSortKey?: string,
    mobileActions?: any,
    hideMobileCards?: boolean,
    hidePagination?: boolean
    filter?: boolean
}

interface RowsPerPageProps {
    initial?: number,
    options?: number[]
}

export interface RowProps {
    onClick?: (item: any) => void
}

function Table<T>({
    data,
    headers,
    rowsPerPage,
    rowProps,
    selectedRow,
    tooltip,
    children,
    canEdit,
    noDataMessage,
    filterString,
    filterKeys,
    useServer,
    total,
    fetchDataFromServer,
    mobileIdentifier,
    mobileHeaders,
    mobileSortKey,
    mobileActions,
    hideMobileCards = false,
    hidePagination = false,
    filter = true

}: TableProps<T>, ref: any) {
    const {
        sortedData, pageData, setFilterData, setSortData, paginationData, setPaginationData, count } = useTableSortAndFilter({
            data,
            rowsPerPage,
            filterString,
            filterKeys,
            useServer,
            fetchDataFromServer,
        })

    const [mobile] = useMediaQuery(450);
    if (ref) {
        ref.current = { data: sortedData || pageData, headers }
    }
    if (mobile && !hideMobileCards) {
        if (!data.length) {
            return (
                <NoDataMessage>
                    <H4>{noDataMessage}</H4>
                </NoDataMessage>
            )
        }
        const displayLimit = 30;
        const orderedData = (data: any) => {
            if (mobileSortKey) {
                return data.sort((a: any, b: any) => (a[mobileSortKey] > b[mobileSortKey]) ? -1 : 0).slice(0, displayLimit)
            }
            if (data.length && data[0].updatedBy) {
                return data.sort((a: any, b: any) => (a.updatedOn > b.updatedOn) ? -1 : 0).slice(0, displayLimit)
            }
            return data.slice(0, displayLimit)
        }
        return (
            <>
                {mobileActions && mobileActions()}
                {orderedData(data).map((item: any, idx: number) => {
                    return (
                        <MobileDisplay data={item} mobileIdentifier={mobileIdentifier} mobileHeaders={mobileHeaders} rowProps={rowProps} />
                    )
                })}

            </>

        )
    }

    return (
        <>
            <MuiTableContainerx>
                <MUITable>
                    <TableHead>
                        <TableHeader
                            useServer={useServer}
                            headers={headers}
                            setSortData={setSortData}
                            setFilterData={setFilterData}
                            filter={filter}
                        />
                    </TableHead>
                    <TableBody>
                        {
                            pageData.map((row) => {
                                return (
                                    <TableRowComponent
                                        key={row.id}
                                        columns={headers}
                                        tooltip={(canEdit && canEdit(row)) ? tooltip : ""}
                                        tertiary={row == selectedRow}
                                        row={row}
                                        onRowClick={() => rowProps?.onClick && rowProps.onClick(row)}
                                    />
                                );
                            })
                        }
                    </TableBody>
                </MUITable>
                {!data.length &&
                    <NoDataMessage>
                        <H4>{noDataMessage}</H4>
                    </NoDataMessage>
                }
            </MuiTableContainerx>
            <span style={{ alignSelf: !mobile ? "flex-end" : "auto" }}>
                {!!children && children}
                {!hidePagination && <Pagination
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    itemsTotal={total || count!}
                    options={rowsPerPage?.options}
                />}
            </span>
        </>
    )
}

export const PrintTable = ({ data, headers }: { data: any[], headers: any[] }) => {
    return (
        <PrintTableWrapper>
            <PrintTableHeader>
                <H4>{strings.serialCodePrintHeader}</H4>
            </PrintTableHeader>
            <MUITable>
                <TableHead>
                    <TableHeader
                        setSortData={() => { }} setFilterData={() => { }} headers={headers}
                    />
                </TableHead>

                <TableBody>
                    {data.map((row) => {
                        return (
                            <TableRowComponent
                                key={row.id + "print"}
                                columns={headers}
                                tooltip={strings.tableTooltip}
                                row={row}
                                onRowClick={() => { }}
                            />
                        );
                    })}
                </TableBody>
            </MUITable>
        </PrintTableWrapper >
    )
}

const NoDataMessage = styled.div({
    paddingTop: "24px"
})

const MuiTableContainerx = styledMui(TableContainer)({
    "td, th": {
        padding: "8px",
        border: 0,
    },
    "::-webkit-scrollbar": {
        width: "0px",
    },
})

const PrintTableWrapper = styled.div({
    padding: "24px 20px 0 20px",
    boxSizing: "border-box",
})
const PrintTableHeader = styled.div({
    display: "flex"
})

export default React.forwardRef(Table)
