import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components'
import Table from "../../../core/table/Table"
import { strings } from "../../../content/strings";
import agents from '../../../api/agent';
import { ILogsListResponse } from '../../../models/ILogsListResponse';
import { DateRangePicker } from "mui-daterange-picker";
import { PrimaryButton } from '../../../components/button';
import { FormControl, Dialog, Paper, InputAdornment, TextField, Box, LinearProgress } from '@mui/material';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Colors } from '../../../core/brand/themes';
import moment from "moment";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SearchField from "../../../core/form-components/SearchField"
import TableTitle from '../../../core/table/TableTitle';
import TableWrapper from '../../../core/table/TableWrapper';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const convertDateFormat = (date: string) => {
    const dateObj = new Date(date);
    const momentObj = moment(dateObj);
    return momentObj.format("YYYY/MM/DD");
}

const UserLogsContainer = () => {
    const [logs, setLogs] = useState<ILogsListResponse[]>([]);
    const [filteredLogs, setFilteredLogs] = useState<ILogsListResponse[]>([]);
    const [logsFetched, setLogsFetched] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState({});
    const [inputValue, setInputValue] = useState("");
    const [mobile] = useMediaQuery(414);

    const headers: {
        text: string,
        key: string,
        date?: boolean,
        content?: any,
        normalSort?: boolean,
        endOfRow?: boolean
        filterOptions?: string[] | { label: string, value: any }[]
    }[] = useMemo(() =>
        [
            { text: "Date", key: "createdOn", normalSort: true },
            { text: "Type", key: "logType", normalSort: true },
            { text: "Description", key: "logDescription", endOfRow: true },
        ], []
    )

    const getLogs = useCallback(async () => {
        const today = moment(new Date()).format("MM/DD/YYYY")
        const result = await agents.Users.getLogsList(today, today);
        if (result) {
            setLogsFetched(true);
            //@ts-ignore
            setLogs(result.data);
        }
    }, [])

    const toggle = useCallback(() => setOpen(prevState => !prevState), []);

    const handleSubmit = async (start: string, end: string) => {
        setLogsFetched(false)
        try {
            const result = await agents.Users.getLogsList(moment(start).format("YYYYMMDD"),
                moment(end).format("YYYYMMDD"));
            //@ts-ignore
            setLogs(result.data);
            setLogsFetched(true);

        } catch (err) {
            setLogsFetched(true);
        }
    }

    const handleValueChange = () => {
        //@ts-ignore
        const endDate = convertDateFormat(dateRange.endDate);
        //@ts-ignore
        const startDate = convertDateFormat(dateRange.startDate);
        setInputValue(`${startDate}-${endDate}`);
        toggle();
        handleSubmit(startDate, endDate)
    }

    useEffect(() => {
        setInputValue(
            `${convertDateFormat(moment().toString())}-${convertDateFormat(moment().toString())}`
        )
        getLogs()
    }, [])

    return (

        <Container>
            <ActionsContainer>
                <TableTitle title={strings.logs} />
                <SearchField
                    data={logs || []}
                    searchKeys={["logDescription"]}
                    searchLabel={strings.description}
                    setFilterData={setFilteredLogs}
                    fullWidth={mobile}
                />
                <FormControl sx={{ marginBottom: "16px" }}>
                    <TextField
                        sx={{ cursor: "pointer", width: mobile ? "97%" : "auto" }}
                        onClick={toggle}
                        label=""
                        value={inputValue}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faCalendar as IconProp} color={Colors.red()} size="1x" />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />

                </FormControl>
            </ActionsContainer>
            {
                !logsFetched &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="primary" />
                </Box>
            }
            <Dialog TransitionComponent={Transition}
                open={open}
                onClose={toggle}
                keepMounted
                fullWidth
                maxWidth="md"
                sx={{ width: "100vw", solid: "red 1px" }} >
                <Paper>
                    <DateRangePicker
                        open={open}
                        toggle={toggle}
                        onChange={(range) => setDateRange(range)}
                    />
                </Paper>
                <PrimaryButton onClick={handleValueChange}>{strings.apply}</PrimaryButton>
            </Dialog>
            <TableWrapper>
                <Table
                    data={filteredLogs}
                    headers={headers}
                    rowsPerPage={{ initial: 10, options: [5, 10, 20, 50, 100] }}
                    mobileHeaders={headers.filter((h: any) => h.key == "logDescription")}
                    mobileIdentifier="logType"
                />
            </TableWrapper>
        </Container>
    );
}

const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    alignItems: "baseline",
    gap: "12px",
    gridTemplateColumns: "minmax(135px, 1fr) repeat(3, auto)",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
});

export default UserLogsContainer;
