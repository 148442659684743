import { useRef } from "react";
import Role from "../enums/Role";
import { useQuery } from "../hooks/useQuery";
import { useUserRoleID } from "./useLoggedInUser";

export const useCompanyID = () => {
    const companyIDQuery = useQuery("companyID");
    const companyID = companyIDQuery ? Number(companyIDQuery) : null
    const companyIDRef = useRef<number | null>(null);
    companyIDRef.current = companyID

    /** This hook is used to fetch data from other companies.
     * Don't even offer the functionality unless you are logged in as a site level user. */
    if (Role.isSiteRole(useUserRoleID())) {
        return {
            companyIDRef,
            companyID,
        }
    }

    return {
        companyIDRef: { current: null },
        companyID: null
    }

};
