import { useMemo } from "react";
import { useFacilities } from "./useFacilities";

export const useFacilityLookup = () => {
    const { facilities } = useFacilities();

    const facilityLookup = useMemo(() => facilities.reduce((acc: { [key: number]: string; }, cv: any) => ({
        ...acc,
        [cv.facilityID!]: cv.facilityName
    }), {}) as ({ [key: number]: string; }),
        [facilities]
    );

    return facilityLookup;
};
