
import React from "react";
import { FormFlowRenderProps } from "../../../hooks/useFormFlow2";
import { IFacilityModelForm } from "../../../models/IFacilityForm";
import { IForm } from "../../../hooks/hooks";
import { FMSForm } from "./FMSForm";
import { events as analyticsEvents } from "../../../mixPanelEvents"

export const FMSStep = {
    Render: ({ form, next, back, analytics, submit }: FormFlowRenderProps<IFacilityModelForm>) => {
        const handleBack = () => {
            back(form)
            analytics(analyticsEvents.previousStep)
        }

        return (
            <>
                <FMSForm form={form} handleBack={handleBack} handleSubmit={submit} />
                {/* I don't think we need this, the FMSForm already submits, just can't get the analytics tag to run here, calling form.handleSubmit inside the  method does not work */}
                {/* <PrimaryButton onClick={handleSubmit} fullWidth>Continue</PrimaryButton> */}

            </>
        )
    },
    validate: (form: IForm<IFacilityModelForm>) => {
        return form.validateFields("street", "state", "city", "zip", "lat", "lng")
    }
}
