import { getAuthError, getAuthPending, loginAsync } from "../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../hooks/useAppSelector";
import { IAccount } from "../models/IAccount";
import { useCallback } from "react";

interface IUseLogin {
    login: (account: IAccount['login']) => void,
    errorMessage: string | undefined,
    pending: boolean | undefined,
}

export const useLogin = (): IUseLogin => {
    const dispatch = useAppDispatch()
    const error = useAppSelector(getAuthError)
    const pending = useAppSelector(getAuthPending)

    const login = useCallback((account: IAccount['login']) => {
        dispatch(loginAsync(account))
    }, [])

    return {
        login,
        errorMessage: error,
        pending,
    }
}
