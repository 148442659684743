import LZString from "../utils/lz-string.js";
import { debugLog } from "../utils/debugLog.js"
const worker = new Worker(
    // @ts-ignore
    new URL('../workers/compressCache.worker.js', import.meta.url),
    { type: 'module' }
);

export function createCacheWorker<T>(getCacheKey: (userID: number) => string) {
    const cacheQueueRef: { current: null | { data: T, key: string } } = {
        current: null
    }

    const workingRef = {
        current: false
    }

    worker.onerror = (err) => debugLog({ err });
    worker.onmessage = (e) => {
        if (e.data.key) {
            localStorage.setItem(e.data.key, e.data.compressedData);
        }

        if (cacheQueueRef.current) {
            worker.postMessage(cacheQueueRef.current);
            cacheQueueRef.current = null
        } else {
            workingRef.current = false
        }
    };

    const getCachedData = (userID: number): T | null => {
        const uncompressedData: string | null = localStorage.getItem(getCacheKey(userID));

        if (uncompressedData) {
            return JSON.parse(LZString.decompress(uncompressedData) as string);
        }
        return null;
    }

    const postCache = (data: T, userID: number) => {
        const workItem = {
            data,
            key: getCacheKey(userID)
        }

        if (workingRef.current) {
            cacheQueueRef.current = workItem
        } else {
            workingRef.current = true
            worker.postMessage(workItem);
        }
    }

    return { getCachedData, postCache };
}
