import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Obj } from "../../../models/obj";

type Columns = {
    header: string,
    key: string,
    width: number
}[]

const columnLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V"]

export const excelExport = (data: Obj[], columns: Columns, filename: string) => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet('Locks');

    worksheet.columns = columns;

    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };

    for (let i = 1; i <= columns.length; i++) {
        const cell = headerRow.getCell(i);
        cell.border = { bottom: { style: "medium" } }
    }

    worksheet.addRows(data)

    worksheet.autoFilter = `A1:${columnLetters[columns.length - 1]}1`;

    ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            filename
        );
    });
};
