import React from "react";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    stepConnectorClasses,
    styled,
} from "@mui/material";
import LensIcon from '@mui/icons-material/Lens';
import { Colors } from '../../../core/brand/themes';
import { IPermission } from "../../../models/IPermission";
import Permission from '../../../enums/Permission';

interface IPermissionStepper {
    setValue: (arg0: number, arg1: number) => void,
    value: IPermission | any,
    id: number
}

const PermissionStepper = ({ setValue, value, id }: IPermissionStepper) => {

    const steps = Permission.isToggle(id) ?
        ["No", "Yes"] : [
            'None',
            'View',
            'Edit',
        ];

    const handleClick = (val: number) => {
        if (Permission.isToggle(id) && val == 1) val++;
        setValue(id, val)
    }
    return (
        <Box sx={{ width: '100%', mb: 2, display: "flex", justifyContent: "flex-start" }}>
            <Stepper activeStep={value} alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((label, idx) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={() =>
                                value >= idx ?
                                    <LensIcon onClick={() => handleClick(idx)} color="primary" sx={{ cursor: "pointer", zIndex: 100, color: Colors.red() }} />
                                    :
                                    <LensIcon onClick={() => handleClick(idx)} color="disabled" sx={{ cursor: "pointer", color: "#EEE", zIndex: 100 }} />
                            }><p style={{ marginTop: "-10px" }}>{label}</p></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export default PermissionStepper

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        _borderColor: Colors.red(),
        get borderColor() {
            //@ts-ignore
            return this._borderColor;
        },
        set borderColor(value) {
            //@ts-ignore
            this._borderColor = value;
        },
        top: 8,
        left: 'calc(-50% + 5px)',
        right: 'calc(50% + 5px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: Colors.red(),
            borderWidth: "8px",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: Colors.red(),
            borderWidth: "8px",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#EEE",
        // borderTopWidth: 3,
        borderWidth: "8px",
        borderRadius: 1,
        zIndex: -1,
    },
}));
