import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { Card } from '../../../core/surfaces/Card';
import { useCustomMessage, useForm, useProfileSettings, useQuery, useTemplateValues, useTextTemplate } from '../../../hooks/hooks';
import StatsCard from '../../auto-notifications/components/StatsCard';
import CreateCustomMessage from "./CreateCustomMessage"
import { MessagesEnum, MessagesEnumKeys } from "../../../enums/MessagePreference"
import agents from '../../../api/agent';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { debugLog } from '../../../utils/debugLog';
import { customTextValidator } from '../../../validators/validators';
import { MessageType } from '../../../enums/MessageType';

const CustomMessageContainer = () => {
    const params: any = useQuery("message");
    const slash = params.indexOf("/")
    const message: MessagesEnumKeys = params.slice(0, slash)

    const { messageStats, defaultMessage } = useProfileSettings()
    const { templateValues } = useTemplateValues()
    const { displayToast } = useContext(ToastContext)
    const { defaultMessages, inUseProps, activeTab, setActiveTab } = useCustomMessage(message);

    const submitAction = async ({ template }: any) => {
        const serverTemplate = smsProps.generateTextForServer(template);
        const inUse = MessageType[activeTab] == MessageType[0] ? inUseProps.smsInUse : inUseProps.emailInUse

        if (MessagesEnum[message] == 3 || MessagesEnum[message] == 4) {
            const textToReleaseType = MessagesEnum[message] == 3 ? "approved" : "denied";
            try {
                const result = await agents.Settings.updateTextRelease(textToReleaseType, inUse, serverTemplate)
                if (result.success) {
                    displayToast({ type: ToastType.Success, text: result.data })
                } else {
                    displayToast({ type: ToastType.Error, text: result.data })
                }
            } catch (e) {
                debugLog({ e })
            }
        }
        if (message == MessagesEnum[1]) {
            try {
                const result = await agents.Settings.updateMoveIn(MessageType[activeTab].toLowerCase(), inUse, serverTemplate)
                if (result.success) {
                    displayToast({ type: ToastType.Success, text: result.data })
                } else {
                    displayToast({ type: ToastType.Error, text: result.data })
                }
            } catch (e) {
                debugLog({ e })
            }
        }
        if (message == MessagesEnum[2]) {
            try {
                const result = await agents.Settings.updateDelinquent(MessageType[activeTab].toLowerCase(), inUse, serverTemplate)
                if (result.success) {
                    displayToast({ type: ToastType.Success, text: result.data })
                } else {
                    displayToast({ type: ToastType.Error, text: result.data })
                }
            } catch (e) {
                debugLog({ e })
            }
        }
    }

    const emailForm = useForm<{ template: string }>(
        { template: defaultMessages.defaultEmail || defaultMessage }, submitAction, customTextValidator, { message: MessageType[0] }
    );

    const emailProps = useTextTemplate(templateValues, emailForm.inputProps.template as any);

    const smsForm = useForm<{ template: string }>(
        { template: defaultMessages.defaultSms || defaultMessage }, submitAction, customTextValidator, { message: MessageType[1] }
    );
    const smsProps = useTextTemplate(templateValues, smsForm.inputProps.template as any);


    return (
        <h1>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", justifyContent: "space-around", mx: "8px" }}>
                <Card style={{ flexBasis: "12.5%", display: "flex", padding: "8px 4px", boxSizing: "border-box", marginTop: "22px", minHeight: "142px" }}>
                    <StatsCard data={messageStats} />
                </Card>
                <Card style={{ flexBasis: "65%", display: "flex", flexDirection: "column" }}>
                    <CreateCustomMessage
                        message={message}
                        emailProps={emailProps}
                        smsProps={smsProps}
                        smsForm={smsForm}
                        emailForm={emailForm}
                        inUseProps={inUseProps}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </Card>
            </Box>
        </h1>
    );
}

export default CustomMessageContainer;
