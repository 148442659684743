import React from "react"
import FloatingMenu from "./NavMenu";
import styled from "styled-components"
import { Colors } from "../../core/brand/themes"
import { ILoggedInUser } from "../../models/ILoggedInUser"

const MobileNavMenu = (
    { loggedInUser, showMenu, setShowMenu }:
        { loggedInUser: ILoggedInUser | null, showMenu?: boolean, setShowMenu: (val: boolean) => void }) => {
    if (!loggedInUser?.roleID) return null

    return (
        <>
            <CloseMenuOverlay active={showMenu} onClick={() => setShowMenu(false)} />
            <SlideRight show={showMenu} width={"221px"} duration={450}>
                <FloatingMenu />
            </SlideRight>
        </>
    )
}

const CloseMenuOverlay = styled.div<{ active?: boolean }>(({ active }) => ({
    position: "absolute",
    opacity: "0%",
    '@media(min-width: 420px)': {
        backgroundColor: "#0002",
        width: "100vw",
        height: "100vh",
        transition: "opacity 0.3s ease-in",
        zIndex: -1,
        ...(active ? {
            opacity: "100%",
        } : {
            pointerEvents: "none",
            opacity: "0%",
        })
    },
}))

const SlideRight = styled.div<{ show?: boolean, duration: number, width: string }>(({ show, duration, width }) => ({
    backgroundColor: Colors.white(),
    position: "absolute",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
        height: "0px",
        width: "0px",
    },
    width: "275px",
    marginLeft: "-275px",
    transition: `${duration / 1000}s margin ease-in`,
    zIndex: 1,
    transform: "translate3d(0, 0, 50px)",
    top: "55px",
    maxHeight: "calc(100vh - 95px)",
    height: "calc(100vh - 95px)",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
    ...(show ? {
        marginLeft: "0",
        boxShadow: "3px 2px 9px 0px rgba(0, 0, 0, 0.25)",
        overflow: "hidden",
    } : {}),
    '@media(max-width: 420px)': {
        width: "100vw",
        minHeight: "100vh",
        overflow: "hidden",
        marginLeft: "-100vw",
        ...(show ? {
            marginLeft: "0",
            boxShadow: "3px 2px 9px 0px rgba(0, 0, 0, 0.25)",
        } : {}),
    },
}))

export default MobileNavMenu
