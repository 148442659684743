import React from "react";
import LoginFrom from "../../core/common/LoginForm";
import gears from "../../assets/images/gears.png";
import { PageTemplate } from "../../core/layout/PageTemplate";
import { strings } from "../../content/strings";

const LoginPage = ({ email }: { email?: string }) => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.login}
            pageDescription={strings.davincilockPageDescription}
            pageCanonical={strings.signinPageCanonical}
            center>
            <LoginFrom email={email} />
        </PageTemplate>
    );
};

export default LoginPage;
