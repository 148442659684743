import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { Colors } from "../brand/themes";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAnalytics from "../../hooks/useAnalytics";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type AutoCompleteProps = {
    options: string[],
    setValue: any,
    validate?: (val?: string) => Promise<boolean>,
    error?: string | boolean | undefined,
    label: string,
    value: any,
    noClear?: boolean,
    analyticsTag?: string,
    solo?: boolean,
    endAdornment?: JSX.Element,
    suspendMouseEvent?: boolean
    // propName?: string,
    // disabled?: boolean,
    // noSort?: boolean,
    // displayProp?: string
}

export default function ({ options, value, error, label, setValue, validate, noClear, analyticsTag, solo, endAdornment, suspendMouseEvent = false, ...props }: AutoCompleteProps) {
    const mouseLeft = useRef(false)
    const [inputVal, setInputVal] = useState<string>(value as string)
    const { trackClickEvent } = useAnalytics("AutoComplete", false)

    useEffect(() => {
        setInputVal(value as string)
    }, [value])

    const handleInputChange = ({ target: { value: newValue } }: BaseSyntheticEvent) => {
        analyticsTag && trackClickEvent(analyticsTag)
        setValue(newValue)
    }

    const handleBlur = async ({ target: { value: newValue } }: BaseSyntheticEvent) => {
        if (solo) {
            setValue(newValue);
            return
        }

        if (!newValue) {
            if (noClear) {
                if (!validate || await validate(options[0])) {
                    analyticsTag && trackClickEvent(analyticsTag)
                    setValue(value || options[0])
                }
            }
            return;
        }

        const suggestions = options.filter(o => o.toLocaleLowerCase().includes(newValue.toLocaleLowerCase()));
        const exactMatch = options.find(o => o.toLocaleLowerCase() === newValue.toLocaleLowerCase());
        if (suggestions.length) {
            if (!validate || await validate(exactMatch || suggestions[0])) {
                analyticsTag && trackClickEvent(analyticsTag)
                setValue(exactMatch || suggestions[0])
            }
        } else {
            validate && validate("")
            analyticsTag && trackClickEvent(analyticsTag)
            setValue("")
        }
    }

    const handleSelect = (val: string) => {
        if (mouseLeft.current || suspendMouseEvent) {
            return false;
        }
        analyticsTag && trackClickEvent(analyticsTag)
        setValue(val)
    };

    return <AutocompleteContainer>
        <Autocomplete
            sx={{
                minWidth: "235px"
            }}
            options={options}
            popupIcon={endAdornment ? endAdornment :
                <FontAwesomeIcon icon={faCaretDown as IconProp} color={Colors.red()} size="sm" />
            }
            forcePopupIcon={true}
            autoSelect
            selectOnFocus
            openOnFocus
            freeSolo={solo}
            {...(noClear ? { clearIcon: null } : {})}
            onOpen={() => mouseLeft.current = false}
            ListboxProps={{
                onMouseLeave: () => mouseLeft.current = true,
                onMouseEnter: () => mouseLeft.current = false,
            }}
            value={inputVal || ""}
            onChange={(_: React.SyntheticEvent, v: any) => handleSelect(v as string)}
            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        InputLabelProps={{ style: { color: Colors.charcoal() }, ...params.InputLabelProps }}
                        label={label}
                        error={!!error}
                        helperText={error || ""}
                        variant="standard"
                    />
                )
            }}
        />
    </AutocompleteContainer>
}

const AutocompleteContainer = styled.span({
    "input": {
        borderBottom: "0px solid black"
    }
})
