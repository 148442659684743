import React, { useEffect, useLayoutEffect } from "react";
import Input, { NumberInput } from "../../../core/form-components/Input";
import { ManagementSoftware } from "../../../enums/ManagementSoftware";
import { IForm } from "../../../hooks/useForm";
import { strings } from "../../../content/strings";

type FmsFormComponentInputProps = {
    [key: number]: (form: any) => JSX.Element
}

interface FMSFormInterface {
    fms: ManagementSoftware, form: IForm<any>, fmsRef?: any
}
export const FmsFormComponent = ({
    fms, form, fmsRef
}: FMSFormInterface) => {
    const FmsFormInputs = getFmsComponentFormInputsComponent[fms]
    if (!FmsFormInputs) return null;

    if (fmsRef) {
        fmsRef.current.fieldNames = formFieldNames[fms]
    }


    return (
        <div>
            <p>{fmsSubText[fms]}</p>
            {FmsFormInputs(form)}
        </div>
    )
}

const fmsSubText: { [key: number]: string } = {
    [ManagementSoftware.storEDGE]: strings.fmsSubText.storeEDGE,
    [ManagementSoftware["Easy Storage Solutions"]]: strings.fmsSubText.easyStorage,
    [ManagementSoftware.SiteLink]: strings.fmsSubText.siteLink,
    [ManagementSoftware.DoorSwap]: strings.fmsSubText.doorSwap,
    [ManagementSoftware.Hummingbird]: strings.fmsSubText.hummingBird,
    [ManagementSoftware.WebSelfStorage]: strings.fmsSubText.webSelfStorage,
}

const formFieldNames: { [key: number]: string[] } = {
    [ManagementSoftware.storEDGE]: ["externalFacilityId"],
    [ManagementSoftware["Easy Storage Solutions"]]: ["externalFacilityId"],
    [ManagementSoftware.SiteLink]: ["corporateCode", "locationCode", "corporateUsername", "corporatePassword"],
    [ManagementSoftware.DoorSwap]: ["externalFacilityId", "corporateUsername", "corporatePassword"],
    [ManagementSoftware.Hummingbird]: ["externalFacilityId"],
    [ManagementSoftware.WebSelfStorage]: ["externalFacilityId"],
}

const getFmsComponentFormInputsComponent: FmsFormComponentInputProps = {
    [ManagementSoftware.storEDGE]:
        (form: any) => {
            useLayoutEffect(() => {
                document.getElementById("storedge-id")?.focus()
            }, [])
            return <Input id="storedge-id" {...form.inputProps.externalFacilityId} label={strings.facilityID} />
        },

    [ManagementSoftware["Easy Storage Solutions"]]:
        (form: any) => {
            useLayoutEffect(() => {
                document.getElementById("easy-storage-id")?.focus()
            }, [])
            return <Input id="easy-storage-id" {...form.inputProps.externalFacilityId} label={strings.facilityAPIKey} />
        },
    [ManagementSoftware.SiteLink]:
        (form: any) => {
            useLayoutEffect(() => {
                document.getElementById("site-link-corp-code")?.focus()
            }, [])
            return (
                <>
                    <input id="password" style={{ display: "none" }} type="password" name="fakepasswordremembered" />
                    <Input id="site-link-corp-code" {...form.inputProps.corporateCode} label={strings.corpCode} />
                    <Input {...form.inputProps.locationCode} label={strings.locationCode} />
                    <Input {...form.inputProps.corporateUsername} label={strings.corpUsername} autocomplete="new-username" />
                    <Input {...form.inputProps.corporatePassword} label={strings.corpPassword} type="password" autocomplete="new-password" />
                </>
            )
        },
    [ManagementSoftware.DoorSwap]:
        (form: any) => {
            useLayoutEffect(() => {
                document.getElementById("doorswap-id")?.focus()
            }, [])
            return (
                <>
                    <input id="password" style={{ display: "none" }} type="password" name="fakepasswordremembered" />
                    <Input id="doorswap-id" {...form.inputProps.externalFacilityId} label={strings.facilityID} />
                    <Input  {...form.inputProps.corporateUsername} label={strings.username} autocomplete="new-username" />
                    <Input {...form.inputProps.corporatePassword} label={strings.password} type="password" autocomplete="new-password" />
                </>
            )
        },
    [ManagementSoftware.Hummingbird]:
        (form: any) => (
            <Input {...form.inputProps.externalFacilityId} label={strings.facilityGdsId} />
        ),
    [ManagementSoftware.WebSelfStorage]:
        (form: any) => (
            <NumberInput {...form.inputProps.externalFacilityId} label={strings.entityNumber} />
        ),
}
