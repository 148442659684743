import React, { useContext, useEffect, useState } from "react";
import agents from "../../api/agent";
import gears from "../../assets/images/gears.png";
import Input from "../../core/form-components/Input";
import { transferFacilityValidator } from "../../validators/validators";
import { ICompanyUser, ITransferForm } from "../../models/models";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { PBold, H4Bold } from "../../core/brand/typography";
import { PrimaryButton, QuaternaryButton } from "../../components/button";
import { strings } from "../../content/strings";
import { useForm } from "../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom"
import { FacilityDto } from "../../models/facility";
import { useQuery } from "../../hooks/useQuery";
import { LoggedInForm } from "../../core/layout/LoggedInForm";
import { HubContext } from "../../contexts/HubContext";
import { events } from "../../events";
import { useUsers } from "../../hooks/useUsers";
import { useFacilities } from "../../hooks/useFacilities";

const address = (facility: FacilityDto) => (<> {facility?.street} <br /> {facility?.city}, {facility?.state} {facility?.zip}</>)

const InitiateFacilityTransferPage = () => {
    const [success, setSuccess] = useState(false);

    const { getFacilityByID } = useFacilities()

    const { users } = useUsers()

    const navigate = useNavigate();
    const token = useQuery("token");
    const { id } = useParams<{ id: string }>();
    const facility = getFacilityByID(Number(id));

    const initialValues = { email: "", facilityID: id, users: users } as ITransferForm;

    const submitAction = async (formValues: ITransferForm, setFormValues: (arg0: ITransferForm) => any) => {
        const { users, ...updatedFormValues } = formValues
        const result = await agents.Facilities.initializeFacilityTransfer(updatedFormValues, token as string);
        if (result.success) {
            setSuccess(true)
        }
    }

    const form = useForm<ITransferForm>(initialValues, submitAction, transferFacilityValidator);

    const cancelTransferAndRedirect = () => {
        form.clear();
        navigate('/Company/FacilityListing')
    }

    const renderContent = () => {
        if (success) {
            return (
                <LoggedInForm.Form>
                    <LoggedInForm.FullSpan center>
                        <PBold>{strings.facilityTransferInitiated}</PBold>
                        <PrimaryButton onClick={() => navigate("/Users/Dashboard")}>{strings.backToDash}</PrimaryButton>
                    </LoggedInForm.FullSpan>
                </LoggedInForm.Form>
            )
        }
        return (
            <LoggedInForm.Form>
                <LoggedInForm.Title>
                    <PBold left>{strings.transferFacility}</PBold>
                    <H4Bold left>{facility?.facilityName}</H4Bold>
                    <PBold left>{address(facility!)}</PBold>
                </LoggedInForm.Title>
                <Input autoCompleteBackground="" {...form.inputProps.email} type="text" label={strings.recipientEmail} />
                <br />
                <QuaternaryButton type="reset" onClick={cancelTransferAndRedirect}>{strings.cancel}</QuaternaryButton>
                <PrimaryButton type="submit" onClick={form.handleSubmit}>{strings.initiate}</PrimaryButton>
            </LoggedInForm.Form>
        )
    }

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.transferFacilityPage}
            pageDescription={strings.davincilockPageDescription}
        >
            {renderContent()}
        </LoggedInPageTemplate>
    )
}

export default InitiateFacilityTransferPage
