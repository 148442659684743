import React, { useCallback, useMemo, useState } from "react";
import { LockModel } from "../../../models/lockModel";
import { PrimaryButton } from "../../../components/button";
import { excelExport } from "./excelExport";
import { strings } from "../../../content/strings";
import moment from "moment";
import { useFacilities } from "../../../hooks/useFacilities";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { createLocksExcelWorker } from "../../../hooks/createLocksExcelWorker";

let columns: {
    header: string,
    key: string,
    width: number
}[] =
    [
        { header: strings.facilityName, key: "facilityName", width: 30, },
        { header: strings.serialCode, key: "serialCode", width: 15 },
        { header: strings.unitNumber, key: "unitNumber", width: 15 },
        { header: strings.unlockCode, key: "unlockCode", width: 15, },
        { header: strings.status, key: "secondaryStatusName", width: 25 },
        { header: strings.updatedBy, key: "updatedBy", width: 30 },
        { header: strings.updatedOn, key: "updatedOn", width: 30 },
    ]

export const DownloadExcel = (
    { data, inventory = false, trackClickEvent }:
        {
            data: LockModel[],
            inventory?: boolean,
            trackClickEvent: (tag: string) => void,
        }) => {
    const [pending, setPending] = useState(false)
    const { facilities } = useFacilities()

    const excelColumns = useMemo(() => {
        return inventory
            ? columns.filter((c) => c.key !== "unitNumber" && c.key !== "facilityName")
            : columns
    }, [columns, inventory])


    const handleExport = useCallback(async () => {
        setPending(true)

        trackClickEvent(analyticsEvents.downloadCSV);

        const worker = createLocksExcelWorker(
            async (result: LockModel[]) => {
                excelExport(
                    result,
                    excelColumns,
                    moment().format("[locks]-yyyy-M-D-hhmmss[.xlsx]")
                )

                setPending(false)
            }
        )

        worker.performWork(data, facilities)
    }, [facilities, data, excelColumns])

    return (
        <PrimaryButton pending={pending} onClick={handleExport}>{strings.exportToExcel}</PrimaryButton>
    );
};
