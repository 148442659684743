import { SelectChangeEvent } from "@mui/material";
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { generateID } from "../utils/generateID"
import { debugLog } from "../utils/debugLog";
import { captureException } from "../utils/errorHandling";

export interface FormObject {
    [key: string]: string | number | boolean | undefined | number[] | FormData | null
}
export interface IChangeEvent {
    e: { target: { value: string } },
}
export interface IMuiChangeEvent {
    event?: SelectChangeEvent<string | number | boolean | number[] | FormData | null>,
    // child?: ReactNode
}

export interface IFieldProps<T extends FormObject> {
    value: string | number | boolean | undefined | number[] | FormData | null,
    // These methods are used on library input sometimes that takes a different interface
    onBlur: (value?: string | any) => void,
    onChange: (e: { target: { value: string } } | any) => void,
    error: string | undefined,
    name: string,
    setValue: (value: string | number | boolean | undefined | number[] | FormData | null | ((prev: string) => string)) => void,
    setValueAsync: (value: string | number | boolean | undefined | number[] | FormData | null) => Promise<IForm<T>>
    validate: (val?: string) => Promise<boolean>,
    hasChange: () => boolean,
    setError: (message: string) => void,
    disableSubmit?: any
}

export interface IForm<T extends FormObject> {
    values: T,
    handleSubmit: (e: any, values?: T) => Promise<boolean>,
    clear: () => void,
    validateFields: (...fields: (keyof T)[]) => Promise<boolean>,
    setFormValues: React.Dispatch<React.SetStateAction<T>>
    inputProps: { [key in keyof T]: IFieldProps<T> },
    clearErrors: () => void,
    setFormValuesAsync: (cb: SetStateAction<T>) => Promise<IForm<T>>,
    context?: any
}

export function useForm<T extends FormObject>(
    initialValues: T,
    submitAction: null | ((arg0: T, arg1: (arg0: T) => any) => any),
    validator: yup.ObjectSchema<any>,
    context?: any,
    handleErrorCallback?: any,
): IForm<T> {
    const [formValues, setFormValues] = useState<T>(initialValues);

    /** life cycle ID is tied to the new form state. That way we can resolve the promise
     * returned by inputProps.setValueAsync
     */
    const [lifeCycleID, setLifeCycleID] = useState("");

    /** This ref will store the tasks created by setStateAsync */
    const resolver = useRef<{
        lifeCycleID: string | number;
        resolve: (value: IForm<T> | PromiseLike<IForm<T>>) => void;
    }[]>([]);

    const initialErrors = useMemo(
        () => Object.keys(initialValues)
            .reduce((acc, k) => ({ ...acc, [k]: false }), {}) as T,
        [initialValues]
    )

    const [errorValidation, setErrorValidation] = useState<T>(initialErrors);

    const clearErrors = useCallback(
        () => setErrorValidation(initialErrors),
        [initialErrors]
    )

    useEffect(() => {
        setFormValues(initialValues)
    }, [JSON.stringify(initialValues)])

    const handleSubmit = async (e: any, values?: T): Promise<boolean> => {
        e.preventDefault();
        const errorMessages: any = {};
        try {
            await validator
                .validate(values || formValues, { abortEarly: false, context: context })
            if (submitAction) {
                return await submitAction(values || { ...formValues }, setFormValues);
            }
            return true;
        } catch (err) {
            debugLog({ err })
            if (err instanceof yup.ValidationError) {
                err?.inner?.forEach((e: any) => {
                    errorMessages[e.path] = e.message;
                });
                setErrorValidation(errorMessages);

                if (handleErrorCallback) {
                    handleErrorCallback(errorMessages);
                }
            }
            return false;
        }
    };

    /** Validates a specific field and it's value in the form.
     * You can override the value in the second parameter
     */
    const validateAt = useCallback(async (fieldName: keyof T, val?: string): Promise<boolean> => {
        try {
            typeof val == "string" ?
                await validator.validateAt(fieldName as string, { ...formValues, [fieldName]: val.trim() }) :
                await validator.validateAt(fieldName as string, formValues)
            setErrorValidation(prevState => ({
                ...prevState,
                [fieldName]: ""
            }))
            return true;
        } catch (err: any) {
            if (typeof err.message !== "string") {
                captureException(err)
                setErrorValidation(prevState => ({
                    ...prevState,
                    [fieldName]: JSON.stringify(err.message)
                }))
            } else {
                setErrorValidation(prevState => ({
                    ...prevState,
                    [fieldName]: err.message
                }))
            }
            return false;
        }
    }, [validator, formValues])

    const validateFields = useCallback(async (...fields: (keyof T)[]): Promise<boolean> => {
        for (var i in fields) {
            var result = await validateAt(fields[i])

            if (!result) return result
        }

        return true;
    }, [validateAt])

    const clear = useCallback(() => {
        setErrorValidation(initialValues)
        setFormValues(initialValues)
    }, [initialValues])

    const setFormValuesAsync = (setStateAction: SetStateAction<T>): Promise<IForm<T>> => {
        /** create a unique ID to identify the render that will come from this setState*/
        const lifeCycleID = generateID();

        setFormValues(setStateAction)

        /** Set an ID tied to the life cycle initiated by setting state in this function */
        setLifeCycleID(lifeCycleID)

        /** return a promise that will be resolved by the useEffect in this hook
         * This promise can be awaited by whoever fired the setValueAsync
         */
        return new Promise<IForm<T>>((resolve) => {
            resolver.current.push({
                lifeCycleID,
                resolve
            })
        })
    }

    const inputProps: { [key in keyof T]: IFieldProps<T> } = Object.keys(initialValues).reduce((acc, fieldName: keyof T) => ({
        ...acc,
        [fieldName]: {
            value: formValues[fieldName] ?? "",
            error: errorValidation[fieldName] || false,
            name: fieldName,
            onChange: function ({ target: { value: newValue } }: { target: { value: string } }) {
                setFormValues({
                    ...formValues,
                    [fieldName]: newValue
                })
            },
            setValue: function (value: string | ((prev: string) => string)) {
                if (typeof value === "function") {
                    setFormValues((prev: T) => {
                        return {
                            ...prev,
                            [fieldName]: value(prev[fieldName] as string)
                        }
                    })
                } else {
                    setFormValues((prev) => ({
                        ...prev,
                        [fieldName]: value
                    }))
                }
            },
            setValueAsync: function (value: string) {
                /** create a unique ID to identify the render that will come from this setState*/
                const lifeCycleID = generateID();

                setFormValues((prev) => ({
                    ...prev,
                    [fieldName]: value
                }))

                /** Set an ID tied to the life cycle initiated by setting state in this function */
                setLifeCycleID(lifeCycleID)

                /** return a promise that will be resolved by the useEffect in this hook
                 * This promise can be awaited by whoever fired the setValueAsync
                 */
                return new Promise<IForm<T>>((res) => {
                    resolver.current.push({
                        lifeCycleID,
                        resolve: res
                    })
                })
            },
            onBlur: function (value?: string) {
                if (typeof value !== "string") value = "";
                const inputVal = value || formValues[fieldName]
                /** trim input value if there is whitespace on front or end */
                if (
                    typeof inputVal === "string" &&
                    (inputVal as string).trim() !== inputVal
                ) {
                    const trimmedValue = (inputVal as string).trim()
                    setFormValues((prevValues) => ({
                        ...prevValues,
                        [fieldName]: trimmedValue
                    }))
                    validateAt(fieldName, trimmedValue)
                } else if (value) {
                    setFormValues((prevValues) => ({
                        ...prevValues,
                        [fieldName]: value
                    }))
                    validateAt(fieldName, value)
                } else {
                    validateAt(fieldName)
                }
            },
            validate: async (val?: string) => await validateAt(fieldName, val),
            hasChange: () => initialValues[fieldName] === formValues[fieldName],
            setError: function (message: string) {
                setErrorValidation(prevState => ({
                    ...prevState,
                    [fieldName]: message
                }))
            }
        } as IFieldProps<T>
    }), {}) as { [key in keyof T]: IFieldProps<T> };

    const form = {
        values: formValues,
        handleSubmit,
        clear,
        validateFields,
        inputProps,
        setFormValues,
        clearErrors,
        setFormValuesAsync
    }

    /** This useEffect is executed whenever the lifeCycleID changes */
    useEffect(() => {
        /** Using the lifeCycleID, we can determine which lifecycle we are on.
         * setState => start new life cycle => re-render
         * This insures that when this useEffect fires,
         * it holds the state values that were set from setStateAsync
         */
        if (lifeCycleID) {
            /** Find the task in the resolver ref */
            for (const item of resolver.current) {
                if (item.lifeCycleID === lifeCycleID) {
                    /** Resolve the promise created from setValueAsync */
                    item.resolve(form)

                    /** remove the promise's resolve function from the resolver ref*/
                    resolver.current = resolver.current.filter(cv => cv.lifeCycleID !== lifeCycleID)

                    /** End the for loop */
                    return;
                }
            }
        }
    }, [lifeCycleID])

    return form
}
