import { Box, Dialog, Tab, Tabs } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import { ISiteLinkForm, _FMS } from '../../facility-listing/components/FMSForm';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import { useForm } from '../../../hooks/useForm';
import { siteLinkValidator } from '../../../validators/validators';
import { PrimaryButton } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import TabPanel from '../../company-details/components/TabPanel';
import Input, { InputNoAutoFill } from '../../../core/form-components/Input';
import agents from '../../../api/agent';
import FacilityCSVForm from './FacilityCSVForm';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { PBold } from '../../../core/brand/typography';
import { HelperDialog, DialogHeader } from "./HelperDialog"
import ClearIcon from '@mui/icons-material/Clear';

const SiteLinkFacilityImportForm = ({ open, setOpen }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const [tab, setTab] = useState<number>(0);
    const { displayToast } = useContext(ToastContext);

    const initialValues: ISiteLinkForm = {
        corporateCode: "",
        corporateUsername: "",
        corporatePassword: "",
        postalCode: "",
        inMiles: false
    }

    const submit = async (formValues: any) => {
        const { corporateCode, corporateUsername, corporatePassword, postalCode, inMiles } = formValues

        try {
            const result = await agents.Company.importFacilitiesSitelink(corporateCode, corporateUsername, corporatePassword, postalCode, inMiles)
            if (result.success) {
                displayToast({ type: ToastType.Success })
            }
            displayToast({ type: ToastType.Error, text: result.error || "" })

        } catch (e: any) {
            displayToast({ type: ToastType.Error, text: e.message || "" })
        }
    }

    const form = useForm<ISiteLinkForm>(initialValues, submit, siteLinkValidator)
    return (
        <>
            <Dialog maxWidth={"xl"} open={open} onClose={() => setOpen(false)}>
                <DialogHeader>
                    <PBold secondary onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}><ClearIcon/></PBold> 
                </DialogHeader>
                <Box>
                    {tab == 0 ?
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", padding: "8px 12px" }}>
                            {strings.siteLinkPopup.map((info: string) => <PBold left>{info}</PBold>)}
                        </Box> :
                        <HelperDialog open={open} setOpen={setOpen} />
                    }
                </Box>
            </Dialog>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(_, val: number) => setTab(val)}>
                    <Tab label={strings.useApi} sx={{ fontSize: "20px", color: Colors.charcoal() }}></Tab>
                    <Tab label={strings.useCSV} sx={{ fontSize: "20px", color: Colors.charcoal() }}></Tab>
                </Tabs>
            </Box>
            <TabPanel
                value={tab}
                index={0}
            >
                <>
                    <Input  {...form.inputProps.corporateCode} label={strings.corpCode} />
                    <InputNoAutoFill  {...form.inputProps.corporateUsername} label={strings.corpUsername} autoComplete="off" />
                    <InputNoAutoFill {...form.inputProps.corporatePassword} label={strings.corpPassword} type="password" />
                    <PrimaryButton fullWidth onClick={form.handleSubmit}>
                        <FontAwesomeIcon size="lg" icon={faDownload as IconProp} style={{ marginRight: "8px" }} />
                        {strings.importFacilities}
                    </PrimaryButton>
                </>
            </TabPanel>
            <TabPanel
                value={tab}
                index={1}
            >
                <FacilityCSVForm managementSoftware={ManagementSoftware.SiteLink} openDialog={open} setOpenDialog={setOpen} />
            </TabPanel>
        </>
    );
}

export default SiteLinkFacilityImportForm;
