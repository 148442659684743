import React from "react";
import styled from "styled-components"
import links from "../../../routes/links"
import { strings } from "../../../content/strings"
import { PrimaryLink } from "../../../components/button"
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Colors } from "../../../core/brand/themes"
import { Section } from "../../../core/layout/Section"
import { Box } from "@mui/material";
import { PThin } from "../../../core/brand/typography";

const DemoSection = () => {
    const [mobile, tablet] = useMediaQuery(630, 1060);

    const IframeStyles =
        { width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px" };

    return (
        <DemoSectionStyled column={mobile}>
            <DemoSectionBox tablet={tablet} mobile={mobile}>
                <Box sx={{ m: 0, p: 0 }}>
                    <PThin secondary >{strings.marketingMaterial}</PThin>
                </Box>
                <Box sx={{ m: 0 }}>
                    <PrimaryLink {...links.HOWITWORKS}>{strings.howItWorks} </PrimaryLink>
                </Box>
            </DemoSectionBox>
            <DemoSectionBox tablet={tablet} mobile={mobile}>
                <VideoWrapper mobile={mobile}>
                    <iframe title="demo"
                        style={IframeStyles as any}
                        width="100%" height="100%"
                        src="https://www.youtube-nocookie.com/embed/sQ_ipUg3UGU?rel=0&showinfo=0U">
                    </iframe>
                </VideoWrapper>
            </DemoSectionBox>
        </DemoSectionStyled>
    )
}

const DemoSectionStyled = styled(Section)({
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '40px',
    paddingBottom: '50px',
    backgroundColor: Colors.navy()
});

const VideoWrapper = styled.div<{ mobile: boolean }>((props) => ({
    height: '100%',
    width: '100%',
    paddingTop: '56.25%',
    top: '0',
    left: '0',
    ...(!props.mobile ? {
        minHeight: '30px',
        minWidth: '170px',
    } : {})
}));

export const DemoSectionBox = styled.div<{ tablet: boolean, mobile: boolean }>((props) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '28%%',
    margin: "0 8px",
    ...(!props.tablet ? {
        width: '100%',
        margin: "0 4px",
    } : {}),
    ...(!props.mobile ? {
        width: '100%',
        margin: 0,
    } : {})
}));

export default DemoSection
