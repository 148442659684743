import React from "react";
import { filterOverLock, filterVacant, OverlockMap, VacantMap } from "../../../hooks/useAutoLockStatusChange";
import Select from '@mui/material/Select';
import {Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import { strings } from "../../../content/strings";
import styled from "styled-components";
import { Colors } from "../../../core/brand/themes";
import LockStatus, { lockStatuses } from "../../../enums/LockStatus";


const keepStatus = "Don't Change status";
export const SelectOverlockAutoStatus = ({ status, setStatus }: { status: number | null, setStatus: any }) => {
    const statusProps = {
        value:status,
        setValue: setStatus,
        error: "",
    }

    const handleChange = (event: any) => {
        const { target: { value } } = event;
        statusProps.setValue(LockStatus[value]);
      };

    return (
        <Box sx={{ minWidth: 260 }}>
        <FormControl fullWidth >
          <InputLabel shrink sx={{color:`${Colors.black()}!important`}}>{strings.selectStatus}</InputLabel>
          <StyledSelect
            value={statusProps.value}
            label={strings.selectStatus}
            onChange={handleChange}
            variant="standard"
          >
            {filterOverLock.map((status:string)=>{
                return(
                  status === keepStatus ? 
                  <MenuItem value={1004}>{status}</MenuItem>:
                    <MenuItem value={LockStatus[status as any]}>{status }</MenuItem>
                )
            })}
          </StyledSelect>
        </FormControl>
        </Box>
    );
}

export const SelectVacantAutoStatus = ({ status, setStatus }: { status: number | null, setStatus: any }) => {
    const statusProps = {
        value: status, 
        setValue: setStatus,
        error: "",
    }
    const handleChange = (event: any) => {
        const { target: { value } } = event;
        statusProps.setValue(LockStatus[value]);
      };

    return (
        <Box sx={{ minWidth: 260 }}>
        <FormControl fullWidth >
         <InputLabel shrink sx={{color:`${Colors.black()}!important`}}>{strings.selectStatus}</InputLabel>
          <StyledSelect
            value={statusProps.value}
            label={strings.selectStatus}
            onChange={handleChange}
            variant="standard"
          >
            {filterVacant.map((status:string)=>{
                return(
                  status === keepStatus ? 
                  <MenuItem value={1005}>{status}</MenuItem>:
                    <MenuItem value={LockStatus[status as any]}>{status }</MenuItem>
                )
            })}
          </StyledSelect>
        </FormControl>
        </Box>
    );
}

const StyledSelect = styled(Select)({
  ".MuiSelect-icon":{
      color:Colors.red(),
      fontSize:"34px"
  }
})