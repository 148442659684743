import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import InventoryContainer from "./components/InventoryContainer";
import { strings } from "../../content/strings";

const InventoryPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.inventory}
            pageDescription=""
            pageCanonical="www.davincilock.com/Company/InventoryLocks">
            <InventoryContainer />
        </LoggedInPageTemplate >
    );
};

export default InventoryPage;
