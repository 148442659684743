export function createThrottle<T>(cb: (...params: any[]) => Promise<T>, timeout: number = 2000) {
    const throttleRef = { current: false }
    return function (...params: any[]) {
        if (!throttleRef.current) {
            throttleRef.current = true
            setTimeout(() => {
                throttleRef.current = false
            }, timeout)
            return cb(...params)
        }
    }
}
