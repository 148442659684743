import { Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import agents from "../../../api/agent";
import { PrimaryButton } from "../../../components/button";
import Input from "../../../core/form-components/Input";
import LockStatus from "../../../enums/LockStatus";
import { IFieldProps } from "../../../hooks/useForm";
import { strings } from "../../../content/strings";
import AutoComplete from "../../../core/form-components/AutoComplete";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFacilities } from "../../../hooks/useFacilities";
import { ManagementSoftware } from "../../../enums/ManagementSoftware";
import alphanum from "../../../utils/alphanum-sort";
import { Unit, UnitStatus } from "../../../models/Unit";

interface IEditUnitProps {
    fieldProps: IFieldProps<any>,
    facilityID: number,
    secondaryLockStatus: number,
    trackClickEvent: (tag: string) => void,
    disableSubmit?: boolean
}

const isSolo = (secondaryLockStatus: LockStatus) => secondaryLockStatus === LockStatus["Assigned Other"]
    || secondaryLockStatus === LockStatus["On Dropbox"]
    || secondaryLockStatus === LockStatus["Assigned Vendor"]

export const EditUnitNumberField = ({ fieldProps, facilityID, secondaryLockStatus, trackClickEvent, disableSubmit: disable }: IEditUnitProps) => {
    const [unitNumberChanged, setUnitNumberChanged] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [units, setUnits] = useState<{ id: string, name: string, status: number }[]>([])

    const { getFacilityByID } = useFacilities();

    const unitNumbers = useMemo(() => units.map(x => x.name), [units])
    const unit = useMemo(() => units.length ? units.find(x => x.name === fieldProps.value) : null, [units, fieldProps.value])

    const facility = useMemo(() => getFacilityByID(facilityID), [facilityID, getFacilityByID]);
    const showRefreshButton = facility && ManagementSoftware[facility?.managementSoftware] !== ManagementSoftware[0];

    const getUnitNumbers = async () => {
        const result = await agents.Facilities.getUnits(facilityID);

        if (result.success) {
            var sortedData = result.data?.sort((a, b) => alphanum(a.name, b.name)) as Unit[]
            setUnits(sortedData);
        }

        setLoaded(true)
    };

    const getWarningMessage = (): string => {
        if (!loaded) { return ""; }
        if (!fieldProps.value) { return ""; }

        if (disable) {
            return strings.alertMultipleLocks;
        }

        if (unit?.status !== UnitStatus.Delinquent &&
            (secondaryLockStatus == LockStatus["Assigned Overlock"] || secondaryLockStatus == LockStatus["Assigned Auction"])) {
            return strings.alertUnitAssignment(true)
        }

        if (unit?.status !== UnitStatus.Vacant &&
            secondaryLockStatus == LockStatus["Assigned Vacant"]) {
            return strings.alertUnitAssignment(false)
        }

        return "";
    }

    useEffect(() => {
        getUnitNumbers();
    }, [facilityID]);

    // reset unitNumber chosen it moving from free form textfield to select text field.
    useEffect(() => {
        if (units.length && !isSolo(secondaryLockStatus) && !unit) {
            fieldProps.setValue("");
        }
    }, [secondaryLockStatus])

    const handleSelectUnit = async (unitNumber: string) => {
        fieldProps.setValue(unitNumber);
        await fieldProps.validate(unitNumber == null ? "" : unitNumber);
        setUnitNumberChanged(true);
    };

    const handleBlur = (e: any) => {
        setUnitNumberChanged(true);
        fieldProps.onBlur(e)
    }

    const handleRefreshUnits = async () => {
        trackClickEvent(analyticsEvents.refreshUnitNumbers)
        await agents.Facilities.refreshUnitNumbers(facilityID!);
        await getUnitNumbers();
    }

    const disableRefreshButton = () => {
        return LockStatus.isNotAssigned(secondaryLockStatus) ||
            secondaryLockStatus === LockStatus["Assigned Other"] ||
            secondaryLockStatus === LockStatus["On Dropbox"]
    }
    const renderUnitNumberField = () => {
        if (LockStatus.isNotAssigned(secondaryLockStatus)) {
            return <Input autoComplete="off" value="" disabled={true} label={strings.unitNumber} />;
        }

        if (units?.length) {
            return <AutoComplete
                solo={isSolo(secondaryLockStatus)}
                options={unitNumbers}
                {...fieldProps}
                setValue={handleSelectUnit}
                label={strings.unitNumber}
            />
        }

        return <Input autoComplete="off" {...fieldProps} onBlur={handleBlur} label={strings.unitNumber} />;
    }

    const warningMessage = getWarningMessage()

    return (
        <div style={{ position: "relative", marginBottom: "20px" }}>
            <FieldContainter>
                {renderUnitNumberField()}
                {showRefreshButton &&
                    <PrimaryButton disabled={disableRefreshButton()} style={{ marginTop: units?.length ? "24px" : "0" }} onClick={handleRefreshUnits}>{strings.refresh}</PrimaryButton>}
            </FieldContainter>

            <Tooltip
                title={warningMessage}
                open={unitNumberChanged && !!warningMessage}
                placement="left"
                arrow
                componentsProps={{
                    tooltip: { height: "40px", sx: { fontSize: "0.9rem", my: 0.5 }, }
                }}>
                <AssignmentWarningAnchor />
            </Tooltip>
        </div>
    );
};

const FieldContainter = styled.div<{ autoComplete?: boolean }>(({ autoComplete }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 72px",
}))

const AssignmentWarningAnchor = styled.div({
    position: "absolute",
    left: "8px",
    top: "24px",
    width: "80px",
})
