import React from "react";
import gears from "../../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../../core/layout/LoggedInPageTemplate"
import { termsOfUse } from "../../../content/termsOfUse";
import TermsOfUseContainer from "../components/TermsOfUseContainer";

const TermsOfUse = () => (
    <LoggedInPageTemplate
        backgroundImage={gears}
        pageTitle={termsOfUse.title}
        pageDescription=""
        pageCanonical="www.davincilock.com/Account/TermsOfUse">
        <TermsOfUseContainer />
    </LoggedInPageTemplate>
);

export default TermsOfUse;
