import React from "react";
import logowhite from "../../assets/images/davinciwhite.svg";
import { ImageBox, Image } from "../../components/styled/boxes";
import { strings } from "../../content/strings"
import { Copyright } from "../../core/brand/typography";
import { PrimaryLink, SecondaryLink } from "../../components/button";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

const Footer = () => {
    const { loggedInUser } = useLoggedInUser()

    return (
        <ImageBox>
            <Image src={logowhite} alt="" imgHeight="31px"></Image>
            <Copyright secondary style={{ margin: "10px 0 0 0" }}>&copy;{`${strings.copyright}`} </Copyright>
            {loggedInUser && <PrimaryLink link="/Account/PrivacyPolicy" style={{ marginTop: "12px" }}>Privacy  Policy</PrimaryLink>}
        </ImageBox>
    )
}
export default Footer
