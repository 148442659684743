import { Box, Dialog, Tab, Tabs } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import { _FMS } from '../../facility-listing/components/FMSForm';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import { useForm } from '../../../hooks/useForm';
import { PrimaryButton } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import agents from '../../../api/agent';
import TabPanel from '../../company-details/components/TabPanel';
import Input from '../../../core/form-components/Input';
import FacilityCSVForm from './FacilityCSVForm';
import * as yup from "yup";
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { PBold } from '../../../core/brand/typography';
import { HelperDialog, DialogHeader } from "./HelperDialog"
import ClearIcon from '@mui/icons-material/Clear';

const storEDGEValidator = yup.object().shape({
    companyApiKey: yup.string().required(strings.required(strings.companyApiKey)),
})
const StorEdgeFacilityImportForm = ({ open, setOpen }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const [tab, setTab] = useState<number>(0);
    const { displayToast } = useContext(ToastContext);

    const submit = async ({ companyApiKey }: { companyApiKey: string }) => {
        try {
            const result = await agents.Company.importFacilitiesStorEdge(companyApiKey)
            displayToast({ type: ToastType.Success })
            if (result.success) {
                displayToast({ type: ToastType.Error, text: result.error || "" })
            }

        } catch (e: any) {
            displayToast({ type: ToastType.Error, text: e.message || "" })
        }
    }

    const form = useForm<{ companyApiKey: string }>({ companyApiKey: "" }, submit, storEDGEValidator)

    return (
        <>
            <Dialog maxWidth={"xl"} open={open} onClose={() => setOpen(false)}>
                <DialogHeader>
                    <PBold secondary onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}><ClearIcon/></PBold> 
                </DialogHeader>
                <Box>
                    {tab == 0 ?
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", padding: "8px 12px" }}>
                            {strings.storeEDGEPopup.map((info: string) => <PBold left>{info}</PBold>)}
                        </Box>
                        :
                        <HelperDialog open={open} setOpen={setOpen} />
                    }

                </Box>
            </Dialog>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(_, val: number) => setTab(val)}>
                    <Tab label={strings.useApi} sx={{ fontSize: "20px", color: Colors.charcoal() }}></Tab>
                    <Tab label={strings.useCSV} sx={{ fontSize: "20px", color: Colors.charcoal() }}></Tab>
                </Tabs>
            </Box>
            <TabPanel
                value={tab}
                index={0}
            >
                <div style={{ marginTop: "24px" }}>
                    <Input {...form.inputProps.companyApiKey} label={strings.companyApiKey} />
                    <PrimaryButton fullWidth onClick={form.handleSubmit}>
                        <FontAwesomeIcon size="lg" icon={faDownload as IconProp} style={{ marginRight: "8px" }} />
                        {strings.importFacilities}
                    </PrimaryButton>
                </div>
            </TabPanel>
            <TabPanel
                value={tab}
                index={1}
            >
                <FacilityCSVForm managementSoftware={ManagementSoftware.storEDGE} openDialog={open} setOpenDialog={setOpen} />
            </TabPanel>
        </>
    )
}

export default StorEdgeFacilityImportForm
