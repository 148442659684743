import { Grid, Divider, FormGroup, FormControlLabel, Switch} from '@mui/material';
import React from 'react';
import { PrimaryButton } from '../../../components/button';
import { strings } from '../../../content/strings';
import { Colors } from '../../../core/brand/themes';
import { PBold } from "../../../core/brand/typography"
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import TemplateEditor from './TemplateEditor';
import BadgeIcon from '@mui/icons-material/Badge';
import BungalowIcon from '@mui/icons-material/Bungalow';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PasswordIcon from '@mui/icons-material/Password';
import { MessagesTemplateValue } from '../../../enums/MessagesTemplateValue';
import { useProfileSettings } from '../../../hooks/hooks';
import { MessageType } from '../../../enums/MessageType';

interface IMessagePreview {
    type: string,
    value: string,
    insertTemplateValue: any,
    preview: string,
    inputRef: any,
    onSelect: any,
    onKeyDown: any,
    onClick: any,
    onChange: any,
    onBlur: any,
    updateTemplate?: any,
    count: number,
    checkEmail: boolean,
    setCheckEmail: any,
    checkSms: boolean,
    setCheckSms: any,
}

const MessagePreview = ({ type, value, insertTemplateValue, preview, count,
    checkEmail,
    setCheckEmail,
    checkSms,
    setCheckSms,
    ...props }: IMessagePreview) => {
    const [mobile] = useMediaQuery(599);
    const { defaultMessage } = useProfileSettings()
    const resetToDefault = () => {
        props?.updateTemplate(defaultMessage)
    }

    const usedCheck = type === MessageType[0] ? { checked: checkSms, setter: setCheckSms } : { checked: checkEmail, setter: setCheckEmail }

    return (
        <Grid container xs={12} sx={{ padding: "12px 18px" }} gap="12px">
            <Grid item container sm={7} xs={12} >
                <Grid item container xs={11.9} justifyContent="space-between" alignItems={"center"} sx={{ p: "8px 0px" }} gap="0px">
                    <Grid item xs={12} sm={12} md={7.5}  >
                        <FormGroup sx={{ width: { lg: "44%", md: "85%", xs: "55%" } }}>
                            <FormControlLabel control={<Switch checked={usedCheck.checked} onChange={() => usedCheck.setter(!usedCheck.checked)} />}
                                label={
                                    <PBold style={{ marginTop: "8px", }}>{`Send a ${type}`}</PBold>
                                } />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3.8} >
                        <PrimaryButton fullWidth onClick={resetToDefault} >{strings.resetToDefault.toUpperCase()}</PrimaryButton>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TemplateEditor {...props} onChange={props.onChange} value={value} count={count} inputRef={props.inputRef} type={type} />
                </Grid>
                <Grid item container xs={12} gap="12px" sx={{ p: "8px 0px" }}>
                    <Grid item container sm={5.6} xs={12} justifyContent={{ sm: "center", xs: "start" }} gap="8px">
                        <PrimaryButton onClick={() => insertTemplateValue(MessagesTemplateValue[1])} fullWidth> <BadgeIcon /> {strings.insertName.toUpperCase()}</PrimaryButton>
                        <PrimaryButton fullWidth onClick={() => insertTemplateValue(MessagesTemplateValue[3])} ><ApartmentIcon /> {strings.insertCompany.toUpperCase()}</PrimaryButton>
                    </Grid>
                    <Grid item container sm={5.5} xs={12} justifyContent="center" gap="8px">
                        <PrimaryButton fullWidth onClick={() => insertTemplateValue(MessagesTemplateValue[2])} ><BungalowIcon /> {strings.insertFacility.toUpperCase()}</PrimaryButton>
                        <PrimaryButton fullWidth onClick={() => insertTemplateValue(MessagesTemplateValue[4])} ><PasswordIcon /> {strings.insertUnlockCodes.toUpperCase()}</PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sm={0.1} xs={12}>
                <Divider orientation={mobile ? "horizontal" : "vertical"} sx={{ background: Colors.red(), width: { xs: "100%", sm: "1px" }, height: "100%", overflow: "visible" }} />
            </Grid>
            <Grid item  sm={4} xs={12}>
                <TemplateEditor preview value={preview} count={count} />
            </Grid>
        </Grid>
    );
}

export default MessagePreview;
