import styled from "styled-components";
import React from "react";
import { Colors } from "../../core/brand/themes";

export const GridRow = styled.div({
    display: "flex",
    wordWrap: "break-word"
});

export const Image = styled.img<{
    imgHeight?: string;
    filter?: string;
    imgMargin?: string;
}>((props) => ({
    maxHeight: props.imgHeight,
    filter: props.filter,
    margin: props.imgMargin,
}));
export const ImageBox = styled.div({
    display: "flex",
    wordWrap: "break-word",
    zIndex: 20,
    alignItems: "center",
    width: "100%",
    height: "40px",
    justifyContent: "center",
    position: "absolute",
    bottom: "0px",
    background: Colors.red(),
    [`> ${Image}`]: {
        // all of the styled for the image child are applied in Image
    },
})

export const ImgWrapper = styled.div({
    display: "flex",
    wordWrap: "break-word",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap"
})

export const GridColumn = styled.div<{ width: string }>((props) => ({
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: props.width,
}));

export const Grid = styled.div<{ fixedWidth?: string }>((props) => ({
    width: props.fixedWidth ? props.fixedWidth : "70%",
    "@media (max-width: 1100px)": {
        width: "80%",
    },
    "@media (max-width: 1200px)": {
        width: "90%",
    },
    "@media (max-width: 1300px)": {
        width: "100%",
    },
}));

export const HorizontalGrid = ({
    items,
    id,
    fixedWidth,
}: {
    items: any[];
    fixedWidth?: string;
    id: string;
}) => {
    let result = [];
    if (Array.isArray(items[0]) && items[0][1]) {
        const total =
            Array.isArray(items[0]) && items.reduce((acc, cv) => acc + cv[1], 0);
        // calculating width -16px adds some overflow in the section img description images(substracting 56px seems to solve it but Idk how it will affect future usage)
        result = items.reduce(
            (acc, cv) => [...acc, [cv[0], `calc(${(100 / total) * cv[1]}% - 16px)`]],
            [],
        );
    } else {
        result = items.map((item) => [item, `calc(${100 / items.length}% - 16px)`]);
    }

    return (
        <Grid fixedWidth={fixedWidth}>
            <GridRow>
                {result.map((item: [any, string], i: number) => (
                    <GridColumn key={`grid-${id}-${i}`} width={item[1]}>
                        {item[0]}
                    </GridColumn>
                ))}
            </GridRow>
        </Grid>
    );
};

export const GridItem = ({ el, span }: { el: any; span?: number }) => [
    el,
    span || 1,
];
