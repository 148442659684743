import { FormObject, IForm, useForm } from './useForm';
import * as yup from "yup";
import { useCallback, useEffect, useRef, useState } from 'react';

export function useFormFlow2<T extends FormObject>({
    initialValues, submitAction, validator, handleErrorCallback, steps, loadProgress,
}: UseFormFlowProps<T>) {
    const form = useForm<T>(initialValues, submitAction, validator, handleErrorCallback);
    const [activeStep, setActiveStep] = useState<number>(0);
    const furthestProgress = useRef(0);
    const [loadingProgress, setLoadingProgress] = useState(loadProgress || false);
    furthestProgress.current = furthestProgress.current < activeStep ? activeStep : furthestProgress.current;

    const currentStep = steps[activeStep];

    /** runs validation on the default form or form override and then proceed to next step
     * @param updatedForm: optional override - can override the form with a different version of the form
     * @param numOfSteps: optional override - can move forward more than 1 step
     */
    const next = useCallback(async (
        updatedForm?: IForm<T> | null,
        numOfSteps?: number
    ) => {
        if (!currentStep.validate ||
            await currentStep.validate(updatedForm || form)) {
            setActiveStep((prev) => prev + (numOfSteps || 1));
        }
    }, [currentStep]);

    const back = useCallback(() => setActiveStep(activeStep - 1), [activeStep]);

    useEffect(() => {
        if (loadProgress) {
            goToProgress();
        }
    }, []);

    /** Reads form values and progresses through each
     * step until it fails validation
     */
    const goToProgress = useCallback(async () => {
        let stepIndex = 0;
        while ((!steps[stepIndex].validate || await steps[stepIndex].validate!(form))
            && stepIndex < steps.length - 1) {
            stepIndex++;
        }

        form.clearErrors();

        setActiveStep(stepIndex);
        setLoadingProgress(false);
    }, [form]);

    return {
        currentStep,
        form,
        next,
        back,
        goToProgress,
        activeStep,
        setActiveStep,
        loadingProgress,
        furthestProgress: furthestProgress.current
    };
}

export type FormFlowRenderProps<T extends FormObject> = {
    form: IForm<T>;
    back: any;
    next: any;
    submit: any;
    analytics?: any
};

export interface IStep<T extends FormObject> {
    validate: (form: IForm<T>) => Promise<boolean>;
    Render: (props: FormFlowRenderProps<T>) => JSX.Element;
    [key: string]: any;
}

export type UseFormFlowProps<T extends FormObject> = {
    initialValues: T;
    submitAction: null | ((arg0: T, arg1: (arg0: T) => any) => any);
    validator: yup.ObjectSchema<any>;
    handleErrorCallback?: any;
    steps: IStep<T>[];
    loadProgress?: boolean;
};
