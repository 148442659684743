import { useContext, useMemo, useState } from "react"
import styled from "styled-components"
import { PBolder } from "../../core/brand/typography";
import { Colors } from "../../core/brand/themes"
import Role from "../../enums/Role";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigateToRazorPage } from "../../api/agent";
import { useLocation, useNavigate } from "react-router-dom";
import { doesPathMatchLink, INavigation } from "../../routes/Navigation";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import Collapse from '@mui/material/Collapse';
import useAnalytics from "../../hooks/useAnalytics";
import { ConditionalTooltip } from "../common/ConditionalTooltip";
import { useLogout } from "../../hooks/useLogout";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { strings } from "../../content/strings";
import { SubscriptionContext } from "../../contexts/SubscriptionProvider";
import { ILoggedInUser } from "../../models/ILoggedInUser";

const componentName = "Nav Menu"

const ChildrenItems = ({ children, loggedInUser }: { children: INavigation[], loggedInUser: ILoggedInUser | null }) => {
    const navigate = useNavigate();
    const { trackClickEvent } = useAnalytics(componentName, false);

    return (
        <>
            {
                children?.map((item) => {
                    const isActive = doesPathMatchLink(item.link, location);
                    const specialIcon = item.text == strings.davinciLockPremium;

                    const onClick = async () => {
                        if (item.analyticsTag) {
                            await trackClickEvent(item.analyticsTag);
                        }
                        if (item.link.link) {
                            navigate(item.link.link);
                        } else if (item.link.href) {
                            navigateToRazorPage(item.link.href || "");
                        }
                    }
                    {/**@ts-ignore */ }
                    return <NavMenuItem
                        loggedInUser={loggedInUser}
                        key={item.text}
                        child
                        item={item}
                        active={!!isActive}
                        premium={specialIcon}
                        onClick={onClick} />
                })
            }
        </>
    )
}

const NavMenuItem = ({ child, active, item, onClick, premium, loggedInUser }: { active: boolean, child?: boolean, item: INavigation, onClick: () => void, premium: boolean, loggedInUser: ILoggedInUser | null }) => {
    const [disabled, disabledMessage] = item.disabled ? item.disabled(loggedInUser) : []
    const hidden = item.hidden ? item.hidden(loggedInUser) : false;

    const renderMenuItem = useMemo(() => (
        <MenuItem
            onClick={() => !disabled && onClick()}
            secondary={item.logout}
            premium={premium}
            child={child}
            disabled={disabled}
            active={active}>
            <MenuText>
                <FontAwesomeIcon icon={item.icon as IconProp}></FontAwesomeIcon>
            </MenuText>
            <MenuText>{item.text}</MenuText>
        </MenuItem>

    ), [disabled])

    return (
        !hidden &&
        <ConditionalTooltip
            message={disabledMessage || item?.tooltip}
            enabled={disabled || item?.tooltip}
            placement="right"
        >
            {renderMenuItem}
        </ConditionalTooltip>
    )
}

const NavMenu = () => {
    const navigate = useNavigate();
    const { loggedInUser, loading, isSubscribed } = useLoggedInUser();
    const location = useLocation();
    const { logout } = useLogout()
    const { trackClickEvent } = useAnalytics(componentName, false);
    const { handleOpen, renderDialog } = useContext(SubscriptionContext)

    if (loading || !loggedInUser?.roleID) return null

    let navItems = Role.navigationPermissions(loggedInUser.roleID, loggedInUser.permissions)
    if (isSubscribed()) {
        navItems = navItems.filter((item: INavigation) => item.text !== strings.davinciLockPremium)
    }

    return (
        <MenuStyles>
            {navItems.map((item) => {
                const isActive = doesPathMatchLink(item.link, location);
                const specialIcon = item.text == strings.davinciLockPremium;
                const onClick = async () => {
                    if (item.analyticsTag) {
                        await trackClickEvent(item.analyticsTag);
                    }
                    if (item.logout) {
                        logout()
                    } else if (item.link.link) {
                        navigate(item.link.link);
                    } else if (item.link.href) {
                        navigateToRazorPage(item.link.href || "");
                    }
                    if (item.popup) {
                        handleOpen && handleOpen();
                        renderDialog && renderDialog();
                    }
                }
                if (item.children) {
                    return (
                        <NestedMenuItem loggedInUser={loggedInUser} key={item.text} item={item} active={isActive} secondary={item.logout} />
                    )
                }
                {/**@ts-ignore */ }
                return <NavMenuItem loggedInUser={loggedInUser} key={item.text} item={item} active={!!isActive} onClick={onClick} premium={specialIcon} />
            })}
        </MenuStyles>
    )
}

const NestedMenuItem = (
    { active, secondary, item, loggedInUser }:
        { active?: boolean, secondary?: boolean, item: INavigation, loggedInUser: ILoggedInUser | null }) => {
    const [visible, setVisible] = useState(
        item.children.some((child: INavigation) => doesPathMatchLink(child.link, location))
    )
    const { trackClickEvent } = useAnalytics(componentName, false);

    const handleClick = () => {
        setVisible(!visible)
        if (item.analyticsTag) {
            trackClickEvent(item.analyticsTag);
        }
    }
    return (
        <>
            <MenuItem active={active} secondary={secondary} onClick={handleClick}>
                <MenuText>
                    <StyledIcon size="lg" flip={visible ? "vertical" : "horizontal"} icon={faCaretUp as IconProp}></StyledIcon>
                    <FontAwesomeIcon icon={item.icon as IconProp}></FontAwesomeIcon>
                </MenuText>
                <MenuText>{item.text}</MenuText>
            </MenuItem>
            <Collapse in={visible}>
                <ChildrenItems loggedInUser={loggedInUser} children={item.children!}></ChildrenItems>
            </Collapse>
        </>
    )
}

export default NavMenu

const MenuStyles = styled.div({
    border: "solid black black",
    background: Colors.white(),
    minHeight: "81.5vh",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
        height: "0px",
        width: "0px",
    },
})

const MenuItem = styled.div<{ active?: boolean, secondary?: boolean, child?: boolean, disabled?: boolean, premium?: boolean }>(({ active, secondary, child, disabled, premium }) => ({
    position: "relative",
    alignContent: "center",
    padding: "8px 24px",
    display: "grid",
    gridTemplateColumns: "30px 1fr",
    cursor: "pointer",
    gap: "4px",
    svg: {
        color: Colors.red(),
        fontSize: "1.1rem",
    },
    ...(secondary ? {
        background: Colors.yellow(),
        color: Colors.navy(),
        svg: {
            color: Colors.navy(),
        }
    } : {}),
    ...(premium ? {
        background: Colors.navy(),
        color: Colors.yellow(),
        "svg, p": {
            color: Colors.yellow(),
        },
    } : {}),
    ...(child ? {
        gridTemplateColumns: "30px 1fr",
        p: {
            marginLeft: "35px",
        },
        gap: "2px"
    } : {}),
    height: "50px",
    ...(active ? {
        background: Colors.red(),
        "svg, p": {
            color: Colors.white(),
        }
    } : {}),
    ...(disabled ? {
        "svg, p": {
            color: Colors.lightgrey(),
        }
    } : {
        "&:hover": {
            // just place holder until we decide what the premium active state colors are!
            backgroundColor: active ? Colors.red() : premium ? Colors.yellow() : Colors.navy(),
            color: Colors.white(),
            "p, svg": {
                color: premium ? Colors.red() : Colors.white()
            }
        },
        "@media (max-width: 414px)": {
            gap: "16px"
        },
    })
}))

const MenuText = styled(PBolder)({
    margin: "0 0 0 8px",
    padding: 0,
    textAlign: "left",
})

const StyledIcon = styled(FontAwesomeIcon)({
    margin: "0  6px 0 -18px",
})
