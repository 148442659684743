import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import CustomMessageContainer from "./components/CustomMessageContainer";

const CustomMessage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={"Custom Message"}
            pageDescription={"Custom Message"}
            pageCanonical={"https://www.davincilock.com/Company/CustomMessage"}
        >
            <CustomMessageContainer />
        </LoggedInPageTemplate >
    )
}

export default CustomMessage
