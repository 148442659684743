import React from "react";
import { Paper, Box } from '@mui/material';
import { H4Bold, PBold, PMedium } from "../../../core/brand/typography";
import styled from "@emotion/styled";
import { strings } from "../../../content/strings";
import { PrimaryButton } from "../../../components/button";
import { ICMSDocument } from "../../../content/common/cmsSchema";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../core/brand/themes";

const contentStyles = (t: any) => {
    return {
        textAlign: t.center && "center" || "left",
        display: t.center && "block" || "inline",
        textDecoration: t.underline && "underline" || "initial",
        fontWeight: t.bold && "500" || "300",
        marginBottom: 0,
        marginBlockEnd: 0
    }
}

const emailLink = (email: string) => {
    return "mailto:" + email
}

const makeText = (t: any) => {
    return (
        <>
            {t.text && <PMedium style={{ ...contentStyles(t) }}>{t.text}</PMedium>}
            {t.link && <a style={{ display: "inline" }} target="_blank" href={t.link.link}>{t.link.text}</a>}
            {t.links && t.links.map((link: string) => {
                return (
                    <>
                        <span> </span>
                        <span>
                            <a style={{ display: "inline" }} target="_blank" href={link}>{link}</a>
                            <PMedium style={{ display: "inline" }}>,</PMedium>
                        </span>
                    </>
                )
            })}
            {t.email && <a style={{ display: "inline" }} target="_blank" href={emailLink(t.email)}>{t.email}</a>}
            {t.newline && <p style={{ marginTop: 0, marginBottom: t.newline }}></p>}
            {t.type === "ol" && <ol type={t.olType}>
                {t.items.map((item: any) => {
                    return (
                        <li>
                            {item.content.map((content: any) => {
                                return (
                                    <>
                                        {makeText(content)}
                                    </>
                                )
                            })}
                        </li>
                    )
                })}
            </ol>}
            {t.type === "ul" && <ul>
                {t.items.map((item: any) => {
                    return (
                        <li>
                            {item.content.map((content: any) => {
                                return (
                                    <>
                                        {makeText(content)}
                                    </>
                                )
                            })}
                        </li>
                    )
                })}
            </ul>}
        </>
    )
}

const CMSDocumentRenderer = ({ cmsDocument, handleBack }: { cmsDocument: ICMSDocument, handleBack?: () => void }) => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/")
    }

    return (
        <Box sx={{
            margin: "16px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            width: "100%",
            height: "calc(100% - 40px)",
        }}>
            <Card >
                <Text>
                    <PBold>{cmsDocument.title}</PBold>
                    <PBold>{cmsDocument.effectiveDate}</PBold>
                </Text>
                {cmsDocument.content.map((content: any) => {
                    return (
                        <>
                            {content.title && <Text><H4Bold style={{ textAlign: "left" }}>{content.title}</H4Bold></Text>}
                            <span style={{ padding: "0px 12px", width: "100%", boxSizing: "border-box" }}>
                                {content.content.map((t: any) => {
                                    return (
                                        <>
                                            {makeText(t)}
                                        </>
                                    )
                                })}
                            </span>
                        </>
                    )
                })}
            </Card >
            <PrimaryButton
                style={{ alignSelf: "center" }}
                onClick={handleBack ? handleBack : goHome}>{strings.back}</PrimaryButton>
        </Box >
    )
}

const Card = styled("span")({
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: 'start',
    gap: "16px",
    width: "100%",
    height: "600px",
    overflowY: "scroll",
    boxSizing: "border-box",
    alignSelf: 'center',
    borderBottom: `1px solid ${Colors.lightergrey()}`,
})

const Text = styled.div({
    alignContent: "start",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "8px",
    padding: "4px"
});

export default CMSDocumentRenderer
