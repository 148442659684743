import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { FacilityDto } from "../../../models/facility";
import { strings } from "../../../content/strings";
import { Box, Dialog, Tooltip } from "@mui/material";
import { TransferList } from "../../../core/form-components/TransferList";
import { useForm } from "../../../hooks/useForm";
import { subdomainValidator } from "../../../validators/validators";
import { ISubdomain } from "../../../models/ISubdomain";
import Input from "../../../core/form-components/Input";
import DropUpload from "../../../core/form-components/DropUpload";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { H4Bold } from "../../../core/brand/typography";
import agents from "../../../api/agent";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { LocksDataHeader, CloseBladeButton } from "./CompanySubdomainsContainer";
import WarningIcon from '@mui/icons-material/Warning';
import { useFacilities } from "../../../hooks/useFacilities";
import { ApiActions } from "../../../enums/ApiActions";

const addUpdateSubdomain = {
    [ApiActions.ADD]: agents.Company.addSubdomain,
    [ApiActions.EDIT]: agents.Company.updateSubdomain
}

const sanitizeOnChange = (val: string) => val.toLowerCase().replace(/[^a-z0-9\-]/gmi, "")
const sanitizeOnBlur = (val: string) => sanitizeOnChange(val).replace(/^\-+|\-+$/g, '')

export function AddEditSubdomain({
    openDialog, handleDialog, fetchSubdomains, initialValues, trackClickEvent
}: {
    openDialog: boolean;
    handleDialog: () => void;
    fetchSubdomains: () => void;
    initialValues: ISubdomain;
    trackClickEvent: (arg0: string, arg1?: any) => void;
}) {
    const { facilities, fetchUserFacilities } = useFacilities();
    const { displayToast } = useContext(ToastContext);
    const [file, setFile] = useState<FormData | null>(null)

    const submitAction = async (formValues: ISubdomain) => {
        const addUpdateKey = initialValues.id ? ApiActions.EDIT : ApiActions.ADD;

        trackClickEvent("Add/Update Subdomain")
        const fieldsHaveNotChanged = () => formValues.displayName === initialValues.displayName &&
            formValues.subdomainUrl === initialValues.subdomainUrl &&
            formValues.facilityIDs?.length === initialValues.facilityIDs?.length &&
            formValues.facilityIDs?.every(id => initialValues.facilityIDs?.includes(id));

        const updateSubdomain = async (): Promise<number> => {
            if (fieldsHaveNotChanged()) {
                return formValues.id!;
            }
            const result = await addUpdateSubdomain[addUpdateKey](formValues);

            if (result.success && result.data?.id) {
                displayToast({ type: ToastType.Success, text: strings.subdomainUpdated });
                return result.data.id;
            } else {
                displayToast({ type: ToastType.Error, text: result.error || strings.subdomainUpdateFailed });
            }

            return 0;
        };

        const changeLogo = async (file: FormData, subdomainID: number) => {
            if (file.has("file")) {
                return agents.Company.uploadSubdomainLogo(file, subdomainID as number);
            } else {
                if (initialValues.logo) {
                    return agents.Company.deleteSubdomainLogo(subdomainID as number)
                }
            }
            return null
        }

        try {
            let subdomainID = await updateSubdomain();

            if (subdomainID && file) {
                const result = await changeLogo(file, subdomainID)

                if (!result) {
                    /** noop */
                } else if (result.success) {
                    displayToast({ type: ToastType.Success, text: strings.subdomainLogoUpdated });
                } else {
                    displayToast({ type: ToastType.Error, text: result.error || strings.subdomainLogoUpdateFailed });
                }
            }

            if (subdomainID == 0) {
                return;
            }
        } catch (e: any) {
            displayToast({ type: ToastType.Error, text: e.message });
        }

        fetchUserFacilities()
        fetchSubdomains();
        handleDialog();
    };

    const form = useForm<ISubdomain>(
        initialValues, submitAction, subdomainValidator
    );

    const controlUrlInput = (e: any) => {
        form.inputProps.subdomainUrl.setValue(
            sanitizeOnChange(e.target.value)
        )
    }

    const controlUrlBlur = (e: any) => {
        form.inputProps.subdomainUrl.onBlur(
            sanitizeOnBlur(form.values.subdomainUrl)
        )
    }

    const facilitiesInputProps = form.inputProps.facilityIDs;

    const assignedUnassignedFacilities = facilities.reduce((acc: { assigned: FacilityDto[]; unassigned: FacilityDto[]; }, cv: FacilityDto) => {
        if (form.values?.facilityIDs?.includes(cv.facilityID!)) {
            return { ...acc, assigned: [...acc.assigned, cv] };
        }
        return { ...acc, unassigned: [...acc.unassigned, cv] };
    }, { assigned: [], unassigned: [] });

    const [isMobile] = useMediaQuery(485);

    return (
        <Dialog sx={{ zIndex: 50 }} open={openDialog} fullScreen={isMobile} maxWidth={"xl"} onClose={handleDialog}>
            <Box sx={{
                position: "relative",
                width: isMobile ? "100vw" : "80vw",
            }}>
                <LocksDataHeader>
                    <span>{initialValues.id ? strings.editSubdomain : strings.addSubdomain}</span>
                    <CloseBladeButton onClick={handleDialog}>X</CloseBladeButton>
                </LocksDataHeader>

                <Box
                    style={{
                        overflowX: "hidden",
                    }}
                    sx={{
                        padding: isMobile ? "24px" : "24px 48px",
                        position: "relative",
                    }}>
                    <ThreeColumnTemplate>
                        <div>
                            <Input {...form.inputProps.displayName} label={strings.displayName} />
                            <Input
                                {...form.inputProps.subdomainUrl}
                                label={strings.subdomainUrl}
                                onBlur={controlUrlBlur}
                                onChange={controlUrlInput} />
                        </div>
                        <div />
                        <div>
                            <H4Bold>Upload Logo:</H4Bold>
                            <DropUpload onChange={setFile} hash={initialValues.logo} />
                        </div>
                    </ThreeColumnTemplate>

                    <TransferList
                        onChange={(_: FacilityDto[], b: FacilityDto[]) => {
                            facilitiesInputProps.setValue(b.map(f => f.facilityID!));
                        }}
                        itemConditionalRender={(item: FacilityDto) => {
                            if (item.companySubdomainID && (!form.values.id || form.values.id !== item.companySubdomainID)) {
                                return (
                                    <Tooltip
                                        title={"Already assigned to a different subdomain"}
                                        arrow
                                        placement="top"
                                        componentsProps={{
                                            tooltip: { height: "40px", sx: { fontSize: "0.9rem", my: 0.5 }, }
                                        }}
                                    >
                                        <WarningIcon color="warning" />
                                    </Tooltip>
                                )
                            }
                            return null;
                        }}
                        list1={assignedUnassignedFacilities.unassigned}
                        list2={assignedUnassignedFacilities.assigned}
                        displayProp={"facilityName"} />

                    <ThreeColumnTemplate style={{ marginTop: "16px" }}>
                        <QuaternaryButton fullWidth onClick={handleDialog}>{strings.cancel}</QuaternaryButton>
                        <div />
                        <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
                    </ThreeColumnTemplate>
                </Box>
            </Box>
        </Dialog>
    );
}
const ThreeColumnTemplate = styled.div({
    display: "grid",
    gridTemplateColumns: "1fr 100px 1fr",
    columnGap: "8px",
    width: "100%",
    marginBottom: "16px"
});
