import React from 'react';
import { Box } from '@mui/material';
import { Card } from '../../../core/surfaces/Card';
import StatsCard from './StatsCard'
import AutoMessagesContainer from './AutoMessagesContainer'
import { useProfileSettings } from '../../../hooks/hooks';

const AutoNotificationContainer = () => {
    const { messageStats } = useProfileSettings()

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "space-around", mx: "8px" }}>
            <Card style={{ flexBasis: "10.5%", display: "flex", padding: "8px 4px", boxSizing: "border-box", marginTop: "42px", minHeight: "138px" }}>
                <StatsCard data={messageStats} />
            </Card>
            <AutoMessagesContainer />
        </Box>
    );
}

export default AutoNotificationContainer;
