export interface Unit {
    id: string;
    name: string;
    combination: string;
    status: UnitStatus;
}

export enum UnitStatus {
    Default = 0,
    Delinquent = 1,
    Vacant = 2
}
