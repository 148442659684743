export default {
    LANDING: { link: "/" },
    LOGIN: { link: "/Account/Login" },
    TERMSOFUSE: { link: "/Account/TermsOfUse" },
    PRIVACYPOLICY: { link: "/Account/PrivacyPolicy" },
    LOGOFF: { href: "/Account/LogOff" },
    SETTINGS: { href: "/Users/Profile" },
    FORGOTPASSWORD: { link: "/Account/ForgotPassword" },
    CREATECOMPANY: { link: "/Account/CreateCompany" },
    LEADFORM: { link: "/Account/GettingStarted" },
    HOWITWORKS: { link: "/Account/HowItWorks" }, // turned off React route to this page so we can get the login page in production.
    STORE: { href: "https://davincilockstore.com" },
    SUPPORT: { href: "https://davincilock.zendesk.com/hc/en-us", external: true },
    COMPANYMANAGEMENT: { href: "https://davincilock.zendesk.com/hc/en-us/articles/360057491373-How-do-I-connect-a-facility-to-my-facility-management-software-", external: true },
    REQUEST_DEMO: { href: "https://docs.google.com/forms/d/e/1FAIpQLSejRYgIxFjKuZ1yDw_ilwSpNsRdINHUxbFRqLKR4ny0Qvs9-w/viewform", popup: true }
}
