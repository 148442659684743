import React, { useLayoutEffect } from "react";
import { strings } from "../../../content/strings";
import { Step } from "../../../hooks/useFormFlow";
import { ICreateCompanyForm } from "../../../models/models";
import Input from "../../../core/form-components/Input";
import { IForm } from "../../../hooks/useForm";
import { StepExtension } from "./CreateCompanyFlow";

export const RenderInputFields = (
    { currentStep, form }: { currentStep: Step<ICreateCompanyForm, StepExtension>; form: IForm<ICreateCompanyForm>; }
) => {
    if (currentStep.render) {
        //@ts-ignore
        return currentStep.render(form);
    }

    useLayoutEffect(() => {
        const input = document.getElementsByTagName("input")[0];
        if (input) {
            input.focus();
        }
    }, [JSON.stringify(currentStep.fields)]);

    return <>
        {currentStep.fields.map(f => f.component ?
            f.component(form.inputProps[f.key]) :
            <Input {...form.inputProps[f.key]} label={f.label || strings[f.key as keyof typeof strings]} />
        )}
    </>;
};
