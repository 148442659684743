import React, { useCallback, useContext, useEffect, useRef } from "react";
import { strings } from "../../content/strings";
import { useFacilities } from "../../hooks/useFacilities";
import { Obj } from "../../models/obj";
import { LocationContext } from "../../contexts/LocationContext";
import AutoCompleteDynamic from "./AutoCompleteDynamic";
import { FacilityDto } from "../../models/facility";

export interface SelectAutoCompleteProps {
    handleClear?: any;
    facilityID?: number
    values?: Obj[] | string[],
    setValue: any,
    errorMessage?: string,
    error?: string,
    label?: string,
    value?: any,
    propName?: string,
    useCategories?: boolean,
    handleClearCallback?: any,
    disabled?: boolean,
    noClear?: boolean,
    noSort?: boolean,
    displayProp?: string,
    analyticsTag?: string,
    endAdornment?: any,
    geolocate?: boolean
}

export const SelectFacility = ({ setValue, handleClear, facilityID, geolocate, ...props }: SelectAutoCompleteProps): JSX.Element => {
    const { facilities } = useFacilities();
    const {
        nearestFacility,
        defaultFacility,
        setDefaultFacility,
        locationToggleIcon
    } = useContext(LocationContext)

    const getFacilityValueProps = () => {
        return { value: facilityID }
    }

    const mountedRef = useRef(false)

    useEffect(() => {
        if (facilities.length === 1) {
            setValue(facilities[0])
        } else if (nearestFacility) {
            mountedRef.current = true
            geolocate && setValue(nearestFacility)
        } else if (defaultFacility) {
            // only set default facility on first mount. Otherwise we
            // are setting state in multiple places and causing
            // unecessary re-renders.
            geolocate && !mountedRef.current && setValue(defaultFacility)
            mountedRef.current = true
        } else {
            setValue({})
        }
    }, [nearestFacility, defaultFacility])

    const handleSetValue = useCallback((val: FacilityDto) => {
        geolocate && setDefaultFacility(val)
        setValue(val)
    }, [setValue, setDefaultFacility])

    return (
        <AutoCompleteDynamic
            {...props}
            propName={props.propName || "facilityName"}
            options={props.values || facilities}
            label={strings.selectFacilityLabel}
            setValue={handleSetValue}
            targetProp="facilityID"
            endAdornment={geolocate ? locationToggleIcon : null}
            analyticsTag="Facility Select"
            {...getFacilityValueProps()}
        />
    );
};
