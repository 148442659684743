import { useEffect, useState } from "react";

export interface LatLongResult {
    latitude: number,
    longitude: number,
    loading?: boolean,
    error?: GeolocationPositionError | null
}

export const useLatLong = () => {
    const [currentPosition, setCurrentPosition] = useState<LatLongResult>({
        latitude: 0,
        longitude: 0,
        loading: true,
        error: null
    });

    useEffect(() => {
        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(
                (res: GeolocationPosition) => {
                    setCurrentPosition({
                        latitude: res.coords.latitude,
                        longitude: res.coords.longitude,
                        loading: false,
                        error: null
                    })
                }, (err: GeolocationPositionError) => {
                    setCurrentPosition(prevState => ({
                        ...prevState,
                        error: err
                    }))
                }, {
                enableHighAccuracy: false,
                timeout: 15000,
                maximumAge: 0
            });
        }
    }, [])

    return { currentPosition }
}
