import agents from "../../../api/agent";
import Input from "../../../core/form-components/Input";
import links from "../../../routes/links"
import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "../../../core/surfaces/Card";
import { Form } from "../../../core/form-components/Form";
import { H2, FormErrorText, PMedium } from "../../../core/brand/typography";
import { IAccount } from "../../../models/models";
import { PrimaryButton, PrimaryLink } from "../../../components/button";
import { resetPasswordValidator } from "../../../validators/validators"
import { strings } from "../../../content/strings";
import { useForm } from "../../../hooks/useForm";
import { useQuery } from "../../../hooks/useQuery";

const ResetPasswordForm = () => {
    const token = useQuery("UID");
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const initialValues: IAccount["resetPassword"] = { password: "", confirmPassword: "", uid: token as string };

    const submitAction = async (formValues: IAccount["resetPassword"], setFormValues: (arg0: IAccount["resetPassword"]) => any) => {
        const result = await agents.Auth.updatePasswordWithToken(formValues);
        if (result.success) {
            setSuccess(true)
        } else {
            setErrorMessage(result.error! || strings.somethingWentWrong);
        }
    }
    const form = useForm<IAccount["resetPassword"]>(
        initialValues, submitAction, resetPasswordValidator
    );

    if (success) {
        return (
            <SuccessCard>
                <PMedium>{strings.setPasswordSuccess}</PMedium>
                <PrimaryLink fullWidth {...links.LOGIN}>{strings.login}</PrimaryLink>
            </SuccessCard>
        )
    } else {
        return (
            <FormCard>
                <Form>
                    <TitleContainer>
                        <H2>{strings.resetPassword}</H2>
                    </TitleContainer>
                    <Input {...form.inputProps.password} required type="password" label={strings.password} />
                    <Input {...form.inputProps.confirmPassword} required type="password" label={strings.confirmPassword} />
                    <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
                </Form>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </FormCard>
        )
    }
}

const FormCard = styled(Card)({ width: "360px", position: "relative", paddingBottom: "24px" })
const ErrorMessage = styled(FormErrorText)({
    position: "absolute",
    bottom: "8px",
    left: "20px"
})

const TitleContainer = styled.div({
    display: "grid",
    rowGap: "8px",
    marginBottom: "24px",
    textAlign: "center"
})

const SuccessCard = styled(FormCard)({
    display: "grid",
    rowGap: "20px",
    width: "300px",
    padding: "45px 20px 30px 20px",
})

export default ResetPasswordForm
