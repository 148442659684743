import React, { ChangeEvent, useEffect, useState } from "react"
import Input from "../../core/form-components/Input";
import styled from 'styled-components'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Collapsable } from "../../components/styled/transitions";
import { Colors, Styles } from "../../core/brand/themes";
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FilterButton } from "../../components/styled/filter-button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PBolder } from "../../core/brand/typography";
import { PrimaryButton, QuaternaryButton } from "../../components/button";
import { strings } from "../../content/strings";
import AutoCompleteDynamic from "../form-components/AutoCompleteDynamic";
import { HeaderProps } from "./Table"
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useAnalytics from "../../hooks/useAnalytics";
import { Tooltip } from "@mui/material";

type TableHeaderProps = {
    headers?: any[],
    setSortData: (obj: any) => void,
    setFilterData: (obj: any) => void,
    useServer?: boolean,
    filter?: boolean,
    tooltip?: string
}

const TableHeader = ({ headers, setSortData, setFilterData, useServer, filter = true }: TableHeaderProps) => {
    const [showFilter, setShowFilter] = useState(-1)
    const [filterIcon, setFilterIcon] = useState(true)
    const [filterHeaders, setFilterHeaders] = useState<string[]>([])
    const { trackClickEvent } = useAnalytics("TableHeader", false)

    const handleSortClick = (th: any) => {
        trackClickEvent("Sort " + th.text)
        setFilterIcon(!filterIcon);
        setSortData((prevState: { key: string, ascending: boolean }) => ({
            key: th.key,
            ascending: !prevState.ascending,
            date: th.date,
            normalSort: th.normalSort
        }))
    }

    const handleIconClick = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
        e.stopPropagation();
        setShowFilter(idx == showFilter ? -1 : idx)
    }

    const handleFilterSubmit = (prop: HeaderProps, value: any) => {
        trackClickEvent("Filter " + prop.text)
        const { key } = prop
        setFilterData((prevState: any) => ({
            ...prevState,
            [key as any]: value
        }))
        setShowFilter(-1)
        setFilterHeaders([...filterHeaders, key as string])
    }

    const handleClearFilterKey = (key: string) => {
        setFilterData((prevState: any) => {
            const { [key]: omit, ...newFilterData } = prevState
            return newFilterData
        })
        setShowFilter(-1)
        const filteredIcons = filterHeaders.filter((k: string) => k != key)
        setFilterHeaders(filteredIcons)
    }
    useEffect(() => {
        const defaultKey = headers?.filter((h: any) => h.default);
        if (defaultKey?.length) {
            handleSortClick(defaultKey[0])
        }

    }, [])
    return (
        <StyledHeaderRow>
            {headers?.map((th, idx) => (
                //Added this header because it turned out 
                // The updateOn filter was working
                // just not very clear to the user how to use it
                <Tooltip title={th.tooltip} placement="top" arrow>
                    <TableCell
                        key={th.key}
                        style={{ minWidth: th.minWidth, maxWidth: th.maxWidth }}
                    >
                        <Header>
                            <HeaderContainer style={{ cursor: th.key ? "pointer" : "default" }} onClick={() => { handleSortClick(th) }}>
                                {!useServer && th.text && filter && <FilterButton onClick={(e: any) => handleIconClick(e, idx)}>
                                    <FontAwesomeIcon icon={showFilter != idx ? faFilter as IconProp : faFilter as IconProp} color={filterHeaders.includes(th.key) ? Colors.yellow() : Colors.red()}></FontAwesomeIcon>
                                </FilterButton>}
                                <HeaderP>{th && th.text}</HeaderP>
                            </HeaderContainer>
                            <DropDownFilter th={th} onClear={handleClearFilterKey} onSubmit={handleFilterSubmit} collapsed={showFilter != idx} />
                            {showFilter != -1 && <FilterOverlay onClick={() => setShowFilter(-1)} />}
                        </Header >
                    </TableCell>
                </Tooltip>
            ))}
        </StyledHeaderRow>
    )
}

const DropDownFilter = ({ collapsed, th, onSubmit, onClear, }: { collapsed?: boolean, th: any, onSubmit: any, onClear: any, }) => {
    const [value, setValue] = useState("")
    const id = `${th.key}-input`
    const handleChange = (value: string) => {
        setValue(value);
    }
    const handleSubmit = () => {
        onSubmit(th, value)
    }
    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode == 13) {
            handleSubmit()
        }
    }
    const handleClear = () => {
        onClear(th.key)
        setValue("")
    }

    useEffect(() => {
        if (!collapsed) {
            const inputEl = document.getElementById(id)
            inputEl?.focus()
        }
    }, [collapsed])

    return (
        <FilterDropdown collapsed={collapsed} duration={400} height="200px">
            <FilterContainer>
                <div onKeyDown={handleKeydown}>
                    <PBolder>{strings.tableFilter}</PBolder>
                    {
                        th.filterOptions ?
                            <AutoCompleteDynamic
                                options={th.filterOptions}
                                setValue={handleChange}
                                value={value}
                                label=""
                            /> :
                            <Input id={id} value={value} onChange={(e: any) => handleChange(e.target.value as string)} />
                    }
                </div>
                <Buttons>
                    <QuaternaryButton type="reset" onClick={handleClear} fullWidth>{strings.clear}</QuaternaryButton>
                    <PrimaryButton type="submit" fullWidth onClick={handleSubmit}>{strings.submit}</PrimaryButton>
                </Buttons>
            </FilterContainer>
        </FilterDropdown>
    )
}

const FilterOverlay = styled.div({
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    background: "transparent",
    zIndex: 1,
    transform: "translate3d(0, 0, 50px)",
})

const FilterDropdown = styled(Collapsable)<{ collapsed?: boolean }>(({ collapsed }) => ({
    position: "absolute",
    width: "200px",
    top: "25px",
    backgroundColor: Colors.white(),
    ...(collapsed ? {} : Styles.shadow()),
    zIndex: 2,
    transform: "translate3d(0, 0, 100px)",
}))

const Buttons = styled.div({
    "button, a": {
        margin: 0
    }
})

const FilterContainer = styled.div({
    div: {
        div: {

        },
        cursor: "default",
        margin: "8px 0",
        p: {
            textAlign: "left",
            cursor: "default",
            margin: 0,
        },
        input: {
            height: "20px",
            paddingTop: 0,
            marginBottom: "0",
        },
        ".MuiAutocomplete-endAdornment": {
            margin: 0
        }
    },
    padding: "0 8px",
    boxSizing: "border-box",
})

const HeaderContainer = styled.span({
    display: "flex",
    alignItems: "center",
})

export const Header = styled.span({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flexStart",
    minWidth: "115px",
    margin: "0px 0px 4px 0",
    height: "18px",
    padding: 0
})

const HeaderP = styled(PBolder)({
    margin: 0,
    padding: 0
});

const StyledHeaderRow = styled(TableRow)({
    borderBottom: "solid black 2px",
})

export default TableHeader;
