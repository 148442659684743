import { useEffect, useMemo } from "react";
import { ILoggedInUser } from "../models/ILoggedInUser";
import agents, { expireToken } from "../api/agent";
import environment from "../utils/environment";

export const useRedirectToNewWebApp = (loggedInUser: ILoggedInUser | null) => {
    const shouldRedirect = useMemo(() => (loggedInUser?.subscriptionTier || -2) > 0, [loggedInUser?.subscriptionTier]);

    useEffect(() => {
        const redirectToNewWebApp = async () => {
            const response = await agents.Auth.createTokenFromUser();
            if (response.data) {
                expireToken();

                setTimeout(() => {
                    window.location.href = environment.newWebServerUrl + "/sso?uid=" + response.data;
                }, 0)
            }
        };

        if ((loggedInUser?.subscriptionTier || -2) > 0) {
            redirectToNewWebApp();
        }
    }, [loggedInUser?.subscriptionTier]);

    return shouldRedirect;
};
