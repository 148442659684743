import React, { useMemo } from "react"
import { useForm } from "../../../hooks/useForm";
import { ICompanyDetails, ICompanyDetailsForm } from "../../../models/ICompanyDetails";
import { editCompanyValues, editCompanyValidator } from "../../../validators/editCompanyValidator";
import { Form } from "../../../core/form-components/Form";
import Input from "../../../core/form-components/Input";
import { strings } from "../../../content/strings";
import { PrimaryButton } from "../../../components/button";
import { useUpdateCompany } from "../../../hooks/useUpdateCompany";
import AddressAutoComplete from "../../../core/form-components/AddressAutoComplete";

const EditCompanyDetails = ({ company, close }: { company: ICompanyDetails, close: () => void }) => {
    const updateCompany = useUpdateCompany();

    const submitAction = async (formValues: ICompanyDetails) => {
        const success = await updateCompany(formValues)
        if (success) {
            close()
        }
    }

    const initialValues = useMemo(() => editCompanyValues(company), [company])
    const form = useForm<ICompanyDetailsForm>(
        initialValues, submitAction, editCompanyValidator(initialValues)
    );

    return (
        <>
            <Form style={{ alignItems: "stretch" }}>
                <Input {...form.inputProps.companyName} label={strings.companyName} />
                <Input {...form.inputProps.companyURL} label={strings.companyUrl} />
                <Input {...form.inputProps.email} type="email" label={strings.email} />
                <Input {...form.inputProps.phoneNumber} label={strings.phoneNumber} />
                <AddressAutoComplete
                    street={form.inputProps.street!}
                    state={form.inputProps.state!}
                    city={form.inputProps.city!}
                    zip={form.inputProps.zip!}
                    setFormAddress={(address: any) => form.setFormValues(previous => ({
                        ...previous,
                        ...address
                    }))}
                    clearErrors={form.clearErrors}
                />
                <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px", width: "100%" }}>
                    <div style={{ width: "100%", margin: "8px" }} />
                    <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
                </span>
            </Form>
        </>

    )

}

export default EditCompanyDetails
