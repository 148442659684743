import React from "react";
import MarketingSection from "../../pages/landing-page/components/MarketingSection"
import DemoSection from "../../pages/landing-page/components/DemoSection";
import FacilityManagementSection from "../../pages/landing-page/components/FacilityManagementSection"
import DownloadSection from "../../pages/landing-page/components/DownloadAppSection";
import CustomerSection from "../../pages/landing-page/components/CustomerSection";
import { PageTemplate } from "../../core/layout/PageTemplate"
import gears from "../../assets/images/gears.png"
import thumbnail from "../../assets/images/davincilock_thumbnail.png"
import { strings } from "../../content/strings"

const LandingPage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.landingPageTitle}
            pageDescription={strings.landingPageDescription}
            pageCanonical={strings.landingPageCanonical}
            pageThumbnail={thumbnail}
        >
            <div>
                <MarketingSection />
                <DemoSection />
                <FacilityManagementSection />
                <DownloadSection />
                <CustomerSection />
            </div>
        </PageTemplate>
    )
}

export default LandingPage
