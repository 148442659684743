import React from "react";
import thumbnail from "../../assets/images/davincilock_thumbnail.png"
import { PageTemplate } from "../../core/layout/PageTemplate"
import gears from "../../assets/images/gears.png"
import { strings } from "../../content/strings"
import ThankYouContainer from "./components/ThankYouContainer"

const ThankYou = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.thankYouTitle}
            pageDescription={strings.thankYouTitleDescription}
            pageCanonical={strings.thankYouTitleCanonical}
            pageThumbnail={thumbnail}
            center>
            <ThankYouContainer />
        </PageTemplate>
    );
}

export default ThankYou
