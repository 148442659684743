import styled from "styled-components";

export const FormCard = styled.form({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "8px",
    marginTop: "24px"
});

export const FlexInputContainer = styled.span({
    display: "flex",
    gap: "12px",
    "@media (max-width: 570px)": {
        flexDirection: "column",
    },
});
