import React, { useContext } from "react"
import agents from "../../../api/agent";
import { PrimaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import Input from "../../../core/form-components/Input";
import { ToastType } from "../../../enums/ToastType";
import useAnalytics from "../../../hooks/useAnalytics";
import { useForm } from "../../../hooks/useForm"
import { IChangePassword } from "../../../models/IChangePassword"
import { strings } from "../../../content/strings";
import { resetPasswordValidator } from "../../../validators/validators";
import FormDivider from "./FormDivider";
import { FlexInputContainer, FormCard } from "./FormStyles";
import { events as analyticsEvents } from "../../../mixPanelEvents";

const PasswordForm = ({ initialValues }: { initialValues: IChangePassword }) => {
    const { displayToast } = useContext(ToastContext);
    const { trackClickEvent } = useAnalytics("Update Password Form")

    const submitAction = async (formValues: IChangePassword, setFormValues: (arg0: IChangePassword) => any) => {
        trackClickEvent(analyticsEvents.submit);
        const result = await agents.Auth.updatePassword(formValues?.password!, formValues.confirmPassword!);
        if (result.success) {
            setFormValues({ password: "", confirmPassword: "" })
            displayToast({ type: ToastType.Success })
        } else {
            displayToast({ type: ToastType.Error, text: strings.somethingWentWrong })
        }
    }

    const form = useForm<IChangePassword>(initialValues, submitAction, resetPasswordValidator)

    return (
        <FormCard>
            <FlexInputContainer>
                <Input {...form.inputProps.password} label={strings.newPassword} type="password" autocomplete="new-password" />
                <Input {...form.inputProps.confirmPassword} label={strings.confirmPassword} type="password" autocomplete="new-password" />
            </FlexInputContainer>
            <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.changePassword}</PrimaryButton>
            <FormDivider />
        </FormCard>
    )
}

export default PasswordForm;
