import { strings } from "../content/strings";
import { LockModel } from "../models/lockModel"
import LockStatus, { lockStatusOptions } from "../enums/LockStatus"
import Role from "../enums/Role";
import React, { useEffect, useMemo, useState } from "react";
import { useLoggedInUser, useFacilities } from "./hooks";

export const useLocksTable = () => {
    const { loggedInUser } = useLoggedInUser();
    const [facilityLookup, setFacilityLookup] = useState<{ [key: number]: string }>({});
    const [facilitiesFilterOptions, steFacilitiesFilterOptions] = useState<any>([])
    const { facilities } = useFacilities();
 
    useEffect(() => {
        if (facilities.length) {
            setFacilityLookup(
                facilities.reduce((acc: { [key: number]: string }, cv: any) => ({
                    ...acc,
                    [cv.facilityID!]: cv.facilityName
                }), {}) as ({ [key: number]: string })
            )
            // loop through the facilities, and just make a pair of name, value 
            // the name of the facility, value is the id of the facility
            steFacilitiesFilterOptions(facilities.map(facility => ({
                label: facility.facilityName,
                value: facility.facilityID
            })))
        }
    }, [facilities])

    const headers: {
        text: string,
        key: string,
        date?: boolean,
        content?: any,
        normalSort?: boolean,
        tooltip?:string
        filterOptions?: string[] | { label: string, value: any }[]
    }[] = useMemo(() =>
        [
            { text: strings.serialCode, key: "serialCode", normalSort: true },
            {
                text: strings.unitNumber,
                key: "unitNumber",
                content: (tr: LockModel): any =>
                    LockStatus.isNotAssigned(tr.secondaryLockStatus as LockStatus)
                        ? "" : tr.unitNumber
            },
            {
                text: strings.unlockCode,
                key: "unlockCode",
                content: (tr: LockModel): any => tr.secondaryLockStatus == 1006 ?
                    <span >
                        ****
                    </span>
                    : tr.unlockCode
            },
            ...(Role.isVendor(loggedInUser?.roleID) ? [] : [
                {
                    text: strings.status,
                    key: "secondaryLockStatus",
                    filterOptions: lockStatusOptions,
                    content: (tr: LockModel): any => tr.secondaryLockStatus ? LockStatus[tr.secondaryLockStatus as number] : ""
                },
                { text: strings.updatedBy, key: "updatedBy"},
                { text: strings.updatedOn, key: "updatedOn", date: true, tooltip:"Use Format YYYY-MM-DD To Filter." },
            ]),
            {
                text: strings.facilityName,
                //changed this to facilityID from facilityName because we don't have the name on the locks!
                key: "facilityID",
                content: (tr: LockModel): any => facilityLookup[tr.facilityID!],
                filterOptions:facilitiesFilterOptions!
            },
        ], [loggedInUser?.roleID, facilityLookup]
    )

    const mobileHeaders = [
        { key: "serialCode", text: strings.serialCode },
        ...(loggedInUser?.roleID === Role.Vendor ? [] :
            [{ key: "", text: strings.status, content: (tr: LockModel): any => tr.secondaryLockStatus ? LockStatus[tr.secondaryLockStatus as number] : "" }]
        ),
        { key: "unlockCode", text: strings.unlockCode, endOfRow: { key: "unitNumber" } },
    ]

    return {
        headers,
        mobileHeaders,
        rowsPerPage: { initial: 10, options: [5, 10, 20, 50, 100] },
        mobileIdentifier: "unitNumber",
        mobileSortKey: "unitNumber",
    }
}
