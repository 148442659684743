import { Colors } from "./themes";
import styled from "styled-components"

const bold = {
    fontWeight: 500,
}
const bolder = {
    fontWeight: 600,
}
const medium = {
    fontWeight: 300,
}
const thin = {
    fontWeight: 200,
}
const thinner = {
    fontWeight: 100,
}

const DefaultText = styled.span<{ secondary?: boolean, tertiary?: boolean, left?: boolean, right?: boolean }>(props => ({
    lineHeight: "1.2",
    marginBlockEnd: "0.5rem",
    marginBlockStart: "0px",
    marginBottom: "0.5rem",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
    marginTop: "0px",
    textSizeAdjust: "100%",
    textAlign: "center",
    ...(props.left ? { textAlign: "left" } : {}),
    ...(props.right ? { textAlign: "right" } : {}),
    fontWeight: 400,
    color: props.secondary && Colors.white() ||
        props.tertiary && Colors.red() ||
        Colors.black(),
}));

export const Display = styled(DefaultText.withComponent('h1'))({
    fontSize: '2.5rem',
    ...bold,
})

export const H1 = styled(DefaultText.withComponent('h1'))({
    fontSize: '2rem',
    ...bold,
})

export const H2 = styled(DefaultText.withComponent('h2'))({
    fontSize: '1.75rem',
})

export const H2Bold = styled(DefaultText.withComponent('h2'))({
    fontSize: '1.75rem',
    ...bold,
})

export const H3 = styled(DefaultText.withComponent('h3'))({
    fontSize: '1.75rem',
})

export const H3Meduim = styled(DefaultText.withComponent('h3'))({
    fontSize: '1.6rem',
    ...medium,
})

export const H3Bold = styled(DefaultText.withComponent('h3'))({
    fontSize: '1.75rem',
    ...bold,
})

export const H3Thin = styled(DefaultText.withComponent('h3'))({
    fontSize: '1.75rem',
    ...thin
})

export const H4 = styled(DefaultText.withComponent('h4'))({
    fontSize: '1.5rem',
})

export const H4Meduim = styled(DefaultText.withComponent('h4'))({
    fontSize: '1.5rem',
    ...medium
})

export const H4Bold = styled(DefaultText.withComponent('h4'))({
    fontSize: '1.5rem',
    ...bold
})

export const H4Thinner = styled(DefaultText.withComponent('h4'))({
    fontSize: '1.5rem',
    ...thinner
})

export const P = styled(DefaultText.withComponent('p'))({
    fontSize: '0.6rem',
})

export const PBold = styled(P)({
    fontSize: '1.1rem',
    ...bold
})

export const PBolder = styled(P)({
    fontSize: '0.85rem',
    ...bolder
})
export const PBoldest = styled(P)({
    fontSize: '0.875rem',
    ...bolder
})

export const PMedium = styled(P)({
    fontSize: '1.1rem',
    ...medium
})

export const PThin = styled(P)({
    fontSize: '1.1rem',
    ...thin
})

export const Copyright = styled(P)({
    fontSize: '0.75rem',
    ...medium
})

export const FormErrorText = styled(Copyright)({
    fontSize: '0.9rem',
    color: Colors.red(),
    ...bold
})

export const DisclaimerText = styled(P)({
    fontSize: '0.6rem',
    lineHeight: "0.8rem",
    textAlign: "left",
    ...medium
})

export const NavParagraph = styled(P)({
    margin: "0 50px 0 0",
    color: Colors.white(),
    fontSize: "0.80em",
    ...bolder,
    "@media (max-width: 997px)": {
        margin: "0 0 0 -8px"
    }
})
