import React, { useCallback, useEffect } from "react";
import { strings } from "../../content/strings";
import AutoCompleteDynamic from "./AutoCompleteDynamic";
import { useUsers } from "../../hooks/useUsers";
import { ICompanyUser } from "../../models/ICompanyUser";
import { SelectAutoCompleteProps } from "./SelectFacility";

export const SelectUser = ({ setValue, handleClear, geolocate, ...props }: SelectAutoCompleteProps): JSX.Element => {
    const { users } = useUsers();

    useEffect(() => {
        if (users.length === 1) {
            setValue(users[0]);
        } else {
            setValue({});
        }
    }, [users]);

    const handleSetValue = useCallback((val: ICompanyUser) => {
        setValue(val);
    }, [setValue]);

    return (
        <AutoCompleteDynamic
            {...props}
            value={props.value || 0}
            propName="email"
            options={users}
            label={strings.selectUserLabel}
            setValue={handleSetValue}
            targetProp="userID"
            analyticsTag="User Select" />
    );
};
