import React from "react"
import HubProvider from "./HubContext"
import ToastsProvider from "./ToastProvider"
import { PopupProvider } from "./PopupProvider"
import LocationProvider from "./LocationContext"
import SubscriptionProvider from "./SubscriptionProvider"
import { theme } from "../core/brand/themes";
import { ThemeProvider } from "@mui/material";

const Providers: React.FC = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <ToastsProvider>
                <HubProvider>
                    <PopupProvider>
                        <LocationProvider>
                            <SubscriptionProvider>
                                {children}
                            </SubscriptionProvider>
                        </LocationProvider>
                    </PopupProvider>
                </HubProvider>
            </ToastsProvider>
        </ThemeProvider>
    )
}

export default Providers;
