import { fetchLoggedInUserAsync, fetchUpdatedLoggedInUserAsync } from "../redux/auth/authSlice";
import { useAppDispatch } from "../hooks/useAppSelector";
import { ILoggedInUser } from "../models/ILoggedInUser";

export const useFetchLoggedInUser = () => {
    const dispatch = useAppDispatch()

    return {
        fetchLoggedInUser: async () => dispatch(fetchLoggedInUserAsync()),
        fetchUpdatedLoggedInUser: async (previousState: ILoggedInUser & { forceUpdate?: boolean }) => dispatch(fetchUpdatedLoggedInUserAsync(previousState)),
    }
}
