import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Clear from '@mui/icons-material/Clear';

const Input = ({
    onBlur,
    value,
    name,
    type,
    label,
    autoCompleteBackground,
    error,
    required,
    disabled,
    children, // remove children from props
    clear,
    endAdornment,
    ...props
}: any) => {

    return (
        <StyledTextField
            fullWidth
            error={!!error}
            helperText={error || " "}
            variant="standard"
            label={label}
            required={required}
            onBlur={onBlur}
            value={value}
            name={name}
            type={type}
            disabled={disabled}
            {...props}
            InputProps={{
                endAdornment:
                    <>
                        {endAdornment &&
                            <InputAdornment position="start" style={{ cursor: "pointer" }}> {endAdornment} </InputAdornment>}
                        {clear && value && <Clear onClick={clear} id="clearIcon" />}
                    </>
            }}
        />
    );
};

const StyledTextField = styled(TextField)({
    "#clearIcon": {
        display: "none",
        fontSize: "16px",
        padding: "4px",
        cursor: "pointer"
    },
    "&:hover": {
        "#clearIcon": {
            display: "inline-block"
        }
    }
})

export const PhoneInput = React.forwardRef(({ value, setValue, ...props }: any, ref) => {
    return <Input
        {...props}
        type="tel"
        value={function () {
            if (value.length > 6) {
                return "(" + value.slice(0, 3) + ")" + " "
                    + value.slice(3, 6) + "-" + value.slice(6)
            }
            if (value.length > 3) {
                return "(" + value.slice(0, 3) + ")" + " " + value.slice(3)
            }
            return value
        }()}
        onChange={(e: any) => {
            const val = e.target.value.replace(/[^0-9]/g, "")
            if (val.length > 10) return;

            setValue(val)
        }}
    />
})

export const NumberInput = React.forwardRef(({ setValue, ...props }: any, ref) => {
    return <Input
        {...props}
        type="tel"
        onChange={(e: any) => {
            const val = e.target.value.replace(/[^0-9]/g, "")
            setValue(val)
        }}
    />
})

export const InputNoAutoFill = (props: any) => {
    return (
        <>
            <AutofillOff {...props} />
            <Input  {...props} />
        </>
    )
}

const AutofillOff = ({ label, type }: { label: string, type: string }) =>
    <Input label={label} type={type} style={{ opacity: 0, position: "fixed", zIndex: -1, width: "1px", height: "1px" }} />

export default Input;
