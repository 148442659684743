import React from "react";
import gears from "../../assets/images/gears.png";
import { strings } from "../../content/strings";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate";
import CompanyListingContainer from "./CompanyListingContainer";

const CompanyListingPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.companies}
            pageDescription=""
            pageCanonical="www.davincilock.com/Company">
            <CompanyListingContainer />
        </LoggedInPageTemplate >
    );
};

export default CompanyListingPage;
