import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import styled from "styled-components";
import NavMenu from "../../core/navigation-menu/NavMenu";
import Header from "../../core/layout/Header";
import { Colors, Styles } from "../brand/themes";
import { FlipBack, PageLayoutTag } from "./PageLayoutTag";
import Role from "../../enums/Role";
import moment from "moment";
import { H4Bold, PBold } from "../brand/typography";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import AnalyticsProvider from "../../contexts/AnalyticsProvider";
import ExceptionPage from "../error-fallbacks/ErrorPage"
import { ErrorBoundary } from "../../utils/errorHandling";
import { SubscriptionContext } from "../../contexts/SubscriptionProvider";

type LoggedInPageTemplateProps = {
  backgroundImage?: any,
  pageTitle: string
  pageThumbnail?: any,
  pageDescription?: string,
  lang?: string,
  pageCanonical?: string,
  footer?: boolean,
  centerChildren?: boolean,
  extraPadding?: boolean,
}

export const LoggedInPageTemplate: React.FC<LoggedInPageTemplateProps> = ({
  lang = 'en',
  children,
  backgroundImage,
  pageTitle,
  pageThumbnail,
  pageDescription,
  pageCanonical,
  footer,
  centerChildren,
  extraPadding,
}) => {
  const meta = [
    {
      name: 'description',
      content: pageDescription,
    },
    {
      name: 'twitter:title',
      content: pageTitle,
    },
    {
      name: 'twitter:description',
      content: pageDescription,
    },
    {
      name: 'twitter:image',
      content: pageThumbnail,
    },
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:description',
      content: pageDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: pageThumbnail,
    },
  ];

  return (
    <AnalyticsProvider pageName={pageTitle}>
      <PageLayoutTag flip backgroundImage={backgroundImage} footer={footer}>
        <Helmet
          htmlAttributes={{
            lang
          }}
          link={[
            {
              rel: 'canonical',
              href: pageCanonical,
            },
          ]}
          meta={meta}
          title={pageTitle}
          titleTemplate={'%s'}
        />
        <FlipBack>
          <Header ></Header>
          <Body centerChildren={centerChildren} extraPadding={extraPadding} >
            {children}
          </Body>
          <Footer />
        </FlipBack>
      </PageLayoutTag>
    </AnalyticsProvider>
  )
}

const Body: React.FC<{ centerChildren?: boolean, extraPadding?: boolean }> = ({ centerChildren, extraPadding, children }) => {
  const { loggedInUser, loading } = useLoggedInUser();
  const { renderDialog } = useContext(SubscriptionContext)

  if (loading || !loggedInUser?.roleID) return null
  const noNav = loggedInUser?.roleID === Role["Call Center Agent"] || loggedInUser.roleID === Role.Vendor;

  const renderNavMenu = () => {
    if (noNav) return null

    return (
      <div style={{ width: "275px" }}>
        <NavMenuWrapper>
          <NavMenu />
        </NavMenuWrapper >
      </div>
    )
  }

  return (
    <Container extraPadding={extraPadding} centerChildren={centerChildren}>
      <PageGrid>
        {renderNavMenu()}
        <div style={noNav ? { gridColumn: "1/-1", margin: "auto", width: "100%" } : {}}>
          <Banner
            endDate={moment("11/08/2022")}
          />
          <ErrorBoundary
            fallback={<ExceptionPage />}>
            {renderDialog && renderDialog()}
            {children}
          </ErrorBoundary>
        </div>
      </PageGrid>
    </Container>
  )
}

const Banner = ({
  endDate
}: { endDate: moment.Moment }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(
      !JSON.parse(localStorage.getItem("bannerClosed") || "false")
    )
  }, [])

  const onClose = useCallback(() => {
    localStorage.setItem("bannerClosed", "true")
    setOpen(false)
  }, [])

  if (!open)
    return null

  if (Number(moment().format("YYYYMMDD")) >= Number(endDate.format("YYYYMMDD")))
    return null

  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "auto 52px",
      marginTop: "4px",
      width: "100%",
      backgroundColor: Colors.grey()
    }}>
      <PBold secondary style={{ padding: "16px" }}>
        As of October 31st, all SiteLink integration capabilities have been restored. If you have any additional issues or questions, please reach out to support@davincilock.com.
      </PBold>
      <H4Bold secondary onClick={onClose} style={{ paddingTop: "8px", cursor: "pointer" }}>x</H4Bold>
    </div>
  )
}

const PageGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "275px 1fr",
  alignItems: "start",
  width: "100%",
  margin: "auto",
  "@media(max-width:865px)": {
    display: "block",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  }
})

const NavMenuWrapper = styled.div({
  "::-webkit-scrollbar": {
    height: "0px",
    width: "0px",
  },
  overflowY: "scroll",
  backgroundColor: Colors.white(),
  width: "275px",
  position: "absolute",
  top: "58px",
  left: "0px",
  maxHeight: "calc(100vh - 58px)",
  height: "calc(100vh - 92px)",
  ...Styles.shadow(),
  "@media(max-width:865px)": {
    display: "none",
    boxShadow: "none",
  },
})

const Container = styled.div<{ centerChildren?: boolean, extraPadding?: boolean }>(({ centerChildren, extraPadding }) => ({
  padding: `${extraPadding ? "125px" : "56px"} 16px 70px 16px`,
  width: "calc(100% - 32px)",
  margin: "auto",
  display: "flex",
  ...(centerChildren ? {
    marginLeft: "-100px",
    "@media(max-width:865px)": {
      marginLeft: "0"
    }
  } : {}),
  '@media(max-width: 865px)': {
    padding: `${extraPadding ? "125px" : "72px"} 16px 70px 16px`,
  }
}))
