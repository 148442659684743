import React from "react";
import Table from "../../../core/table/Table";
import TableWrapper from "../../../core/table/TableWrapper";
import { strings } from "../../../content/strings";

const headers = [
    { text: strings.createdOn, key: "createdOn" },
    { text: strings.description, key: "logDescription" }
]

export const LogsTable = ({ logs, hideMobileCard = false }: {
    logs: any[],
    hideMobileCard?: boolean
}) => {
    return (
        <TableWrapper>
            <Table
                styles={{ minHeight: "100px" }}
                headers={headers}
                rowsPerPage={{ initial: 9 }}
                data={logs}
                mobileHeaders={headers.filter((h: any) => h.key == "logDescription")}
                mobileIdentifier="logType"
                hideMobileCards={hideMobileCard}
            />
        </TableWrapper>
    );
};
