import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";
import React, { useLayoutEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useNavigationType, useLocation } from "react-router";
import { ILoggedInUser } from '../models/ILoggedInUser';
import { debugLog } from './debugLog';
import environment from "./environment"

let initialized = false;
export const initializeErrorHandling = () => {
    Sentry.init({
        dsn: environment.sentryDNS,
        integrations: [new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        })],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    initialized = true;
}

export const isInitialized = () => initialized

export const captureException = Sentry.captureException;

export const ErrorBoundary = ({ children, ...props }: { children: any, user?: ILoggedInUser, [key: string]: any }) => {
    return <Sentry.ErrorBoundary
        onError={(err: any) => debugLog(err)}
        showDialog
        {...props}
    > {children}</Sentry.ErrorBoundary >
}

export const createReduxEnhancer = Sentry.createReduxEnhancer

export const setUser = Sentry.setUser
