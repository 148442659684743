import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import SerialCodesTableContainer from "./components/SerialCodesTableContainer";
import { strings } from "../../content/strings";

const SerialCodesPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.serialCodesPageTitle}
            pageDescription=""
            pageCanonical="www.davincilock.com/SerialCodes_Locks/SerialCodeListingByCompany">
            <SerialCodesTableContainer />
        </LoggedInPageTemplate >
    );
};

export default SerialCodesPage;
