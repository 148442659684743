import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { Colors } from '../../../core/brand/themes';
import { PBold } from '../../../core/brand/typography';
import { PrimaryButton, QuaternaryButton } from '../../../components/button';
import MessagePreview from "./MessagePreview"
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import { strings } from '../../../content/strings';
import { useNavigate } from 'react-router';
import { MessageType } from "../../../enums/MessageType"
import { MessagesEnum, MessagesEnumKeys } from '../../../enums/MessagePreference';


const CreateCustomMessage = ({ message, emailProps, smsProps, smsForm, emailForm, inUseProps, activeTab, setActiveTab }:
    { message: MessagesEnumKeys, emailProps: any, smsProps: any, smsForm: any, emailForm: any, inUseProps: any, activeTab: number, setActiveTab: any }) => {

    const navigate = useNavigate();
    const textToRelease = MessagesEnum[message] == 3 || MessagesEnum[message] == 4

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    const renderedProps = MessageType[activeTab] == MessageType[0] ? smsProps : emailProps
    const renderedForm = MessageType[activeTab] == MessageType[0] ? smsForm : emailForm

    return (
        <Grid container xs={12} gap="12px">
            <Grid item xs={12}>
                <CardHeader>
                    <PBold secondary>{message}</PBold>
                </CardHeader>
            </Grid>
            {
                textToRelease ?
                    <Grid container xs={12}>
                        <MessagePreview
                            type={MessageType[0]}
                            {...smsProps && { ...smsProps }}
                            checkEmail={inUseProps.emailInUse}
                            setCheckEmail={inUseProps.setEmailInUse}
                            checkSms={inUseProps.smsInUse}
                            setCheckSms={inUseProps.setSmsInUse}
                        />
                    </Grid> :
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={activeTab} onChange={handleTabChange}>
                                <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={MessageType[0]} />
                                <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={MessageType[1]} />
                            </Tabs>
                        </Box>
                    </Grid>}
            {
                textToRelease ? null :
                    <Grid container xs={12}>
                        <MessagePreview type={MessageType[activeTab]} {...renderedProps && { ...renderedProps }}
                            checkEmail={inUseProps.emailInUse}
                            setCheckEmail={inUseProps.setEmailInUse}
                            checkSms={inUseProps.smsInUse}
                            setCheckSms={inUseProps.setSmsInUse}
                        />
                    </Grid>
            }
            <Grid container md={7} sm={12} gap="4px" justifyContent={{ xs: "start", sm: "space-evenly" }} sx={{ mt: "42px", mb: "24px", p: { xs: "0 14px", md: "0" } }}>
                <Grid item sm={4} xs={12}>
                    <QuaternaryButton fullWidth onClick={() => navigate(-1)} > <UndoIcon /> {strings.back.toUpperCase()}</QuaternaryButton>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <PrimaryButton fullWidth onClick={renderedForm?.handleSubmit}> <CheckIcon /> {strings.saveChanges}</PrimaryButton>
                </Grid>

            </Grid>
        </Grid>
    );
}

const CardHeader = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
})

export default CreateCustomMessage;
