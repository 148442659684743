import React from 'react';
import { Box } from '@mui/material';
import { Card } from '../../../core/surfaces/Card';
import RolesCount from '../../roles-and-permissions/components/RolesCount';
import AddEditSettings from "./AddEditSettings"

const RoleSettingsContainer = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", justifyContent: "space-around" }}>
            <Card style={{ flexBasis: "15.5%", display: "flex", padding: "8px 4px", boxSizing: "border-box", marginTop: "42px", minHeight: "162px" }}>
                <RolesCount />
            </Card>
            <Card style={{ flexBasis: "65%", }} >
                <AddEditSettings />
            </Card>
        </Box>
    );
}

export default RoleSettingsContainer;
