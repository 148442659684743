import { useRef, useMemo } from 'react';
import StringTemplate from "../utils/stringTemplateMethods"
import { Direction } from "../enums/Direction";
import { IFieldProps } from './useForm';

const directionMap = {
    "ArrowRight": Direction.Right,
    "ArrowLeft": Direction.Left
}

type DirectionKey = "ArrowRight" | "ArrowLeft";

export const useTextTemplate = (templateValues: string[] | any, fieldProps: IFieldProps<{ [key: string]: string }> & { value: string }) => {
    const initialValue = useMemo(() => fieldProps.value, [])
    const value = fieldProps.value as string
    const inputRef = useRef<any>(null);
    const directionRef = useRef<any>(null);
    const cursorPositionRef = useRef<any>(null);

    const historyRef = useRef<string[]>([])

    const updateTemplate = (newVal: string) => {
        fieldProps.setValue((previous: string) => {
            historyRef.current.push(previous)
            return newVal as string
        })
    }

    const undoChange = () => {
        // This is nice!
        const val = historyRef.current.pop();
        fieldProps.setValue(val || initialValue)
    }

    const preview = useMemo(() => StringTemplate.generatePreview(value, templateValues), [value, templateValues]);

    const onSelect = (e: any) => {
        const { selectionStart, selectionEnd, value: input, selectionDirection } = e.target;
        cursorPositionRef.current = [selectionStart, selectionEnd];
        const cursor = selectionDirection === "forward" ? selectionStart : selectionEnd
        const [start, end] = StringTemplate.getWordIndexRange(input, cursor);

        if (start !== -1 && end !== -1) {
            cursorPositionRef.current = [start, end];
            inputRef.current?.setSelectionRange(start, end);
        }
    }

    const onClick = (e: any) => {
        directionRef.current = null;
    }

    const onKeyDown = (event: any) => {
        const { key, shiftKey, target, ctrlKey } = event

        if (ctrlKey && key === "z") {
            undoChange()
            return;
        }


        const direction: Direction | undefined = directionMap[key as DirectionKey]

        if (!direction) {
            return
        }

        const { selectionStart, selectionEnd, value: input, } = target;

        if (selectionStart === selectionEnd && shiftKey) {
            directionRef.current = directionMap[key as DirectionKey] // indicate direction from starting out
        }

        const options = {
            shiftKey, direction, directionFromOrigin: directionRef.current, selectionEnd, selectionStart, input
        }
        const selectionRange = StringTemplate.getSelectionRangeFromArrowPress(options)

        if (selectionRange.length) {
            event.preventDefault()
            inputRef.current?.setSelectionRange(...selectionRange);
        }
    }

    const onChange = ({ target, nativeEvent: { inputType } }: any) => {
        const input = target.value
        const cursor = target.selectionStart
        // debugger
        if (inputType === "historyUndo") {
            return;
        }

        if (inputType === "deleteContentBackward" || inputType === "deleteContentForward") {
            const offset = inputType === "deleteContentForward" ? 1 : 0
            const [start, end] = StringTemplate.getWordIndexRange(value, cursor + offset)

            if (start !== -1 && end !== -1) {
                const newVal = value.slice(0, start) + value.slice(end)
                setTimeout(() => {
                    inputRef.current?.setSelectionRange(start, start);
                }, 0)

                updateTemplate(newVal)
                return;
            }
        }

        updateTemplate(input)
    }

    const insertTemplateValue = (templateValue: string) => {

        const newTemplate = StringTemplate.insertValue(cursorPositionRef.current, value, templateValue)
        updateTemplate(newTemplate);

        const [start] = cursorPositionRef.current
        const [, endIndex] = StringTemplate.getWordIndexRange(newTemplate, start + 2)
        inputRef.current.focus();
        setTimeout(() => {
            inputRef.current?.setSelectionRange(endIndex, endIndex);

            setTimeout(() => {
                cursorPositionRef.current = [endIndex, endIndex]
            }, 500)
        }, 0)

    }

    const onBlur = (e: any) => {
        fieldProps.onBlur(e)
        setTimeout(() => {
            cursorPositionRef.current = null
        }, 500)
    }

    const generateTextForServer = (template: string) => {
        return StringTemplate.replaceMessageValues(template)
    }

    const count = StringTemplate.templateCount(value)

    return ({
        value,
        inputRef,
        onSelect,
        onKeyDown,
        onClick,
        onChange,
        onBlur,
        insertTemplateValue,
        preview,
        name: fieldProps.name,
        error: fieldProps.error,
        validate: fieldProps.validate,
        setError: fieldProps.setError,
        updateTemplate,
        count,
        generateTextForServer
    })
}
