import React, { useState, useEffect } from "react";
import agents from "../../api/agent";
import AutoComplete from "../../core/form-components/OldAutoComplete"
import gears from "../../assets/images/gears.png";
import { FacilityDto } from "../../models/facility";
import { LoggedInForm } from "../../core/layout/LoggedInForm";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { PBold } from "../../core/brand/typography";
import { PrimaryButton, QuaternaryButton } from "../../components/button";
import { strings } from "../../content/strings";
import { ICompanyUser, ITransferForm } from "../../models/models";
import { ILoggedInUser } from "../../models/ILoggedInUser"
import { useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/useAnalytics";
import { useUsers } from "../../hooks/useUsers";

const PromptRecipientToSelectClientAdmin = ({ token, facility }: { token: string, facility: FacilityDto }) => {
    const [transferFaciltyValues, setTransferFacilityValues] = useState<ITransferForm>({ email: "", facilityID: facility?.facilityID });
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { trackClickEvent } = useAnalytics("Select Client Admin")
    const navigate = useNavigate()
    const { adminsOwnerList } = useUsers();


    const submitAction = async () => {
        trackClickEvent("Send Facility Transfer")
        const matches = adminsOwnerList.some((admin: ICompanyUser) => admin.email === transferFaciltyValues.email);
        if (matches) {
            setErrorMessage("");
            const result = await agents.Facilities.initializeFacilityTransfer(transferFaciltyValues);
            if (result.success) {
                setSuccess(true);
            } else {
                setErrorMessage(result?.error || strings.somethingWentWrong);
            }
        } else {
            setErrorMessage(strings.mustSelectAdmin);
        }
        return true;
    }

    const setFormValues = (clientAdmin: ILoggedInUser) => {
        setTransferFacilityValues({
            facilityID: facility?.facilityID || 0,
            email: clientAdmin?.email || ""
        })
    }

    const renderContent = () => {
        if (success) {
            return (
                <LoggedInForm.Form>
                    <LoggedInForm.FullSpan center>
                        <PBold>{strings.transferFacilitySuccess}</PBold>
                        <br />
                        <PrimaryButton onClick={() => navigate("/Users/Dashboard")}>{strings.backToDash}</PrimaryButton>
                    </LoggedInForm.FullSpan>
                </LoggedInForm.Form>
            )
        }
        return (
            <LoggedInForm.Form>
                <LoggedInForm.Title>
                    <PBold left> {strings.transferApproval}</PBold>
                </LoggedInForm.Title>
                <AutoComplete errorMessage={errorMessage} values={adminsOwnerList} setValue={setFormValues} propName="name" />
                <br />
                <QuaternaryButton onClick={() => navigate("/Users/Dashboard")}>{strings.decline}</QuaternaryButton>
                <PrimaryButton onClick={submitAction}>{strings.sendEmail}</PrimaryButton>
            </LoggedInForm.Form>
        )
    }

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.transferFacility}
            pageDescription={strings.davincilockPageDescription}
        >
            {renderContent()}
        </LoggedInPageTemplate>
    );
}

export default PromptRecipientToSelectClientAdmin
