import { useEffect } from "react";
import { Colors } from "../../../core/brand/themes";

const HubspotForm = () => {

    useEffect(() => {
        const script = document.createElement('script')
        script.src = "//js.hsforms.net/forms/embed/v2.js";

        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '44206742',
                    formId: '2c051559-8026-45ff-9f69-de9ce45ed41f',
                    region: 'na1',
                    target: "#na1",
                    onFormReady: function (form: any) {
                        const button = form.querySelector(".hs-button");
                        button.style.backgroundColor = Colors.red()
                        button.style.height = "36px";
                        button.style.fontSize = ".85rem";
                        button.style.padding = "1px 16px";
                    }
                });
            }
        });
    }, [])

    return <div id="na1"></div>;
}
 
export default HubspotForm
