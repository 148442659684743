import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import { _FMS } from '../../facility-listing/components/FMSForm';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import { PrimaryButton, SecondaryButton } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone'
import agents from '../../../api/agent';
import { Box } from '@mui/material';
import { H4Bold } from '../../../core/brand/typography';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { HelperDialog } from "./HelperDialog"
import * as excel from "xlsx"
import {
    doorSwapCloudExamples,
    doorSwapCloudExplained,
    easyStorageExamples,
    easyStorageExplained,
    siteLinkExamples,
    siteLinkExplained,
    siteLinkRequirements,
    siteLinkValuesAndInstructionHeaders,
    storEDGEExplained,
    storEdgeRequirements,
    storEDGEvaluesAndInstructionHeaders,
    storEDGExamples,
    hummingbirdExplained,
    hummingbirdRequirements,
    hummingbirdExamples,
    valuesAndInstructionHeaders
} from "./sheetHeadersAndValues"
import { HubContext } from '../../../contexts/HubContext';
import { useLoggedInUser } from '../../../hooks/hooks';

const FacilityCSVForm = ({ managementSoftware, openDialog, setOpenDialog }: { openDialog: boolean, setOpenDialog: Dispatch<SetStateAction<boolean>>, managementSoftware: ManagementSoftware }) => {
    const [file, setFile] = useState<File | null>(null);
    const { displayToast } = useContext(ToastContext);
    const { invalidFacilities, setInvalidFacilities } = useContext(HubContext);
    const { loggedInUser } = useLoggedInUser();

    const {
        open
    } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
        onDrop: acceptedFiles => setFile(acceptedFiles[0])
    });

    const submit = async () => {
        if (file) {
            const formData = new FormData();;
            formData.append("file", file)

            try {
                const result = await agents.Facilities.importFacilitiesCSV(managementSoftware, formData);
                if (result.success === true) {
                    displayToast({ type: ToastType.Success, text: result.data })
                    setFile(null);
                } else if (!result.success) {
                    displayToast({ type: ToastType.Error, text: result.error || "" })
                    setFile(null);
                }
            } catch (e: any) {
                displayToast({ type: ToastType.Error, text: e.message || "" })
            }

        }
    }

    useEffect(() => {
        if (invalidFacilities.invalidAddresses && loggedInUser?.userID == invalidFacilities.userID) {
            displayToast({ type: ToastType.Error, text: strings.unverifiedAddresses(invalidFacilities.invalidAddresses) });
            setInvalidFacilities({ userID: null, invalidAddress: 0 })
        }
    }, [invalidFacilities.invalidAddresses])

    let sheet: any = [];
    switch (managementSoftware) {
        case ManagementSoftware["Not listed"]:
            sheet = [
                [valuesAndInstructionHeaders],
                [valuesAndInstructionHeaders]
            ]
            break;
        case ManagementSoftware["storEDGE"]:
            sheet = [
                [storEDGEvaluesAndInstructionHeaders],
                [storEDGEvaluesAndInstructionHeaders, storEDGEExplained, storEdgeRequirements, [], [], [], storEDGExamples]

            ]
            break;
        case ManagementSoftware["SiteLink"]:
            sheet = [
                [siteLinkValuesAndInstructionHeaders],
                [siteLinkValuesAndInstructionHeaders, siteLinkExplained, siteLinkRequirements, [], [], [], siteLinkExamples]
            ]
            break;
        case ManagementSoftware["Easy Storage Solutions"]:
            sheet = [
                [storEDGEvaluesAndInstructionHeaders],
                [storEDGEvaluesAndInstructionHeaders, easyStorageExplained, storEdgeRequirements, [], [], [], easyStorageExamples]
            ]
            break;
        case ManagementSoftware["DoorSwap"]:
            sheet = [
                [valuesAndInstructionHeaders],
                [valuesAndInstructionHeaders, doorSwapCloudExplained, siteLinkRequirements, [], [], [], doorSwapCloudExamples]
            ]
            break;
        case ManagementSoftware.Hummingbird:
            sheet = [
                [valuesAndInstructionHeaders],
                [valuesAndInstructionHeaders, hummingbirdExplained, hummingbirdRequirements, [], [], [], hummingbirdExamples]
            ]
            break;
        default:
            sheet = [
                [storEDGEvaluesAndInstructionHeaders],
                [storEDGEvaluesAndInstructionHeaders, storEDGEExplained, storEdgeRequirements, [], [], [], storEDGExamples]
            ]
    }

    const createExcelTemplate = () => {
        // create the headers for the two sheets
        const mainSheet = sheet[0]
        const secondarySheet = sheet[1]
        // Create workbook with 2 sheets
        const workbook = excel.utils.book_new();
        const mainSheetName = "Values";
        const secondarySheetName = "Instructions";
        const mainSheetData = excel.utils.aoa_to_sheet(mainSheet);
        const secondarySheetData = excel.utils.aoa_to_sheet(secondarySheet);
        excel.utils.book_append_sheet(workbook, mainSheetData, mainSheetName);
        excel.utils.book_append_sheet(workbook, secondarySheetData, secondarySheetName);
        // Convert workbook to binary format
        const binaryData = excel.write(workbook, { bookType: "xlsx", type: "binary" });
        // Create download link and click it
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/octet-stream;base64," + btoa(binaryData);
        downloadLink.download = `${ManagementSoftware[managementSoftware]}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    }

    return (
        <>
            <HelperDialog open={openDialog} setOpen={setOpenDialog} />
            <DropSection >
                {file ?
                    <Box onClick={open}>
                        <H4Bold>{file.name}</H4Bold>
                    </Box> :
                    <FontAwesomeIcon
                        onClick={open}
                        size="3x"
                        icon={faFileAlt as IconProp}
                        style={{ color: Colors.lightgrey(), cursor: "pointer" }} />
                }
            </DropSection>

            <PrimaryButton disabled={!file} onClick={submit} fullWidth>
                <FontAwesomeIcon size="lg" icon={faDownload as IconProp} style={{ marginRight: "8px" }} />
                {strings.importFacilities}
            </PrimaryButton>
            <SecondaryButton fullWidth onClick={createExcelTemplate} style={{ margin: "8px 0px" }}>
                <FontAwesomeIcon size="lg" icon={faDownload as IconProp} style={{ marginRight: "8px" }} />
                {strings.downloadTemplate}
            </SecondaryButton>
        </>
    )
}

const DropSection = styled.section({
    background: Colors.lightergrey(),
    border: "1px dashed black",
    minHeight: '200px',
    minWidth: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "24px",
    marginBottom: "12px",
})

export default FacilityCSVForm;
