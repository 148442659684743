import React, { useContext, useMemo } from 'react';
import { useFacilities } from './useFacilities';
import { useLoggedInUser } from './useLoggedInUser';
import { LocationContext } from '../contexts/LocationContext';

export const useTemplateValues = () => {

    const { nearestFacility, } = useContext(LocationContext)
    const { facilities } = useFacilities();
    const { loggedInUser } = useLoggedInUser()

    const facility = nearestFacility ? nearestFacility : facilities[0]

    const templateValues = useMemo(() => ({
        facility: facility?.facilityName, // \u200B is a zero width space. I use them to determine which text to make bold. 
        "unlockCodes": `\u200BUnit A21:\u200B
\u200BUnlock 00000000 with 5928\u200B
\u200BUnlock 00000000 with 8792\u200B`,
        company: loggedInUser?.companyName,
        name: `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
    }), [loggedInUser, facility])

    return { templateValues }
}
