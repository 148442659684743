import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ICompanyUser } from "../../models/ICompanyUser";
import agents from '../../api/agent';
import { ActionTypes } from "../auth/ActionTypes";
import { fetchData } from '../fetchData';

interface ICompanyUserState {
    /* * A list of company users.* */
    users: ICompanyUser[],
    error?: string,
    pending?: boolean
}

const initialState: ICompanyUserState = {
    users: [],
}

export const fetchUsersAsync = createAsyncThunk(
    "CompanyUsers/fetchUsersAsync",
    async (_, { rejectWithValue }) =>
        fetchData(rejectWithValue, agents.Users.getCompanyUsers)
)

export const fetchCompanyUsersAsync = createAsyncThunk(
    "CompanyUsers/fetchCompanyUsersAsync",
    async (companyID: number, { rejectWithValue }) =>
        fetchData(rejectWithValue, agents.Users.getCompanyUsers, companyID)
)

export const companyUsersSlice = createSlice({
    name: "CompanyUsers",
    initialState,
    reducers: {
        setUsers: (state: any, action: PayloadAction<any>) => {
            return { ...state, users: [...action.payload] }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(fetchUsersAsync.pending, fetchCompanyUsersAsync.pending),
                (state) => {
                    return { ...state, pending: true }
                })
            .addMatcher(
                isAnyOf(fetchUsersAsync.fulfilled, fetchCompanyUsersAsync.fulfilled),
                (state, action: PayloadAction<any>) => {
                    return { ...state, users: action.payload }
                })
            .addMatcher(
                isAnyOf(fetchUsersAsync.rejected, fetchCompanyUsersAsync.rejected),
                (state, action: PayloadAction<any>) => {
                    return { ...state, error: action.payload }
                })
            .addMatcher(({ type }: { type: string }) => type === ActionTypes.LOGOUT,
                () => ({ users: [] })
            )
            .addDefaultCase((state) => {
                return state;
            })
    }
})

export const { setUsers } = companyUsersSlice.actions;

export const getCompanyUsers = (state: RootState) => state.companyUsers
export const getCompanyUsersError = (state: RootState) => state.companyUsers
