import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import UserLogsContainer from "./components/UserLogsContainer";

const UserLogs = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.logsPage}
            pageDescription=""
            pageCanonical="www.davincilock.com/Users/GetLogs">
            <UserLogsContainer />
        </LoggedInPageTemplate >
    );
};

export default UserLogs;
