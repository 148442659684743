import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContext } from "../contexts/ToastProvider";
import { ToastType } from "../enums/ToastType";
import { useQuery } from "../hooks/useQuery";
import { strings } from "../content/strings";

enum ToastErrorType {
    expiredFacilityToken = 1
}

const ToastErrorTypeStringMap: { [key: string]: string } = {
    [ToastErrorType.expiredFacilityToken]: strings.expiredFacilityTransfer
}

export default function UrlParamErrorMiddleware({ children }: any) {
    const errorType = useQuery<ToastErrorType>("errorType");
    const navigate = useNavigate()
    const location = useLocation();

    const { displayToast } = useContext(ToastContext)

    useEffect(() => {
        if (errorType && (errorType in ToastErrorType)) {
            displayToast({ type: ToastType.Error, text: ToastErrorTypeStringMap[errorType] })
            navigate(location.pathname);
        }
    }, [errorType])

    return children
}
