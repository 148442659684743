import {
    useEffect,
    useRef
} from "react";
import { getToken } from "../api/agent";
import { ILoggedInUser } from "../models/ILoggedInUser";
import { joinCompanyGroupMethodName, leaveCompanyGroupMethodName, signalRService } from "../contexts/HubContext";

export const useClientRoleCompanyConnection = (isReady: boolean, loggedInUser: ILoggedInUser | null) => {
    const previousCompanyID = useRef<number | null>(null);

    useEffect(() => {
        if (!isReady) {
            return;
        }

        const connection = signalRService.getConnection();

        if (!previousCompanyID.current && loggedInUser?.userID) {
            connection?.invoke(
                joinCompanyGroupMethodName,
                getToken(),
                loggedInUser.companyID || 0,
            );
            previousCompanyID.current = loggedInUser.companyID || 0;
        } else if (previousCompanyID.current && !loggedInUser) {
            connection?.invoke(
                leaveCompanyGroupMethodName,
                getToken(),
                previousCompanyID.current,
            );
            previousCompanyID.current = null;
        }
    }, [loggedInUser?.userID, isReady]);
};
