import React, { useContext, useState } from "react";
import Input from "../../../core/form-components/Input";
import styled from "styled-components";
import { apiKeyValidator } from "../../../validators/validators";
import { InputLabel, Select, Switch, Input as MuiInput, MenuItem, Box, Chip } from "@mui/material";
import { IAPIKey } from "../../../models/IAPIKey";
import { PBold, PThin } from "../../../core/brand/typography";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { useForm } from "../../../hooks/hooks";
import { Colors } from "../../../core/brand/themes";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFacilities } from "../../../hooks/useFacilities";
import { FacilityDto } from "../../../models/facility";
import { ApiActions } from "../../../enums/ApiActions";
import agents from "../../../api/agent";

const AddUpdateAPILookup = {
    [ApiActions.ADD]: agents.Company.addApiKey,
    [ApiActions.EDIT]: agents.Company.updateApiKey
}

export default function AddEditAPIKey({ closeBlade, initialValue, editting }: { initialValue: IAPIKey, closeBlade: () => void, editting?: boolean }) {
    const { displayToast } = useContext(ToastContext)
    const { facilities } = useFacilities();
    const [showSecret, setShowSecret] = useState(false);
    const { trackClickEvent } = useAnalytics(editting ? "Edit API Key" : "Add API Key")
    const lookupKey = editting ? ApiActions.EDIT : ApiActions.ADD;

    const submitAction = async (formValues: IAPIKey, setFormValues: (arg0: IAPIKey) => any) => {
        await trackClickEvent(analyticsEvents.submit)
        try {
            //@ts-ignore
            const result = await AddUpdateAPILookup[lookupKey](formValues);
            if (result.success) {
                displayToast({ type: ToastType.Success, text: result.result })
                closeBlade();
            } else {
                displayToast({ type: ToastType.Error, text: result.error })
            }
        } catch (err: any) {
            displayToast({ type: ToastType.Error, text: err.message })
        }
    }

    const form = useForm<IAPIKey>(
        initialValue, submitAction, apiKeyValidator
    );

    const facilityIdProps = form.inputProps.facilityIds;
    const allFacilitiesProps = form.inputProps.allFacilities;
    const allFacilitiesValue = allFacilitiesProps?.value as boolean;
    const secretProps = form.inputProps.secret
    const keyProps = form.inputProps.key

    return (
        <>
            <Input {...form.inputProps.name} label={strings.nameLabel} />
            <ShrinkFontContainer>
                <Input disabled {...keyProps} value={keyProps.value || strings.apiKeyGenerated} label={strings.apiKey} />
            </ShrinkFontContainer>
            {secretProps.value && <ShowSecretContainer>
                <Input disabled type={showSecret ? strings.text : strings.password} {...secretProps} label={strings.apiSecret} />
                <PrimaryButton onClick={() => setShowSecret(true)}>{strings.clickToShow}</PrimaryButton>
            </ShowSecretContainer>}
            <span style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                <InputLabel>{strings.userFacilities}</InputLabel>
                <span style={{ display: "flex", alignItems: "center" }}>
                    <Switch
                        checked={allFacilitiesValue}
                        onChange={() => allFacilitiesProps?.setValue(!allFacilitiesValue)} />
                    <PBold style={{ marginBlockEnd: "0px" }}>{strings.allFacilities}</PBold>
                </span>

            </span>
            {
                !allFacilitiesValue
                && <Select
                    labelId={strings.userFacilities}
                    multiple
                    inputProps={facilityIdProps}
                    value={facilityIdProps.value}
                    onChange={facilityIdProps.onChange}
                    input={
                        <MuiInput sx={{
                            "& .Mui-focused": { color: "black !important" }
                        }} />
                    }
                    native={false}
                    displayEmpty={true}
                    renderValue={(selected: any) => {
                        if (!selected.length) return <PThin style={{ color: Colors.grey(), textAlign: "left" }}>{strings.selectFacilityLabel}</PThin>
                        const userFacilities = selected?.map((id: number) => facilities.find((facility) => facility.facilityID == id)) || []
                        return (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {userFacilities.map((facility: FacilityDto) => (
                                    <Chip key={facility?.facilityID} label={facility?.facilityName} />
                                ))}
                            </Box>
                        )
                    }}
                >
                    {facilities.map((f) => (
                        <MenuItem
                            key={f.facilityID}
                            value={f.facilityID}
                        >
                            {f.facilityName}
                        </MenuItem>
                    ))}
                </Select>
            }

            <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px" }}>
                <QuaternaryButton fullWidth>{strings.cancel}</QuaternaryButton>
                <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
            </span>
        </>
    )
}

const ShrinkFontContainer = styled.div({
    input: { fontSize: "0.95rem" },
})

const ShowSecretContainer = styled.div({
    input: { fontSize: "0.95rem", marginBottom: 0 },
    "a, button": { marginLeft: 0 }
});
