import React from "react";
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import appstore from "../../../assets/images/appstore.svg"
import appstoreqr from "../../../assets/images/appstoreqr.svg"
import googleplay from "../../../assets/images/googleplay.svg"
import googleplayqr from "../../../assets/images/googleplayqr.svg";
import { Colors } from "../../../core/brand/themes"
import { strings } from "../../../content/strings"
import { Section } from "../../../core/layout/Section"
import styled from "styled-components";
import { H3, PMedium } from "../../../core/brand/typography";
import { Box } from "@mui/material";

const DownloadAppSection = () => {
    return (
        <Section backgroundColor={Colors.navy()}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                margin: "20px",
                gap: "24px"
            }} >
                <H3 secondary >{strings.downloadTitle}</H3>
                <PMedium secondary >{strings.downloadParagraph}</PMedium>
            </Box>
            <DownloadableContentBox>
                <QRCodeBox direction="column" margin=" 4px 16px">
                    <a href="https://itunes.apple.com/us/app/davinci-lock-mobile/id1438528927?mt=8" target="_blank">
                        <img src={appstore} style={{ width: "135px", height: "50px", objectFit: "contain", marginBottom: "8px" }} />
                    </a>
                    <img alt="" src={appstoreqr} />
                </QRCodeBox>
                <QRCodeBox direction="column" margin=" 8px 16px">
                    <a href="https://play.google.com/store/apps/details?id=com.davincilock.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                        <img src={googleplay} style={{ width: "135px", height: "50px", objectFit: "contain", marginBottom: "8px" }} />
                    </a>
                    <img alt="" src={googleplayqr} />
                </QRCodeBox>
            </DownloadableContentBox>
        </Section>
    )
}

const DownloadableContentBox = ({ children }: { children: JSX.Element[] }) => {
    const [isMobile] = useMediaQuery(767);
    const direction = isMobile ? "column" : "row"
    const justify = isMobile ? "center" : "space-evenly"
    return (
        <DownloadableContainer justify={justify} direction={direction}>
            {children}
        </DownloadableContainer>
    )
}

const QRCodeBox = ({ children, margin, direction }: { children: JSX.Element[], margin: string, direction: string }) => {
    return (
        <QRContainer margin={margin} direction={direction} >
            {children}
        </QRContainer>

    )
}

const DownloadableContainer = styled.div<{ justify: any, direction: any }>(({ justify, direction }) => ({
    display: "flex",
    flexDirection: direction,
    alignItems: "center",
    justifyContent: justify,
    width: "calc(45% - 80px)",
    padding: "0px 40px"
}))
const QRContainer = styled.div<{ margin: any, direction: any }>(({ margin, direction }) => ({
    display: "flex",
    flexDirection: direction,
    alignItems: "center",
    margin: margin,
}))

export default DownloadAppSection
