import React, { useContext } from 'react';
import { Box, Divider, FormControlLabel, FormGroup, Grid, Switch, Tooltip } from "@mui/material";
import { PBold, PThin } from '../../../core/brand/typography';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import styled from '@emotion/styled';
import { TertiaryButton } from '../../../components/button';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import { SubscriptionContext } from '../../../contexts/SubscriptionProvider';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CountUp from 'react-countup';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const StatsCard = ({ data }: { data: any }) => {
    const [checked, setChecked] = React.useState(true);
    const { isSubscribed } = useLoggedInUser();
    const subscribed = isSubscribed()
    const { handleOpen, renderDialog } = useContext(SubscriptionContext);
    const [isMobile] = useMediaQuery(552)

    const handleClick = () => {
        handleOpen && handleOpen();
        renderDialog && renderDialog();
    }

    if (isMobile) {
        return (
            <Box>
                <Grid container lg={12} xs={12} justifyContent="space-between">
                    {Object.entries(data).map(([type, count]) => {
                        return (
                            <>
                                <Grid item xs={8} sx={{ px: 1 }}>
                                    <PBold left style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>{type}</PBold>
                                </Grid>
                                <Grid item xs={4} sx={{ px: 1 }}>
                                    <PThin left>
                                        <CountUp end={count as number} />
                                    </PThin>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
                <Divider orientation="horizontal" sx={{ background: Colors.red() }} />
                <Box sx={{ pt: 1, px: 1, boxSizing: "border-box" }}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={checked} onChange={() => setChecked(!checked)} disabled={true} />}
                            label={
                                <PBold style={{ marginTop: "8px", marginLeft: "22px" }}>{strings.sendAutoNotifications}</PBold>
                            } />
                    </FormGroup>
                    {!subscribed &&
                        <Tooltip title="Coming Soon!" placement='bottom-end'>
                            <PremiumLockButton fullWidth onClick={handleClick} >
                                <AutoAwesomeIcon />
                                {strings.customMessage.toUpperCase()}</PremiumLockButton>
                        </Tooltip>}
                </Box>
            </Box>
        )
    }

    return (
        <Grid container alignItems="center" justifyContent="space-around" sx={{ width: "100%" }}>

            <Grid item container lg={2.5} xs={5} sx={{ width: "100%" }} gap="20px" alignSelf="center">
                <Grid item sx={{ width: "100%", textAlign: "center" }} xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={checked} onChange={() => setChecked(!checked)} disabled={true} />}
                            label={
                                <PBold style={{ marginTop: "8px", marginLeft: "22px" }}>{strings.sendAutoNotifications}</PBold>
                            } />
                    </FormGroup>
                </Grid>
                {!subscribed && <Grid item sx={{ width: "100%", textAlign: "center" }} xs={12}>
                    <Tooltip title="Coming Soon!" placement='bottom-end'>
                        <PremiumLockButton fullWidth onClick={handleClick} >
                            <AutoAwesomeIcon />
                            {strings.customMessage.toUpperCase()}</PremiumLockButton>
                    </Tooltip>
                </Grid>}
            </Grid>
            <Divider orientation="vertical" sx={{ background: Colors.red(), width: "1px", height: "100%", overflow: "visible" }} />
            <Grid item container lg={8.5} xs={5} alignItems={{ xs: "center", md: "flex-start" }} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
                {data && Object.entries(data).map(([type, count]) => {
                    return (
                        <Grid item container xs={6} md={5} lg={2} sx={{ height: "100%", gap: "20px" }}
                            direction="column"
                            justifyContent="center"
                            alignItems={{ lg: "center", xs: "start" }}>
                            <Grid item sm={12} xs={6}>
                                <PBold style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>{type}</PBold>
                            </Grid>
                            <Grid item sm={12} xs={8}>
                                <PThin>
                                    <CountUp end={count as number} />
                                </PThin>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    );
}

const PremiumLockButton = styled(TertiaryButton)({
    color: `${Colors.yellow()} !important`,
    padding: "4px 8px",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
})

export default StatsCard;
