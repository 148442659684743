import React from "react";
import gears from "../../assets/images/gears.png";
import links from "../../routes/links"
import styled from "styled-components";
import { Card } from "../../core/surfaces/Card";
import { PageTemplate } from "../../core/layout/PageTemplate";
import { PMedium } from "../../core/brand/typography";
import { PrimaryLink } from "../../components/button";
import { strings } from "../../content/strings";

const ExpiredResetLinkPage = (
) => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.davinci}
            pageDescription={strings.davincilockPageDescription}
            center>
            <ContainerCard>
                <PMedium>{strings.expiredLink}</PMedium>
                <PrimaryLink fullWidth {...links.FORGOTPASSWORD}>{strings.resetPassword}</PrimaryLink>
            </ContainerCard>
        </PageTemplate>
    );
};

export default ExpiredResetLinkPage;

const ContainerCard = styled(Card)({
    position: "relative",
    display: "grid",
    rowGap: "20px",
    width: "300px",
    padding: "45px 20px 30px 20px",
})
