import React from "react";
import { strings } from "../../content/strings";
import gears from "../../assets/images/gears.png";
import { PageTemplate } from "../../core/layout/PageTemplate";
import SerialCodesTableContainer from "../serial-codes/components/SerialCodesTableContainer";

const VendorPage = () => {
    return (
        <PageTemplate
            noTopPadding
            backgroundImage={gears}
            pageTitle={strings.vendor}
            pageDescription=""
            pageCanonical="www.davincilock.com/UsersListing_Locks/Vendor">
            <SerialCodesTableContainer />
        </PageTemplate >
    );
};

export default VendorPage;
