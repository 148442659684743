import React from "react";
import styled from "styled-components";
import { Card } from "../../core/surfaces/Card";
import { Form } from "../../core/form-components/Form";

const Container = styled.div({
    minHeight: "295px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "556px",
    padding: "8px",
    boxSizing: "border-box",
    marginBottom: "0",
    textAlign: "left",
    columnGap: "30px",
    alignItems: "end",
    p: {
        textAlign: "left"
    },
    "a, button": {
        margin: 0
    },
    "@media(max-width:575px)": {
        gridTemplateColumns: "1fr",
    },
    "@media(max-width:865px)": {
        width: "100%",
        "a, button": {
            margin: "8px 0"
        },
    }
})

export const LoggedInForm = {
    Form: ({ children }: { children: any }) => {
        return (
            <Card>
                <Form>
                    <Container>
                        {children}
                    </Container>
                </Form>
            </Card>
        )
    },
    FullSpan: styled.div<{ center?: boolean }>(({ center }) => ({
        gridColumn: "1/-1",
        ...(center ? {
            display: "flex",
            flexDirection: "column",
            alignSelf: "center", justifySelf: "center", alignItems: "center"
        } : {})
    })),
    Title: styled.div({
        marginBottom: "8px",
        gridColumn: "1/-1",
        alignSelf: "start",
    }),
    Bottom: styled.div({
        alignSelf: "end"
    })
}
