import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styled from "styled-components";
import { IFieldProps } from "../../../hooks/useForm";
import { Input as MuiInput, InputLabel, Switch } from "@mui/material/";
import { PBold } from "../../../core/brand/typography";
import { strings } from "../../../content/strings";
import { useFacilities } from "../../../hooks/useFacilities";

export function FacilitiesMultiSelect({ facilityIdProps, onSwitchChange }: { facilityIdProps?: IFieldProps<any>; onSwitchChange: (val: boolean) => void }) {
    const { facilities } = useFacilities();

    const [checked, setChecked] = useState(false);

    const onSwitchClicked = useCallback(() => {
        setChecked(prevState => !prevState);
    }, [])

    useEffect(() => {
        onSwitchChange(checked)
    }), [checked]

    useEffect(() => {
        const userFacilities = facilityIdProps?.value as number[];

        // Our server logic treates no facilities as having access to all facilities
        if (userFacilities && !userFacilities.length) {
            setChecked(true)
        }
    }, []);

    const renderSelect = () => {
        if (checked || !facilityIdProps) {
            return null;
        }

        return (
            // @ts-ignore
            <Select
                labelId={strings.userFacilities}
                multiple
                {...facilityIdProps}
                MenuProps={{
                    sx: { maxHeight: "35vh", minHeight: "200px" },
                }}
                input={
                    <MuiInput
                        sx={{
                            "& .Mui-focused": {
                                color: "black !important"
                            }
                        }} />}
                renderValue={(selected: number[]) => {
                    const userFacilities = selected?.map(id => facilities.find(facility => facility.facilityID == id)) || [];
                    return (
                        <InputWrapper>
                            {userFacilities.map(facility => <Chip key={facility?.facilityID} label={facility?.facilityName} />)}
                        </InputWrapper>
                    );
                }}>
                {facilities.map(f => (
                    <MenuItem key={f.facilityID} value={f.facilityID}>
                        {f.facilityName}
                    </MenuItem>
                ))}
            </Select>
        )
    }

    return (
        <>
            <SwitchInput >
                <InputLabel>{strings.userFacilities}</InputLabel>
                <CenteredSpan>
                    <Switch
                        checked={checked}
                        onChange={onSwitchClicked} />
                    <PBold style={{ marginBlockEnd: "0px" }}>{strings.allFacilities}</PBold>
                </CenteredSpan>
            </SwitchInput>

            {renderSelect()}
        </>
    );
}

const InputWrapper = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5
})

const CenteredSpan = styled.span({
    display: "flex",
    alignItems: "center"
});

const SwitchInput = styled.span({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
});
