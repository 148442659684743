import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../core/table/Table";
import { strings } from "../../content/strings";
import { ICompany } from "../../models/ICompany";
import { useCompanies } from "../../hooks/useCompanies";
import { useNavigate } from "react-router-dom"
import SearchField from "../../core/form-components/SearchField";
import { ActionsContainer } from "../users-listing/components/UsersListingContainer";
import TableWrapper from "../../core/table/TableWrapper";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import TableTitle from "../../core/table/TableTitle";

const rowsPerPage = { initial: 20, options: [5, 10, 20] };

const headers: { text: string; key: string; date?: boolean; content?: any; }[] = [
    { text: strings.companyName, key: "companyName" },
    { text: strings.clientAdmin, key: "clientAdmin" },
    { text: strings.email, key: "email" },
    { text: strings.phoneNumber, key: "phoneNumber" },
    { text: strings.noOfActiveLocks, key: "noOfActiveLocks" },
];

const mobileKeys: { text: string; key: string; endOfRow?: any; }[] = [
    { text: strings.clientAdmin, key: "clientAdmin" },
    { text: strings.phoneNumber, key: "phoneNumber" },
    { text: strings.noOfActiveLocks, key: "noOfActiveLocks", endOfRow: true },
];

const CompanyListingContainer = () => {
    const navigate = useNavigate();
    const { companies } = useCompanies()
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [mobile] = useMediaQuery(414);

    const selectRow = useCallback((company: ICompany) => {
        navigate(`/Company/CompanyDetails?companyID=${company.companyID}`)
    }, [])

    const rowsProps = useMemo(() => ({ onClick: selectRow }), [selectRow]);

    return (
        <Container>
            <ActionsContainer>
                <TableTitle title={strings.companies} />
                <SearchField
                    data={companies}
                    searchKeys={["companyName", "clientAdmin", "email"]}
                    searchLabel={strings.companiesSearchLabel}
                    fullWidth={mobile}
                    setFilterData={setFilteredCompanies} />
            </ActionsContainer>
            <TableWrapper>
                <Table
                    tooltip={strings.tableTooltip}
                    data={filteredCompanies}
                    headers={headers}
                    rowsPerPage={rowsPerPage}
                    rowProps={rowsProps}
                    mobileIdentifier="companyName"
                    mobileHeaders={mobileKeys}
                />

            </TableWrapper>
        </Container >
    )
}

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default CompanyListingContainer
