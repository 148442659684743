import React from "react"
import agents from "../../../api/agent";
import { PrimaryButton } from "../../../components/button";
import Input from "../../../core/form-components/Input";
import { useForm } from "../../../hooks/useForm"
import { ILoginForm } from "../../../models/models";
import { accountValidator } from "../../../validators/validators";
import FormDivider from "./FormDivider";
import { FormCard } from "./FormStyles";
import { PBold } from "../../../core/brand/typography";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";

const AuthenticationForm = ({ initialValues, setErrorMessage, setUserAuthenticated }: {
    initialValues: ILoginForm,
    setErrorMessage: (arg0: string | undefined) => any,
    setUserAuthenticated: (arg0: boolean) => any,
}) => {
    const { trackClickEvent } = useAnalytics("Profile Authentication", false)

    const submitAction = async (formValues: ILoginForm, setFormValues: (arg0: ILoginForm) => any) => {
        trackClickEvent(analyticsEvents.submit)
        const result = await agents.Auth.login(formValues);

        if (result.success) {
            setErrorMessage("")
            setUserAuthenticated(true);
        } else {
            setErrorMessage(result?.error);
        }
    }

    const form = useForm<ILoginForm>(initialValues, submitAction, accountValidator)

    return (
        <FormCard>
            <Input {...form.inputProps.email} label={strings.email} disabled />
            <Input {...form.inputProps.password} label={strings.password} type="password" autocomplete="new-password" />
            <PBold>{strings.authenticationTitle}</PBold>
            <PrimaryButton fullWidth onClick={form.handleSubmit} >{strings.authenticate}</PrimaryButton>
            <FormDivider />
        </FormCard>
    )
}

export default AuthenticationForm;
