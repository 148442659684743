import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import RoleSettingsContainer from "./components/RoleSettingsContainer"
const RoleSettings = () => {

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.rolesSettingsDescription}
            pageDescription={strings.rolesSettings}
            pageCanonical={"https://www.davincilock.com/Company/RoleSettings"}
        >
            <RoleSettingsContainer />
        </LoggedInPageTemplate >
    )
}

export default RoleSettings
