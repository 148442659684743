import { Direction } from "../enums/Direction";
import { MessagesTemplateValue } from "../enums/MessagesTemplateValue";

const StringTemplate = {
    insertValue: (cursorPosition: any, template: string, insertedValue: string) => {
        if (!cursorPosition) {
            if (insertedValue[0] == StringTemplate.openDelimiter && insertedValue[insertedValue.length - 1] == StringTemplate.closeDelimiter) {
                return template.concat(" ", insertedValue);
            }
        } else {
            const [start, end] = cursorPosition;
            // if they are using shift to insert a value
            let newTemplate = template
            if (start !== end) {
                newTemplate = template.slice(0, start) + template.slice(end);
            }
            const firstHalf = newTemplate.slice(0, start);
            const addSpace = firstHalf[firstHalf.length - 1] !== " "

            return newTemplate = firstHalf +
                (addSpace ? " " : "") + insertedValue + newTemplate.slice(start);

        }
        return ""
    },

    matchingReg: /\{[\w\s]+\}/g,
    unMatchingReg1: /\{[\w\s]+/g,
    unMatchingReg2: /[\w\s]+\}/g,

    openDelimiter: "{",
    closeDelimiter: "}",

    noWidthSpace: "\u200B",

    validateTemplate: (input: string, templateValues: any) => {
        const fullMatch = input.match(StringTemplate.matchingReg);
        const partialMatch1 = input.match(StringTemplate.unMatchingReg1);
        const partialMatch2 = input.match(StringTemplate.unMatchingReg2);

        if (fullMatch?.length !== partialMatch1?.length
            || fullMatch?.length !== partialMatch2?.length) { return false }

        if (
            fullMatch?.every(item => templateValues.includes(item.slice(1, item.length - 1)))
        ) {
            return true;
        }

        if (input.includes(StringTemplate.openDelimiter) || input.includes(StringTemplate.closeDelimiter)) return false;
        return true;

    },

    findStartOfTemplateWord: (input: string, cursor: number) => {
        if (input[cursor] === StringTemplate.closeDelimiter) cursor--

        while (cursor > 0) {
            if (input[cursor] === StringTemplate.closeDelimiter)
                break;
            if (input[cursor] === StringTemplate.openDelimiter)
                return cursor

            cursor--
        }

        return -1
    },

    findEndOfTemplateWord: (input: string, cursor: number) => {
        while (cursor < input.length) {
            if (input[cursor] === StringTemplate.openDelimiter)
                break;
            if (input[cursor] === StringTemplate.closeDelimiter)
                return cursor + 1;

            cursor++
        }

        return -1
    },

    getWordIndexRange: (input: string, cursor: number) => {
        const startIndex = StringTemplate.findStartOfTemplateWord(input, cursor)
        if (startIndex === -1) return [-1, -1]

        const endIndex = StringTemplate.findEndOfTemplateWord(input, cursor)
        if (endIndex === -1) return [-1, -1]

        return [startIndex, endIndex]
    },

    getSelectionRangeFromArrowPress: ({
        shiftKey, direction, directionFromOrigin, selectionEnd, selectionStart, input
    }: any) => {

        const cursorPosition = directionFromOrigin === Direction.Right ? selectionEnd : selectionStart
        const nextCursorPosition = StringTemplate.nextCursorPosition(input, cursorPosition, direction)

        if (nextCursorPosition !== -1) {
            if (directionFromOrigin === Direction.Right) {
                return [shiftKey ? selectionStart : nextCursorPosition, nextCursorPosition]
            } else {
                return [nextCursorPosition, shiftKey ? selectionEnd : nextCursorPosition, "backward"]
            }
        }

        return []
    },

    isTemplateValue: (word: string): boolean => {
        //return word.match(matchingReg)[0]
        return word.match(StringTemplate.matchingReg) ? true : false
    },

    templateValue: (word: string): string => {
        const matchedWord: any = word.match(StringTemplate.matchingReg)
        return matchedWord[0].slice(1, (matchedWord[0].length - 1))

    },

    templateCount: (initialValue: string) => {
        let count = 0;
        if (initialValue) {
            initialValue.split(" ").map((word: string) => {
                if (word[0] == "{") {
                    count += 3
                } else {
                    count += word.length || 1
                }
            })
        }
        return count;
    },

    generatePreview: (initialValue: string, objectValues: any) => {
        return Object.keys(objectValues).reduce((acc, cv) => {
            return acc.replace(new RegExp(StringTemplate.openDelimiter + cv + StringTemplate.closeDelimiter, "g"), StringTemplate.noWidthSpace + objectValues[cv] + StringTemplate.noWidthSpace)
        }, (initialValue))
    },

    nextCursorPosition: (template: string, previousIndex: any, direction: Direction) => {
        if (direction == Direction.Right) {

            // get the template word range
            const [, end] = StringTemplate.getWordIndexRange(template, (previousIndex + 1))
            if (end !== -1) {
                return end;
            }
        }
        if (direction == Direction.Left) {
            // get the template word range
            const [start] = StringTemplate.getWordIndexRange(template, (previousIndex - 1))
            if (start !== -1) {
                return start;
            }
        }
        return -1
    },

    replaceMessageValues: (template: string): string => {
        const formattedMessage = template?.replace(/\{(\w+)\}/g, (match: any, capture: any) => {
            const value = MessagesTemplateValue[match as keyof typeof MessagesTemplateValue];
            return value !== undefined ? `{${value}}` : match;
        });
        return formattedMessage

    },

    replaceDefaultValue: (template: string): string => {
        const formattedMessage = template?.replace(/\{(\d+)\}/g, (match: any, capture: any) => {
            const index = parseInt(capture, 10);
            const value = MessagesTemplateValue[index];
            return value !== undefined ? value : match;
        });
        return formattedMessage;
    }
}

export default StringTemplate;
