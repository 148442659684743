import React, { createContext, useEffect } from "react";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { analyticsService } from "../services/analytics-service";

interface IDetailedAnalytics {
    context?: string,
    pageName?: string
}
interface IAnalytics {
    pageName: string,
}
export const AnalyticsContext = createContext<IAnalytics>({
    pageName: ""
})

const AnalyticsProvider = ({ children, pageName, }:
    {
        children: any,
        pageName: string,
    }) => {
    const { loggedInUser } = useLoggedInUser()

    useEffect(() => {
        if (loggedInUser?.userID) {
            analyticsService.trackViewEvent(pageName, pageName, loggedInUser)
        }

    }, [loggedInUser?.userID])

    return (
        <AnalyticsContext.Provider value={{
            pageName
        }}>
            {children}
        </AnalyticsContext.Provider>
    )
}

export default AnalyticsProvider;
