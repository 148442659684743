import React from "react";
import gears from "../../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../../core/layout/LoggedInPageTemplate";
import CMSDocumentRenderer from "../components/CMSDocumentRenderer";
import { termsOfUse } from "../../../content/termsOfUse";
import { Card } from "../../../core/surfaces/Card";
import { PageTemplate } from "../../../core/layout/PageTemplate";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";

const TermsOfUsePage = () => {
    const { loggedInUser, loading } = useLoggedInUser();
    if (loading) {
        return null;
    }

    if (loggedInUser) {
        return (
            <LoggedInPageTemplate
                backgroundImage={gears}
                pageTitle={termsOfUse.title}
                pageDescription=""
                pageCanonical="www.davincilock.com/Account/TermsOfUse">
                <Card style={{ backgroundColor: "white" }}>
                    <CMSDocumentRenderer cmsDocument={termsOfUse} />
                </Card>
            </LoggedInPageTemplate>
        );
    }

    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={termsOfUse.title}
            pageDescription=""
            pageCanonical="www.davincilock.com/Account/PrivacyPolicy">
            <Card style={{ margin: "0 24px", boxSizing: "border-box", backgroundColor: "white" }}>
                <CMSDocumentRenderer cmsDocument={termsOfUse} />
            </Card>
        </PageTemplate>
    );
};

export default TermsOfUsePage
