import React from "react";
import styled from "styled-components";
import { Card } from "../../../core/surfaces/Card";
import { strings } from "../../../content/strings";
import Role from "../../../enums/Role";
import { H2 } from "../../../core/brand/typography";
import { Colors } from "../../../core/brand/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigateToRazorPage } from "../../../api/agent";
import { useNavigate } from "react-router-dom";
import Navigation, { INavigation } from "../../../routes/Navigation";
import useAnalytics from "../../../hooks/useAnalytics";
import { ConditionalTooltip } from "../../../core/common/ConditionalTooltip";
import { ILoggedInUser } from "../../../models/ILoggedInUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const DashboardNavigationTiles = ({ loggedInUser }: { loggedInUser: ILoggedInUser }) => {
    const excludedTiles = (item: INavigation) => {
        return item.text !== strings.dashboard
            && item.text != strings.logout
            && item.text !== strings.davinciLockPremium
    }
    const filterChildren = (items: any) => {
        return items.filter((item: INavigation) => item.hidden ? !item.hidden(loggedInUser) : true)

    }
    const navItems = [
        ...Role.navigationPermissions(loggedInUser?.roleID, loggedInUser.permissions),
        ...([])
    ].filter((item) => excludedTiles(item))
        .reduce((acc: INavigation[], cv: INavigation) => {
            if (cv.children) {
                return [...acc, ...filterChildren(cv.children)]
            }
            return [...acc, cv]
        }, []).filter(cv => cv.text !== Navigation.profile().text);

    return (
        <TilesLayout>
            {navItems.map((item: INavigation) => {
                const [disabled, disabledMessage] = item.disabled ? item.disabled(loggedInUser) : []
                return (
                    <ConditionalTooltip
                        message={disabledMessage}
                        enabled={disabled}
                        placement="top"
                        key={`${item.link}-tooltip`}
                    >
                        <span style={{ width: "100%" }}>
                            <RenderPageTile disabled={disabled} item={item} />
                        </span>
                    </ConditionalTooltip>
                )
            })}
        </TilesLayout>
    )
}

const RenderPageTile = ({ item, disabled }: { item: INavigation, disabled?: boolean }) => {
    const navigate = useNavigate();
    const { trackClickEvent } = useAnalytics("Dashboard", false)

    const handleClick = async (item: INavigation) => {
        const { analyticsTag, link } = item;
        if (analyticsTag) {
            await trackClickEvent(analyticsTag);
        }
        if (link.link) {
            navigate(link.link);
        } else {
            navigateToRazorPage(link.href || "");
        }
    }

    return (
        <PageTile
            disabled={disabled}
            key={`tile-${item.link.link || item.link.href}`}
            onClick={() => !disabled && handleClick(item)}>
            <IconContainer />
            <StyledIcon size={"2x"} icon={item.icon as IconProp}></StyledIcon>
            <H2>{item.text}</H2>
        </PageTile>
    );
}

const TilesLayout = styled.div({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    marginTop: "40px",
    minHeight: "100%",
    width: "100%",
    minWidth: "100%",
    padding: "12px",
    boxSizing: "border-box",
    justifyItems: "center",
    '@media(max-width: 1375px)': {
        gridTemplateColumns: "1fr 1fr",
    },
    '@media(max-width: 997px)': {
        gridTemplateColumns: "1fr 1fr",
    },
});

const IconContainer = styled.div({
    height: "60px",
    width: "60px",
    background: Colors.red(),
    borderRadius: "30px"
});

const PageTile = styled(Card)<{ disabled?: boolean }>(({ disabled }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
    boxSizing: "border-box",
    minWidth: "180px",
    width: "100%",
    maxWidth: "380px",
    margin: "auto",
    marginBottom: "24px",
    minHeight: "200px",
    border: `solid 2px ${Colors.red()}`,
    cursor: "pointer",
    ":hover": {
        boxShadow: `-4px 4px 10px 2px  #b8c0c9`
    },
    background: "rgba(255, 255, 255, 0.8)",
    '@media(max-width: 995px)': {
        minWidth: "200px",
        width: "100%",
        maxWidth: "480px",
    },
    '@media(max-width: 780px)': {
        minWidth: "120px",
        width: "100%",
        maxWidth: "480px",
        maxHeight: "150px",
        minHeight: "150px",
        h2: {
            fontSize: "1.25rem",
            marginBottom: 0,
        },
        p: {
            fontSize: "0.8rem",
        }
    },
    [`> ${IconContainer}`]: {
        position: "absolute",
        top: "-30px"
    },
    ...(disabled ? {
        cursor: "unset",
        border: `solid 2px ${Colors.lightgrey()}`,
        boxShadow: "unset",
        ":hover": {
            boxShadow: "unset"
        },
        [IconContainer]: {
            backgroundColor: Colors.lightgrey()
        },
        [H2]: {
            color: Colors.lightgrey(),
        }
    } : {})
}));

const StyledIcon = styled(FontAwesomeIcon)({
    color: Colors.white(),
    position: "absolute",
    top: "-18px",
});

export default DashboardNavigationTiles;
