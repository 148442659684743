import React from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { HorizontalGrid, GridItem } from "../../../components/styled/boxes";
import { Colors } from "../../../core/brand/themes"
import { H3Bold, PMedium } from "../../../core/brand/typography";
import styled from "styled-components";
import Box from "@mui/material/Box/Box";

interface ILink {
    link?: string,
    text: string
}

interface IDescWLink {
    text?: string,
    link: ILink,
    remainingText?: string,
    sections?: any
}
interface Info {
    text?: string,
    button?: JSX.Element,
    link: ILink
}
interface Props {
    header?: string,
    description?: string,
    linkedDescription: IDescWLink,
    info: Info,
    img?: string,
    bg: number
}
const SectionImgDescription = (props: Props) => {
    const [isMobile, mdView] = useMediaQuery(764, 990);
    const imgHeight = isMobile ? window.innerHeight / 2.5 : mdView ? window.innerHeight / 3 : window.innerHeight / 2;
    const textAlign = isMobile ? "center" : "left";
    const secondary = props?.bg % 2 == 0 ? false : true

    return (
        <MainWrapper
            bg={props?.bg}
        >
            <ContentWrapper>
                <ColumnFlexContainer>
                    <H3Bold secondary={secondary} style={{ textAlign: textAlign, margin: " 0 0 8px 0", fontWeight: 400 }}> {props.header} </H3Bold>
                    <PMedium secondary={secondary} style={{ textAlign: textAlign, margin: " 0 0 16px 0", fontSize: "1rem" }}>{props.description}</PMedium>
                    {props?.linkedDescription &&
                        <span>
                            {props.linkedDescription?.sections &&
                                props.linkedDescription?.sections.map((s: string, idx: any) => {
                                    const inline = (idx + 1) === props.linkedDescription?.sections.length
                                    return (
                                        <PMedium style={{ textAlign: textAlign, marginBottom: inline ? "0px" : '24px', display: inline ? "inline" : "block" }} secondary={secondary} >{s}</PMedium>
                                    )
                                })
                            }
                            <PMedium style={{ textAlign: textAlign, margin: " 0 0 8px 0", display: "inline" }} secondary={secondary} >{props?.linkedDescription?.text}
                            </PMedium>
                            <a href={props?.linkedDescription?.link?.link} target={"#"} style={{ color: Colors.infoBlue(), textDecoration: "none", display: "inline", marginRight: " 4px" }}>
                                {props?.linkedDescription?.link?.text}
                            </a>
                            <PMedium style={{ textAlign: textAlign, margin: " 0 0 8px 0", display: "inline" }} secondary={secondary}>{props?.linkedDescription?.remainingText}</PMedium>

                        </span>
                    }
                    {props?.info &&
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", maxWidth: isMobile ? "auto" : "245px" }}>
                            <a href={props.info.link.link} style={{ color: secondary ? Colors.white() : Colors.black(), textAlign: isMobile ? "center" : "start", marginBottom: "8px", fontWeight: 300 }}>
                                {props?.info?.link?.text}
                            </a>

                            <PMedium secondary={secondary} style={{ margin: " 8px 0 0 0", textAlign: textAlign, fontStyle: "italic" }}>{props.info.text}</PMedium>
                            <div style={{ maxWidth: "40%", margin: isMobile ? "0 auto" : "0" }}>
                                {props.info.button}
                            </div>
                        </Box>
                    }
                </ColumnFlexContainer>
                <HorizontalGrid id="" items={[
                    GridItem({ el: <img style={{ objectFit: "contain", width: "100%", maxHeight: `${imgHeight}px` }} src={props.img} /> })
                ]} />
            </ContentWrapper>
        </MainWrapper>
    )
}

const MainWrapper: React.FC<{ children: JSX.Element, bg: number }> = ({ children, bg }) => {
    const backgroundColor = bg === 0 ? "transparent" : bg % 2 == 0 ? Colors.white() : Colors.navy()
    return (
        <Container bg={backgroundColor}>
            {children}
        </Container>
    )
}
const ContentWrapper: React.FC<{ children: JSX.Element[] }> = ({ children }) => {
    const [isMobile, mdView, lgView] = useMediaQuery(764, 990, 1300);
    const direction = isMobile ? "column" : "row";
    const textWrapperWidth = lgView && !mdView ? "75%" : mdView ? "100%" : "55%";
    return (

        <Wrapper
            w={textWrapperWidth}
            direction={direction}
            m="24px 0"
        >{children}</Wrapper>

    )
}
const ColumnFlexContainer: React.FC<{ children: JSX.Element[] }> = ({ children }) => {
    const [isMobile] = useMediaQuery(764);
    const alignItems = isMobile ? "center" : "flex-start";
    const width = isMobile ? "calc(100% - 48px)" : `${window.innerWidth / 1.4}px`;
    const padding = isMobile ? "0 16px 0 0" : "0 24px 0 0"

    return (
        <ColumnContainer align={alignItems} w={width} p={padding}>
            {children}
        </ColumnContainer>
    )
}

const Container = styled.div<{ bg: any }>(({ bg }) => ({
    display: "flex",
    flexDirection: "column",
    background: bg,
    padding: "0 24px",
    width: "calc(100% - 48px)",
    alignItems: "center",
    justify: "center",
}))

const ColumnContainer = styled.div<{ align: string, p: string, w: string }>(({ align, p, w }) => ({
    flexDirection: "column",
    alignItems: align,
    margin: "24px 0",
    padding: p,
    width: w
}))

const Wrapper = styled.div<{ w: string, direction: any, m: string }>(({ w, direction, m }) => ({
    display: "flex",
    flexDirection: direction,
    margin: m,
    width: w
}))

export default SectionImgDescription
