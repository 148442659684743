import React, {
    createContext,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import styled from "styled-components";
import { Colors } from "../core/brand/themes";
import { ToastType } from "../enums/ToastType";
import { faInfoCircle, faTimesCircle, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import "./toast.css"
import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const delay = (ms: number): Promise<any> => {
    return new Promise((resolve: TimerHandler) => setTimeout(resolve, ms));
}

type ToastProviderProps = {
    dismissTime?: number,
    children: any,
};

interface IToastContext {
    displayToast: (toast: DisplayToastArgs) => void
}

type DisplayToastArgs = {
    title?: string,
    text?: string,
    type: ToastType,
}

type IToast = DisplayToastArgs & {
    id: number,
    height?: number
}

export const ToastContext = createContext<IToastContext>({
    displayToast: () => { }
});

const ToastsProvider = ({ children, dismissTime = 5000 }: ToastProviderProps) => {
    const [toastsQueue, setToastsQueue] = useState<IToast[]>([])
    const $toastRefs = useRef<HTMLDivElement | null>(null)

    const displayToast = async (toast: DisplayToastArgs, duration: number = 5000) => {
        const id = Date.now()
        setToastsQueue((prevState) => [
            ...prevState,
            { ...toast, id }
        ])

        let toastEl: HTMLElement | null = null

        let attempt = 0
        while (!toastEl) {
            toastEl = document.getElementById(`toast${id}`)
            await delay(100)
            if (attempt++ > 5) {
                return
            }
        }

        toastEl?.animate([
            { transform: 'translateX(85%)', opacity: 0 },
            { transform: 'translateX(0%)', opacity: 1.1 },
        ], {
            duration: 500,
            iterations: 1,
            fill: "forwards",
        });

        await toastEl?.animate([
            { transform: 'translateX(0%)', opacity: 1 },
            { transform: 'translateX(95%)', opacity: 0 },
        ], {
            duration: 400,
            delay: duration + 500,
            iterations: 1,
            fill: "forwards",
        }).finished;

        setToastsQueue((prevState) => prevState.filter(t => t.id !== id))
    }

    const getToastIcon = (type: ToastType = ToastType.Success) => {
        switch (type) {
            case ToastType.Success:
                return faCheckCircle as IconProp
            case ToastType.Error:
                return faTimesCircle as IconProp
            case ToastType.Info:
                return faInfoCircle as IconProp;
            case ToastType.Warning:
            default:
                return faExclamationTriangle as IconProp
        }
    }

    const clickRemoveToast = async (id: number) => {
        const toastEl = document.getElementById(`toast${id}`)
        await toastEl!.animate({ transform: 'translateX(100%)', opacity: 0 }, { duration: 500, fill: 'forwards' }).finished;
        setToastsQueue((prevState) => {
            const newList = prevState.filter(t => t.id !== id)
            newList.forEach((t: any, i) => t.position = i)
            return newList
        })
    }

    useLayoutEffect(() => {
        if (toastsQueue.length && toastsQueue.some(toast => !toast.height)) {
            setToastsQueue((prevState) => {
                return prevState.map((toast) => {
                    if (!$toastRefs.current) {
                        return toast
                    }

                    const el: HTMLDivElement = [...$toastRefs.current.children as any].find((child) => {
                        const elId = child.id.replace(/\D+/gmi, "")
                        return elId == toast.id.toString()
                    })

                    if (el) {
                        el.style.opacity = "0";
                        el.style.transform = "translateX(85%)"
                        toast.height = el?.clientHeight || 1
                    }

                    return toast;
                })
            }
            )
        }
    }, [toastsQueue])

    let top = 0

    return (
        <ToastContext.Provider
            value={{
                displayToast
            }}
        >
            <div className="toasts" style={{ zIndex: 9999 }}>
                <div className="toasts-container" ref={$toastRefs}>
                    {
                        toastsQueue.map(toast => {
                            top += (8 + (toast.height || 0))
                            return (
                                <Toast
                                    onClick={() => clickRemoveToast(toast.id)}
                                    type={toast.type}
                                    key={toast.id}
                                    top={top - (toast.height || 0)}
                                    id={`toast${toast.id}`}>

                                    <div className="notification-image">
                                        <FontAwesomeIcon size="2x" icon={getToastIcon(toast.type)} />
                                    </div>
                                    <div>
                                        <ToastTitle className="notification-title">{toast.title || ToastType[toast.type || 1]}</ToastTitle>
                                        {toast.text && <p className="notification-message">
                                            {toast.text}
                                        </p>}
                                    </div>
                                </Toast>
                            )
                        })
                    }
                </div>
            </div>
            {children}
        </ToastContext.Provider>
    );
}

const toastBackgroundColor = (type: ToastType) => {
    switch (type) {
        case ToastType.Success:
            return Colors.green()
        case ToastType.Error:
            return Colors.red()
        case ToastType.Info:
            return Colors.infoBlue()
        case ToastType.Warning:
        default:
            return Colors.warningYellow()
    }
}

const Toast = styled.div<{ type: ToastType, top: number }>(({ type = ToastType.Success, top }) => ({
    position: "absolute",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    textAlign: "left",
    zIndex: 9999,
    backgroundColor: toastBackgroundColor(type),
    right: 0,
    minWidth: "365px",
    width: "365px",
    padding: "8px 20px",
    color: Colors.white(),
    transition: "top ease-in 0.6s",
    top: `${top}px`,
    borderRadius: "3px",
    "&:hover": {
        boxShadow: `0 0 12px ${Colors.lightgrey()}`,
        opacity: 1,
        cursor: "pointer",
    },
    boxShadow: `0 0 12px ${Colors.lightgrey()}`,
}))

const ToastTitle = styled.p({
    fontWeight: "700",
    fontSize: "16px",
    textAlign: "left",
    marginTop: "0",
    marginBottom: "6px",
    minWidth: "300px",
    height: "18px",
})

export default ToastsProvider;
