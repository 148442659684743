import { ILoggedInUser } from "../models/ILoggedInUser"

// Justin
enum Permission {
    Locks = 1,
    "Activate Locks" = 2,
    Inventory = 3,
    "Tenant Locks" = 4,
    Facilities = 5,
    "Transfer Facility" = 6,
    Users = 7,
    "Edit Admins" = 8,
    Subdomains = 9,
    ApiSettings = 10,
    "Lock Returns" = 11
}

namespace Permission {
    export function isToggle(id: Permission): boolean {
        return id === Permission["Activate Locks"]
            || id === Permission["Transfer Facility"]
            || id === Permission["Edit Admins"]
    }

    export function write(
        permissionID: Permission,
        loggedInUser: ILoggedInUser | null,
        roleIDs?: number[]
    ) {
        if (!loggedInUser?.roleID) return false;
        if (!roleIDs && (loggedInUser.roleID) < 100) return true;
        if (roleIDs && roleIDs.includes(loggedInUser.roleID)) return true;

        return loggedInUser.permissions && loggedInUser.permissions[permissionID]?.accessLevel > 1
    }
}

export default Permission
