import React from "react";
import gears from "../../assets/images/gears.png";
import { PageTemplate } from "../../core/layout/PageTemplate";
import { strings } from "../../content/strings";
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPasswordPage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.davinci}
            pageDescription={strings.davincilockPageDescription}
            center>
            <ResetPasswordForm />
        </PageTemplate>
    );
};

export default ResetPasswordPage;
