export const deepEquals = (value1: any, value2: any): boolean => {
    if ((typeof (value1) === "object")) {
        if (value1 == null) {
            return value1 == value2
        }
        if (Object.keys(value1).length !== Object.keys(value2).length) {
            return false;
        }
        return Object.entries(value1).every(([k1, v1]) => {
            return deepEquals(v1, value2[k1])
        })
    } else {
        return value1 == value2
    }
}
