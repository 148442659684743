import React, { useContext, useEffect, useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import Box from '@mui/material/Box';
import { IFieldProps } from '../../hooks/useForm';
import Input from './Input';
import { CountryContext } from '../../contexts/CountryProvider';
import { MapsApiContext } from '../../middleware/GoogleScriptWrapper';
import { strings } from '../../content/strings';
import { extractGoogleAddress } from '../../utils/getGoogleAddress';
import { ICompanyDetailsForm } from '../../models/ICompanyDetails';
import { IFacilityModelForm } from '../../models/IFacilityForm';

const AddressAutoComplete = ({
    street,
    city,
    state,
    zip,
    setFormAddress,
    clearErrors
}: {
    street: IFieldProps<IFacilityModelForm> | IFieldProps<ICompanyDetailsForm>,
    city: IFieldProps<IFacilityModelForm> | IFieldProps<ICompanyDetailsForm>,
    state: IFieldProps<IFacilityModelForm> | IFieldProps<ICompanyDetailsForm>,
    zip: IFieldProps<IFacilityModelForm> | IFieldProps<ICompanyDetailsForm>,
    setFormAddress: (values: any) => void,
    clearErrors: () => void,
}) => {
    const country = useContext(CountryContext)
    const { isLoaded, loadError } = useContext(MapsApiContext)
    const autocompleteRef = useRef<google.maps.places.Autocomplete>();

    const onLoad = React.useCallback(function onLoad(autocomplete: google.maps.places.Autocomplete) {
        autocompleteRef.current = autocomplete;
        document.getElementById("autocomplete-street")?.focus()
    }, []);

    const onPlaceChanged = React.useCallback(() => {
        const place: google.maps.places.PlaceResult = autocompleteRef.current!.getPlace();

        if (!place?.address_components) {
            return;
        }

        const address = extractGoogleAddress(place)

        setFormAddress({
            ...address,
            // if we successfully grabbed a google address, then the address is clean
            dirtyAddress: !address
        })
        clearErrors()
    }, []);

    useEffect(() => {
        const item = document.getElementsByClassName("pac-container")[0] as HTMLDivElement
        if (item) {
            item.style.zIndex = "10000"
        }
    }, [street.value])

    useEffect(() => () => {
        Array.from(document.getElementsByClassName("pac-container")).forEach(item => {
            item.remove()
        })
    }, [])

    const canUseGoogle = country && country !== "CN"

    const handleChange = (field: string, value: string) => {
        setFormAddress({
            [field]: value,
            dirtyAddress: canUseGoogle ? true : false // Adds dirtyAddress flag to form.
        })
    }

    const renderGoogleAutoComplete = () => {
        if (canUseGoogle && isLoaded && !loadError?.message) {
            return (
                <Autocomplete
                    //@ts-ignore
                    ref={autocompleteRef}
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <Input
                        {...street}
                        id="autocomplete-street"
                        onChange={(e: any) => handleChange("street", e.target.value)}
                        label={strings.street}
                    />
                </Autocomplete>
            )
        }

        return (
            <Input
                {...street}
                onChange={(e: any) => handleChange("street", e.target.value)}
                label={strings.street}
            />
        )
    }

    return (
        <>
            {renderGoogleAutoComplete()}
            <Input
                {...city}
                onChange={(e: any) => handleChange("city", e.target.value)}
                label={strings.city}
            />
            <Box sx={{
                display: "flex",
                gap: "16px",
                mt: 1,
                mb: 3,
                mx: "auto"
            }}>
                <Input
                    {...state}
                    onChange={(e: any) => handleChange("state", e.target.value)}
                    label={strings.state}
                />
                <Input
                    {...zip}
                    onChange={(e: any) => handleChange("zip", e.target.value)}
                    label={strings.zip}
                />
            </Box>
        </>
    );
};

export default AddressAutoComplete;
