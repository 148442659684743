import { Box, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, List, ListItem, ListItemText } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { H3Bold, H4Bold, PBold } from '../../../core/brand/typography';
import { strings } from '../../../content/strings';
import { _FMS } from '../../facility-listing/components/FMSForm';
import { useMediaQuery } from '../../../hooks/hooks';
import { Colors } from '../../../core/brand/themes';
import ClearIcon from '@mui/icons-material/Clear';

export const HelperDialog = ({ open, setOpen }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const [mobile] = useMediaQuery(840);

    const headers = [
        "Facility Management Software",
        "Internal Name",
        "Display Name",
        "Facility URL",
        "No of Units",
        "Email",
        "Phone Number",
        "Customer Service Phone Number",
        "Street",
        "City",
        "State",
        "Zip",
        "Company Display Name",
        "External Facility Id",
        "Corporate Code",
        "Location Code",
        "Corporate Username",
        "Corporate Password",
    ];
    const rows = [
        { fmsName: "DoorSwap", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false, externalFacilityId: true },
        {
            fmsName: "SiteLink", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false, externalFacilityId: false, CorporateCode: true,
            LocationCode: true, CorporateUsername: true, CorporatePassword: true
        },
        {
            fmsName: "storEDGE", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false, externalFacilityId: true,
        },
        {
            fmsName: "Easy Storage Solutions", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false, externalFacilityId: true,
        },
        {
            fmsName: "Hummingbird", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false, externalFacilityId: true,
        },
        {
            fmsName: "No Fms", internalName: true, displayName: false, facilityUrl: false, no_Of_Units: false, email: false, phone: true, customerPhoneNumber: false, street: true, city: true, state: true, zip: true, companyDisplayName: false,
        },
    ]
    return (
        <Dialog open={open} onClose={() => setOpen(false)} sx={{ padding: "8px" }} maxWidth={"xl"} fullScreen={mobile}>
            <DialogHeader>
                <PBold secondary onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}><ClearIcon/></PBold> 
            </DialogHeader>
            <Box sx={{ display: "flex", flexDirection: "column", padding: "8px 12px", gap: "12px" }}>
                <H4Bold left>{strings.integrateFacilities} </H4Bold>
                <List sx={{ width: { lg: "55%", sm: "100%" } }}>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={
                                <>
                                    <PBold left>{strings.selectFms}</PBold>
                                </>
                            }
                            secondary={
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", padding: "8px 12px" }}>
                                        {strings.csvInstructions.map((info: string) => {
                                            return (
                                                <>
                                                    <PBold left>{info}</PBold>
                                                </>
                                            )
                                        })}
                                    </Box>
                                </>
                            }
                        >
                        </ListItemText>
                    </ListItem>
                </List>
            </Box>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header: string) => {
                                    return (
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="left">{header}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any) => {
                            return (
                                <TableRow key={row.fmsName}>
                                    <TableCell align="center">{row.fmsName}</TableCell>
                                    {
                                        Object.values(row).map((item) => {
                                            if (typeof (item) == "boolean") {
                                                return (
                                                    <TableCell align="center">{item ?
                                                        <Tooltip title={strings.requiredField}>
                                                            <H3Bold>*</H3Bold>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={strings.nonRequiredField}>
                                                            <H3Bold>{" "}</H3Bold>
                                                        </Tooltip>
                                                    }</TableCell>
                                                )
                                            } return null
                                        })
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Box sx={{ m: 4 }}>
                    {strings.csvPopupNotes.map((info: string) => <PBold left > {info}</PBold>)}
                </Box>
            </TableContainer>

        </Dialog>
    )
}

export const DialogHeader = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse"
})
