import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Box, Radio } from "@mui/material";
import React from "react";
import { getGoogleAddress } from "../../../utils/getGoogleAddress";
import { PopupContext } from "../../../contexts/PopupProvider";
import { FormFlowRenderProps } from "../../../hooks/useFormFlow2";
import { useRefState } from "../../../hooks/useRefState";
import { IAddress } from "../../../models/facility";
import { IFacilityModelForm } from "../../../models/IFacilityForm";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import AddressAutoComplete from "../../../core/form-components/AddressAutoComplete";
import { PBold, PMedium, PThin } from "../../../core/brand/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../../core/brand/themes";
import { IForm } from "../../../hooks/hooks";
import { strings } from "../../../content/strings";
import { events as analyticsEvents } from "../../../mixPanelEvents"

export const FacilityAddressStep = {
    Render: ({ form, next, back, analytics }: FormFlowRenderProps<IFacilityModelForm>) => {
        const { confirm, popup } = useContext(PopupContext);
        const [addressRef, setAddress] = useRefState<IAddress | null>(null)

        const confirmAddress = async () => {
            const addresses = await getGoogleAddress(form.values);
            addressRef.current = addresses.length > 1 ? null : addresses[0];

            if (addresses.length) {
                const ConfirmComponent = () => addresses.length === 1 ?
                    <ConfirmAddress address={addresses[0]} setAddress={setAddress} /> :
                    <ChooseAddress addresses={addresses} setAddress={setAddress} />

                if (await confirm(ConfirmComponent, { cancelText: strings.continueEditing })) {
                    const newForm = await form.setFormValuesAsync((previous: any) => ({
                        ...previous,
                        ...(addressRef.current),
                        dirtyAddress: false
                    }));

                    next(newForm);
                }
            } else {
                if (await popup(NoAddresses, { continueText: strings.continueEditing })) {
                    const newForm = await form.setFormValuesAsync((previous: any) => ({
                        ...previous,
                        ...(addressRef.current),
                        dirtyAddress: false
                    }));

                    next(newForm);
                }

            }
        }

        const handleNext = () => {
            /** dirtyAddress is true when address has changed, but no google address was selected. */
            if (!form.values.lat || form.values.dirtyAddress) {
                confirmAddress()
            } else {
                next(form);
                analytics(analyticsEvents.nextStep)
            }
        };

        const handleBack = () => {
            back(form)
            analytics(analyticsEvents.previousStep)
        }

        return (
            <>
                <AddressAutoComplete
                    street={form.inputProps.street!}
                    state={form.inputProps.state!}
                    city={form.inputProps.city!}
                    zip={form.inputProps.zip!}
                    setFormAddress={(address: any) => form.setFormValues(previous => ({
                        ...previous,
                        ...address
                    }))}
                    clearErrors={form.clearErrors} />

                <Box display="flex" gap="12px" sx={{ width: "100%" }}>
                    <QuaternaryButton type="button" fullWidth onClick={handleBack}>{strings.back}</QuaternaryButton>
                    <PrimaryButton fullWidth onClick={handleNext} >{strings.continue}</PrimaryButton>
                </Box>
            </>
        );
    },
    validate: (form: IForm<IFacilityModelForm>) => {
        return form.validateFields("street", "state", "city", "zip", "lat", "lng");
    },
};

const ConfirmAddress = ({ address, setAddress }: { address: IAddress, setAddress: (address: IAddress) => void }) => {
    useEffect(() => {
        setAddress(address)
    }, [])
    return (
        <Box display="flex">
            <Box sx={{ m: 2, p: 2 }}>
                <PBold>{strings.yourAddress}</PBold>
                <PThin>{strings.theAddressProvided}</PThin>
                <Box display="flex" gap="16px" justifyContent={"center"}>
                    <FontAwesomeIcon icon={faCheckCircle as IconProp} color={Colors.green()} size="2x" style={{ alignSelf: "center" }} />
                    <Box>
                        <PThin left style={{ marginTop: "16px" }}>
                            {address?.street}
                        </PThin>
                        <PThin left>
                            {address?.city} {" "} {address?.state} {" "}{address?.zip}
                        </PThin>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const ChooseAddress = ({ addresses, setAddress }: { addresses: IAddress[], setAddress: (address: IAddress) => void }) => {
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        setAddress(addresses[selected])
    }, [selected])

    return (
        <Box display="flex">
            <Box sx={{ m: 2, p: 2 }}>
                <PBold>{strings.chooseAddress}</PBold>

                <PMedium>{strings.addresses}</PMedium>

                {addresses.map((address, idx) => (
                    <Box display="flex" gap="16px" justifyContent={"center"} alignItems="center">
                        <Radio
                            checked={selected === idx}
                            onClick={() => setSelected(idx)}
                        />

                        <Box>
                            <PMedium left style={{ marginTop: "16px" }}>
                                {address?.street}
                            </PMedium>
                            <PMedium left>
                                {address?.city} {" "} {address?.state} {" "}{address?.zip}
                            </PMedium>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

const NoAddresses = () => {
    return (
        <Box sx={{ m: 2, p: 2 }}>
            <Box>
                <PBold>{strings.weCantVerifyAddress}</PBold>
                <PThin>{strings.pleaseContinueEditing}</PThin>
            </Box>
        </Box>
    )
}
