import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useAppSelector";
import { CustomRole } from "../models/CustomRole";
import { getCustomRoles, fetchCustomRolesAsync } from "../redux/custom-roles/customRolesSlice";
import { useLoggedInUser } from "./useLoggedInUser";

export const useCustomRoles = (): {
    customRoles: CustomRole[],
    fetchCustomRoles: () => void,
    customRolesMap: { [key: number | string]: number | string },
    pending?: boolean
} => {
    let { customRoles, pending } = useAppSelector(getCustomRoles);
    customRoles = customRoles.filter(x => x.name)

    const { userRef } = useLoggedInUser();
    const dispatch = useAppDispatch();

    const customRolesMap: { [key: number | string]: number | string } = useMemo(() => {
        return customRoles.reduce((acc, { id, name }) => ({
            ...acc,
            [id]: name,
            [name]: id,
        }), {})
    }, [customRoles])

    const fetchCustomRoles = useCallback(() => {
        if (userRef.current) {
            dispatch(fetchCustomRolesAsync())
        }
    }, [userRef.current])

    return {
        pending,
        customRoles,
        fetchCustomRoles,
        customRolesMap
    }
}
