import React, { useEffect, useState } from "react";
import AcceptTransferPage from "../../pages/approve-facility-transfer/AcceptTransferPage";
import agents, { navigateToRazorPage } from "../../api/agent";
import LoginPage from "../../pages/login/LoginPage";
import jwt_decode from "jwt-decode";
import PromptRecipientToSelectClientAdmin from "./PromptRecipientToSelectClientAdmin";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { FacilityDto } from "../../models/facility";
import { useQuery } from "../../hooks/useQuery";
import Role from "../../enums/Role";
import { useLogout } from "../../hooks/useLogout";

type FacilityTransferJwt = {
    Email: string
}

const getEmailFromFacilityTransferJwt = (jwt: string): string => {
    try {
        return jwt_decode<FacilityTransferJwt>(jwt)?.Email || "";
    } catch (err) {
        return "";
    }
}
export const FacilityTransferPageContainer = () => {
    const { loggedInUser, loading } = useLoggedInUser();
    const [error, setError] = useState<string>("");
    const [facility, setFacility] = useState<FacilityDto | {}>({});
    const token = useQuery<string>("token");
    const email = getEmailFromFacilityTransferJwt(token);
    const { logout } = useLogout()

    useEffect(() => {
        const getFacilityFromToken = async () => {
            const response = await agents.Facilities.getFacilityFromToken(token as string);
            if (response.success) {
                setFacility(response.data || {});
            } else {
                setError(response.error || "")
            }
        }
        if (loggedInUser && loggedInUser?.email !== email.trim()) {
            logout()
        } else if (loggedInUser && loggedInUser?.email === email.trim()) {
            getFacilityFromToken();
        }
    }, [loggedInUser?.userID]);

    if (error) {
        navigateToRazorPage("")
        return null
    }

    if (loading) {
        return null;
    }
    if (!loggedInUser || loggedInUser?.email !== email.trim()) {
        return <LoginPage email={email.trim()} />
    }

    if (loggedInUser?.roleID !== Role.Admin && loggedInUser?.roleID !== Role.Owner) {
        return <PromptRecipientToSelectClientAdmin facility={facility as FacilityDto} token={token as string} />
    }

    return <AcceptTransferPage token={token as string} facility={facility as FacilityDto} />
}

export default FacilityTransferPageContainer
