import React, { useEffect, useMemo, useState } from "react";
import SearchField from "../../../core/form-components/SearchField";
import Table from "../../../core/table/Table";
import { ICompanyUser } from "../../../models/ICompanyUser";
import { PrimaryButton } from "../../../components/button";
import { strings } from "../../../content/strings";
import { ActionsContainer } from "./UsersListingContainer";
import { useUsers } from "../../../hooks/useUsers";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import TableWrapper from "../../../core/table/TableWrapper";
import { useCustomRoles, useMediaQuery } from "../../../hooks/hooks";
import TableTitle from "../../../core/table/TableTitle";
import Permission from "../../../enums/Permission";
import Role from "../../../enums/Role";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
export function UsersListingTable({
    toggleAddUser,
    selectedUser,
    setSelectedUser,
    canUpdate,
}: any) {
    const { users, activeUsersFlag, setActiveUsersFlag } = useUsers();
    const { loggedInUser } = useLoggedInUser()
    const [mobile] = useMediaQuery(414)
    const { customRolesMap } = useCustomRoles()

    const [filteredUsers, setFilteredUsers] = useState<ICompanyUser[]>([]);
    const [displayData, setDisplayData] = useState<ICompanyUser[]>([]);

    const writePermissions = Permission.write(Permission.Users, loggedInUser, [Role.Owner, Role.Admin, Role["Site Admin"], Role["Super Admin"], Role["Site Personnel"],])
    const editAdmins = Permission.write(Permission["Edit Admins"], loggedInUser, [Role.Owner, Role["Site Admin"], Role["Super Admin"]])
    const editOwner = loggedInUser?.roleID === Role["Site Admin"] || loggedInUser?.roleID === Role["Super Admin"] || loggedInUser?.roleID === Role.Owner

    const selectRow = async (user: ICompanyUser) => {
        if (user.roleID === Role.Owner && !editOwner) {
            return; // noop
        }
        if (user.roleID === Role.Admin && !editAdmins) {
            return; // noop
        }
        setSelectedUser(user);
    }

    const canEdit = (user: ICompanyUser) => {
        if (user.roleID === Role.Owner && !editOwner) {
            return false;
        }
        if (user.roleID === Role.Admin && !editAdmins) {
            return false;
        }

        return canUpdate
    }

    const rowsPerPage = { initial: 10, options: [5, 10, 20] };
    const rowsProps = writePermissions ? { onClick: selectRow } : null;

    const headers: { text: string; key: string; date?: boolean; content?: any; }[] = useMemo(() => [
        { text: strings.nameUpperCase, key: "name", default: true },
        { text: strings.email, key: "email" },
        {
            text: strings.role,
            key: "roleName",
            content: (tr: ICompanyUser) => customRolesMap[tr.roleID] || tr.roleName
        },
        { text: strings.active, key: "displayStatus" },
        ...(
            !loggedInUser?.companyID ? [] :
                [{ text: strings.assignedFacilities, key: "facilities", content: (row: ICompanyUser): any => row.facilityIDs?.length || strings.all }]
        ),
    ], [loggedInUser?.companyID, customRolesMap]);

    const renderAddUserButton = () => {
        const mobileButtonStyles = mobile ? { width: "95%", marginTop: "-4px", marginBottom: "8px" } : {}
        if (!canUpdate || !writePermissions) {
            return null
        }

        return (
            <span style={{
                marginTop: "4px"
            }}>
                <PrimaryButton style={mobileButtonStyles} clickContext="Open Add User" onClick={toggleAddUser}>{strings.addUser}</PrimaryButton>
            </span>
        )
    }

    useEffect(() => {
        const usersForDisplay = users.map((u) => u.status ? { ...u, displayStatus: strings.active } : { ...u, displayStatus: strings.inactive })
        setDisplayData(usersForDisplay)
    }, [users])

    const mobileKeys = [
        { key: "email", text: strings.emailLabel },
        { key: "roleName", text: strings.role },
        { key: "facilities", text: strings.assignedFacilities, content: (row: ICompanyUser): any => row.facilityIDs?.length || strings.all, endOfRow: true },
    ]
    const updateList = () => {
        setActiveUsersFlag(!activeUsersFlag)
    }

    return (
        <>
            <ActionsContainer>
                <TableTitle title={strings.users} />
                <SearchField
                    data={displayData}
                    searchKeys={["email", "firstName", "lastName"]}
                    searchLabel={strings.usernameOrEmail}
                    setFilterData={setFilteredUsers}
                    useUserRoleAutoComplete
                    fullWidth={mobile} />
                {renderAddUserButton()}
            </ActionsContainer>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={!activeUsersFlag} onChange={updateList} />}
                    label={activeUsersFlag ? "Show All Users" : "Show Active Users"} />
            </FormGroup>
            <TableWrapper>
                <Table
                    tooltip={strings.tableTooltip}
                    data={filteredUsers}
                    headers={headers}
                    rowsPerPage={rowsPerPage}
                    rowProps={rowsProps}
                    canEdit={canEdit}
                    selectedRow={selectedUser || null}
                    mobileIdentifier="name"
                    mobileHeaders={mobileKeys}
                    defaultSortKey="name"
                />
            </TableWrapper>
        </>
    );
}
