import React, { useCallback, useState } from "react"
import styled from 'styled-components'
import { Box, Tabs, Tab } from "@mui/material";
import { strings } from "../../../content/strings";
import { Colors } from "../../../core/brand/themes";
import TabPanel from "../../../pages/company-details/components/TabPanel";
import ApiListing from "./ApiListing";
import ApiFacilityListing from "./ApiFacilityListing";
import Permission from "../../../enums/Permission";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";

export enum TabState {
    APISettings,
    Facilities,
}
const ApiSettingsContainer = () => {
    const [tab, setTab] = useState<number>(TabState["APISettings"]);
    const { loggedInUser } = useLoggedInUser();

    const writePermissions = Permission.write(Permission.ApiSettings, loggedInUser)

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }, []);

    return (
        <>
            <Container>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleChange}>
                        <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={"Api Settings"} />
                        <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={strings.facilities} />
                    </Tabs>
                </Box>
                <TabPanel value={tab} index={TabState["APISettings"]}>
                    <ApiListing writePermissions={writePermissions!} />
                </TabPanel>
                <TabPanel value={tab} index={TabState["Facilities"]}>
                    <ApiFacilityListing writePermissions={writePermissions!} />
                </TabPanel>

            </Container >
        </>
    )
}

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default ApiSettingsContainer;
