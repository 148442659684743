import { Box, Divider, Fab, Grid } from '@mui/material';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import AutoComplete from '../../../core/form-components/AutoComplete';
import { _FMS } from '../../facility-listing/components/FMSForm';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import LinearProgress from '@mui/material/LinearProgress';
import StorEdgeFacilityImportForm from "./StorEdgeFacilityImportForm"
import SiteLinkFacilityImportForm from "./SiteLinkFacilityImportForm"
import FacilityCSVForm from "./FacilityCSVForm"
import { HubContext } from '../../../contexts/HubContext';
import HelpIcon from '@mui/icons-material/Help';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { PBold } from '../../../core/brand/typography';

const ImportFacilities = () => {
    const [managementSoftware, setManagementSoftware] = useState<ManagementSoftware>(ManagementSoftware.storEDGE)
    const [open, setOpen] = useState<boolean>(false);
    const { progressInfo } = useContext(HubContext);
    const { displayToast } = useContext(ToastContext);
    const { completed, total } = progressInfo

    const handleChangeFMS = (fms: keyof typeof ManagementSoftware) => {
        setManagementSoftware(ManagementSoftware[fms])
    };

    let progressLoaderValue =
        completed == 0 || total == 0 ? 0 :
            (completed / total) * 100;

    const handleDialog = () => {
        setOpen(true)
    }

    useEffect(() => {
        if (completed == total && completed > 0) {
            displayToast({ type: ToastType.Success, text: strings.facilitiesSuccess })
        }

    }, [completed])

    return (
        <Grid container gap="32px">
            <Grid item xs={12} >
                <CardHeader>
                 <PBold secondary>
                        {strings.integrations}
                    </PBold>
                </CardHeader>
            </Grid>
            <Grid item container xs={12} gap={{ lg: "4px", md: "32px" }} justifyContent={"space-around"} alignItems="center" sx={{ flexGrow: 3, p: "24px 0" }} >
                <Grid item lg={5} xs={10}>
                    <AutoComplete
                        noClear={false}
                        value={
                            ManagementSoftware[managementSoftware]
                        }
                        options={_FMS}
                        setValue={handleChangeFMS}
                        label={strings.managementSoftware} />
                </Grid>
                <Grid item lg={5} xs={10} sx={{ marginTop: "12px" }}>
                    <LinearProgress variant="determinate" value={progressLoaderValue} sx={{ height: "16px", borderRadius: "10px" }} />
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ padding: "4px" }} >
                <Divider sx={{ background: Colors.red(), height: "1px" }} />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: "345px", margin: "auto", maxHeight: "420px", }} >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Fab color="primary" size="small" sx={{ alignSelf: "end" }} onClick={handleDialog}>
                        <HelpIcon />
                    </Fab>

                    {useMemo(() => {
                        if (managementSoftware === ManagementSoftware.storEDGE)
                            return <StorEdgeFacilityImportForm open={open} setOpen={setOpen} />

                        if (managementSoftware === ManagementSoftware.SiteLink)
                            return <SiteLinkFacilityImportForm open={open} setOpen={setOpen} />

                        return <FacilityCSVForm managementSoftware={managementSoftware} openDialog={open} setOpenDialog={setOpen} />
                    }, [managementSoftware, open])}
                </Box>
            </Grid >
        </Grid >
    );
}

const CardHeader = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
});

export default ImportFacilities;
