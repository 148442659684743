

export const valuesAndInstructionHeaders = [
    "InternalName", "DisplayName", "FacilityURL", "No_Of_Units", "Email", "PhoneNumber", "PhoneNumberCustomerService", "Street", "City", "State",
    "Zip", "BrandName", "ExternalFacilityId", "CorporateUsername", "CorporatePassword"
]

export const doorSwapCloudExplained = [
    "", "", "", "", "", "", "",
    "Facility street", "Facility city", "Facility state", "Facility zip code",
    "Facility corresponding company name on overlockrelease.com",
    "DoorSwap identification code for facility (This information can be obtained by contacting DoorSwap Support. You will also need to provide DaVinci Lock API information to DoorSwap.)",
    "DoorSwap username",
    "DoorSwap password",
]
export const storEDGEvaluesAndInstructionHeaders = [
    "InternalName", "DisplayName", "FacilityURL", "No_Of_Units", "Email", "PhoneNumber", "PhoneNumberCustomerService", "Street", "City", "State",
    "Zip", "BrandName", "ExternalFacilityId",
]

export const storEDGEExplained = [
    "", "", "", "", "", "", "",
    "Facility street", "Facility city", "Facility state", "Facility zip code",
    "Facility corresponding company name on overlockrelease.com",
    "StorEDGE identification code for facility. (This information can be obtained from the StorEDGE FMS.)"
]
export const easyStorageExplained = [
    "", "", "", "", "", "", "",
    "Facility street", "Facility city", "Facility state", "Facility zip code",
    "Facility corresponding company name on overlockrelease.com",
    "Easy Storage Solutions API key for facility. (This information can be obtained by contacting Easy Storage Solutions Support.)"
]

export const storEdgeRequirements = [
    "Required", "Optional", "Optional", "Optional", "Optional",
    "Required", "Optional",
    "Required", "Required", "Required", "Required",
    "Optional", "Required"
]

export const hummingbirdExplained = [
    "", "", "", "", "", "", "",
    "Facility street", "Facility city", "Facility state", "Facility zip code",
    "Facility corresponding company name on overlockrelease.com",
    "Hummingbird GDS ID for facility. (This information can be obtained from the Tenant Inc Website.)"
]

export const hummingbirdRequirements = [
    "Required", "Optional", "Optional", "Optional", "Optional",
    "Required", "Optional",
    "Required", "Required", "Required", "Required",
    "Optional", "Required"
]

export const hummingbirdExamples = [
    "", "", "", "", "", "", "",
    "Aberdeen st", "Richmond", "VA", "5555",
    "",
    "fac0xxxx123456789xxxxxxxx12345678x9"
]

export const siteLinkValuesAndInstructionHeaders = [
    "InternalName", "DisplayName", "FacilityURL", "No_Of_Units", "Email", "PhoneNumber", "PhoneNumberCustomerService", "Street", "City", "State",
    "Zip", "BrandName", "CorporateCode", "LocationCode", "CorporateUsername", "CorporatePassword"
]
export const siteLinkExplained = [
    "", "", "", "", "", "", "",
    "Facility street", "Facility city", "Facility state", "Facility zip code",
    "Facility corresponding company name on overlockrelease.com",
    "SiteLink identification code for company (This information can be obtained from SiteLink Corporate Control Center.)",
    "SiteLink location code for facility (This information can be obtained from SiteLink Corporate Control Center.)",
    "SiteLink username",
    "SiteLink password",
]
export const siteLinkRequirements = [
    "Required", "Optional", "Optional", "Optional", "Optional",
    "Required", "Optional",
    "Required", "Required", "Required", "Required",
    "Optional", "Required", "Required",
    "Required", "Required",
]

export const storEDGExamples = [
    "", "", "", "", "", "", "",
    "Aberdeen st", "Richmond", "VA", "5555",
    "",
    "n3316357-2j55-38b9-4639-y8m6395qvx41"
]
export const siteLinkExamples = [
    "", "", "", "", "", "", "",
    "Aberdeen st", "Richmond", "VA", "5555",
    "",
    "CORP",
    "L123",
    "Username",
    "Password",
]
export const easyStorageExamples = [
    "", "", "", "", "", "", "",
    "Aberdeen st", "Richmond", "VA", "5555",
    "",
    "g3BRSpcw62mAPb5ZfL8J"
]
export const doorSwapCloudExamples = [
    "", "", "", "", "", "", "",
    "Aberdeen st", "Richmond", "VA", "5555",
    "",
    "1234",
    "Username",
    "Password",
]
