import React from "react";
import thumbnail from "../../assets/images/davincilock_thumbnail.png"
import { PageTemplate } from "../../core/layout/PageTemplate"
import gears from "../../assets/images/gears.png"
import { strings } from "../../content/strings"
import LeadFormContainer from "./components/LeadFormContainer"

const LeadForm = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.gettingStartedTitle}
            pageDescription={strings.gettingStartedTitleDescription}
            pageCanonical={strings.gettingStartedTitleCanonical}
            pageThumbnail={thumbnail}
            center>
            <LeadFormContainer />
        </PageTemplate>
    );
}

export default LeadForm
