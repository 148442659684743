import React from 'react';
import styledMui from "@mui/material/styles/styled"
import styled from 'styled-components';
import SearchField from './SearchField';
import { Checkbox, InputLabel, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Colors } from '../brand/themes';
import { DraggableWrapper } from '../common/DraggableWrapper';
import { FacilityDto } from '../../models/facility';
import { PrimaryButton } from '../../components/button';
import { useEffect, useRef, useState } from 'react';
import { strings } from '../../content/strings';
import { Switch } from "@mui/material/";
import { PBold } from '../brand/typography';

const Item = ({ text, draggable, handleClick, clicked, dragging, itemConditionalRender }: any) => {
    const handleCheckClick = (e: any) => handleClick && handleClick()

    return (
        <StyledListItem
            dragging={dragging && draggable}
        >
            <ListItemIcon>
                <Checkbox
                    style={{
                        opacity: draggable ? 0 : 1 // this makes the button invisible on the dragable copy
                    }}
                    onClick={handleCheckClick}
                    onTouchStart={handleCheckClick}
                    checked={clicked}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ style: { fontSize: "20px" } }}
                primary={text} />
            {itemConditionalRender && itemConditionalRender}
        </StyledListItem>
    )
}

export function TransferList({
    list1, list2, onChange, displayProp, itemConditionalRender
}: any) {
    const [items1, setItems1] = useState(list1);
    const [filteredItems1, setFilteredItems1] = useState(list1)
    const [entered1, setEntered1] = useState(false);
    const [selected1, setSelected1] = useState<FacilityDto[]>([]);
    const targetContainer1 = useRef<HTMLHeadingElement>(null);

    const [items2, setItems2] = useState(list2);
    const [filteredItems2, setFilteredItems2] = useState(list2)
    const [entered2, setEntered2] = useState(false);
    const [selected2, setSelected2] = useState<FacilityDto[]>([]);
    const targetContainer2 = useRef<HTMLHeadingElement>(null);

    const [showAssigned, setShowAssigned] = useState(false)

    useEffect(() => setItems1(list1), [list1])
    useEffect(() => setItems2(list2), [list2])

    const transferFromList1 = (...items: FacilityDto[]) => {
        const a = items1.filter((i: FacilityDto) => !items.includes(i));
        const b = [...items2, ...items];
        onChange(a, b)
        setSelected1(selected1.filter(s => !items.includes(s)));
    };

    const transferFromList2 = (...items: FacilityDto[]) => {
        const a = [...items1, ...items];
        const b = items2.filter((i: FacilityDto) => !items.includes(i));
        onChange(a, b)
        setSelected2(selected2.filter(s => !items.includes(s)));
    };

    const list1SelectItem = (item: FacilityDto) => {
        if (selected1.find(s => s[displayProp as keyof FacilityDto] == item[displayProp as keyof FacilityDto])) {
            setSelected1(selected1.filter(s => s !== item));
        } else {
            setSelected1([...selected1, item]);
        }
    };

    const handleSelect2 = (item: FacilityDto) => {
        if (selected2.find((s: FacilityDto) => s[displayProp as keyof FacilityDto] == item[displayProp as keyof FacilityDto])) {
            setSelected2(selected2.filter(s => s !== item));
        } else {
            setSelected2([...selected2, item]);
        }
    };

    return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 100px 1fr", gap: "8px", width: "100%" }}>

            {/** List 1 */}
            <div>
                <div style={{ display: "flex" }}>

                    <SearchField
                        data={list1}
                        searchKeys={["facilityName"]}
                        searchLabel={strings.searchForUnassigned}
                        setFilterData={setFilteredItems1}
                        fullWidth
                    />
                    <SwitchInput >
                        <InputLabel>{strings.userFacilities}</InputLabel>
                        <CenteredSpan>
                            <Switch
                                checked={showAssigned}
                                onChange={() => { setShowAssigned(!showAssigned) }} />
                            <PBold style={{ marginBlockEnd: "0px" }}>{"Show Assigned"}</PBold>
                        </CenteredSpan>
                    </SwitchInput>
                </div>
                <List
                    highlight={entered2}
                    ref={targetContainer1}>
                    {filteredItems1.map((item: FacilityDto) => {
                        const conditionalJSX = itemConditionalRender(item)
                        if (conditionalJSX && !showAssigned) {
                            return null
                        }
                        return (
                            <DraggableWrapper
                                key={item[displayProp as keyof FacilityDto]}
                                targetContainer={targetContainer2}
                                onDrop={() => transferFromList1(item)}
                                setEntered={setEntered1}>
                                <Item
                                    clicked={selected1.includes(item)}
                                    text={item[displayProp as keyof FacilityDto]}
                                    itemConditionalRender={conditionalJSX}
                                    handleClick={() => list1SelectItem(item)} />
                            </DraggableWrapper>
                        )
                    })}
                </List>
            </div>

            {/** Transfer Buttons */}
            <ButtonContainer>
                <PrimaryButton
                    disabled={!selected1.filter(s => filteredItems1.includes(s)).length}
                    onClick={() => transferFromList1(...selected1.filter(s => filteredItems1.includes(s)))}
                >{">"}</PrimaryButton>
                <PrimaryButton
                    onClick={() => transferFromList1(...filteredItems1)}
                >{">>"}</PrimaryButton>
                <PrimaryButton
                    onClick={() => transferFromList2(...filteredItems2)}
                >{"<<"}</PrimaryButton>
                <PrimaryButton
                    disabled={!selected2.filter(s => filteredItems2.includes(s)).length}
                    onClick={() => transferFromList2(...selected2.filter(s => filteredItems2.includes(s)))}
                >{"<"}</PrimaryButton>
            </ButtonContainer>

            {/** List 2 */}
            <div>
                <SearchField
                    data={list2}
                    searchKeys={["facilityName"]}
                    searchLabel={strings.searchForAssigned}
                    setFilterData={setFilteredItems2}
                    fullWidth
                />
                <List
                    highlight={entered1}
                    ref={targetContainer2}>
                    {filteredItems2.map((item: FacilityDto) => (
                        <DraggableWrapper
                            key={item[displayProp as keyof FacilityDto]}
                            targetContainer={targetContainer1}
                            onDrop={() => transferFromList2(item)}
                            setEntered={setEntered2}>
                            <Item
                                text={item[displayProp as keyof FacilityDto]}
                                clicked={selected2.includes(item)}
                                itemConditionalRender={itemConditionalRender(item)}
                                handleClick={() => handleSelect2(item)} />
                        </DraggableWrapper>
                    ))}
                </List>
            </div>
        </div>
    );
}

const List = styled.div<{ highlight?: boolean; }>(({ highlight }) => ({
    fontWeight: 700,
    display: "flex",
    flexDirection: "column",
    minHeight: "48vh",
    maxHeight: "48vh",
    // overflow: "visible",
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
        width: "12px",
    },
    "::-webkit-scrollbar-track": {
        background: Colors.white(),
    },
    "::-webkit-scrollbar-thumb": {
        background: Colors.lightergrey(),
        borderRadius: "10px",
        border: "solid 3px white"
    },
    width: "100%",
    maxWidth: "100%",
    position: "relative",
    borderRadius: "5px",
    border: highlight ? "2px solid blue" : "1px solid gray",
}));

const ButtonContainer = styled.div({
    fontWeight: 700,
    display: "flex",
    flexDirection: "column",
    // gap: "8px",
    width: "75px",
    justifySelf: "center",
    alignSelf: "center",
    ["button"]: {
        fontSize: "1rem",
        fontStretch: "ultra-condensed",
        fontWeight: "bold",
    },
    ["button:disabled"]: {
        backgroundColor: "gray"
    }
});

const StyledListItem = styledMui(ListItem)<{ dragging?: boolean }>(({ dragging }) => ({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    backgroundColor: dragging ? Colors.lightergrey() : Colors.white(),
    padding: "4px 8px",
}));

export const StyledItem = styled.div<{ dragging?: boolean }>(({ dragging }) => ({
    width: "100%",
    maxWidth: "100%",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    backgroundColor: dragging ? Colors.lightergrey() : Colors.white(),
    padding: "4px 8px",
}));

const SwitchInput = styled.span({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
});

const CenteredSpan = styled.span({
    display: "flex",
    alignItems: "center"
});
