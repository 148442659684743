import { useCallback, useState, useMemo } from "react";
import { fetchCompanyUsersAsync, fetchUsersAsync, getCompanyUsers } from "../redux/companyUsers/companyUsersSlice";
import { useAppDispatch, useAppSelector } from "./useAppSelector";
import { useCompanyID } from "./useCompanyID";
import { ICompanyUser } from "../models/ICompanyUser";
import Role from "../enums/Role";

export const useUsers = () => {
    const [activeUsersFlag, setActiveUsersFlag] = useState(true);

    const { companyIDRef } = useCompanyID()
    const dispatch = useAppDispatch();
    const { users } = useAppSelector(getCompanyUsers);

    const adminsOwnerList = users.filter((u: ICompanyUser) => u.roleID === Role.Admin || u.roleID === Role.Owner);
    const memoizedUsersList = useMemo(() => users?.filter((u: ICompanyUser) => u.isActive), [users]);

    const fetchUsers = useCallback(() => {
        if (companyIDRef.current) {
            dispatch(fetchCompanyUsersAsync(companyIDRef.current))
        } else {
            dispatch(fetchUsersAsync())
        }
    }, [companyIDRef.current])

    return { users: activeUsersFlag ? memoizedUsersList : users, fetchUsers, adminsOwnerList, activeUsersFlag, setActiveUsersFlag };
}
