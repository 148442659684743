import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react'
import ReactDOM from 'react-dom';
import App from "./App"
import { loadEnvironment } from "./utils/environment"
import { initializeErrorHandling, captureException, isInitialized } from "./utils/errorHandling"
import { Provider } from 'react-redux'
import { store } from "./redux/store"
import { fetchLoggedInUserAsync } from "./redux/auth/authSlice";

try {
  loadEnvironment();
  initializeErrorHandling();

  store.dispatch(fetchLoggedInUserAsync())
} catch (error) {
  if (isInitialized()) {
    captureException(error);
  } else {
    alert(JSON.stringify(error))
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
