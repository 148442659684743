import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import UserProfileContainer from "./components/UserProfileContainer";
import { strings } from "../../content/strings";

const UserProfilePage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.userProfile}
            pageDescription=""
            pageCanonical="www.davincilock.com/User/Profile">
            <UserProfileContainer />
        </LoggedInPageTemplate >
    );
};

export default UserProfilePage;
