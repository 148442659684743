import React, { createContext, SetStateAction, useEffect, useRef, useState } from "react"
import { Box, Dialog } from "@mui/material";
import { PrimaryButton, QuaternaryButton } from "../components/button"
import { strings } from "../content/strings";

interface IPopupProvider {
    confirm: (...vals: any[]) => Promise<boolean>;
    popup: (...vals: any[]) => Promise<boolean>;
}

export const PopupContext = createContext<IPopupProvider>({
    confirm: async () => true,
    popup: async () => true
});

export const PopupProvider = ({ children }: { children: any }) => {
    // this state is not doing anything
    const [resolve, setResolve] = useState<SetStateAction<boolean> | null>(null)
    const [open, setOpen] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [ConfirmComponent, setConfirmComponent] = useState(null)
    const [PopupComponent, setPopupComponent] = useState(null)
    const [cancelText, setCancelText] = useState("");
    const [continueText, setContinueText] = useState("");
    const resolver = useRef<((value: boolean | PromiseLike<boolean>) => void) | null>(null)

    const confirm = (ConfirmComponent: any, options?: any): Promise<boolean> => {
        setConfirmComponent(ConfirmComponent)
        if (options?.cancelText) {
            setCancelText(options.cancelText)
        }
        return new Promise((resolve) => {
            setOpen(true)
            resolver.current = resolve
        })
    }

    const popup = (Popup: any, options?: any): Promise<boolean> => {
        setPopupComponent(Popup);
        if (options?.continueText) {
            setContinueText(options.continueText)
        }
        return new Promise((resolve) => {
            setOpenPopup(true)
            resolver.current = resolve
        })
    }

    const handleConfirm = () => {
        resolver.current && resolver.current(true)
        setOpen(false)
    }

    const handleCancel = () => {
        resolver.current && resolver.current(false)
        if (open) setOpen(false)
        if (openPopup) setOpenPopup(false)

    }

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setCancelText("");
            }, 500)
        }
        if (!openPopup) {
            setTimeout(() => {
                setContinueText("");
            }, 500)
        }
    }, [open, openPopup])

    return (
        <PopupContext.Provider
            value={{ confirm, popup }}
        >
            <>
                {children}
                <Dialog open={open}>
                    {ConfirmComponent && ConfirmComponent}
                    <Box display="flex" sx={{ p: 4, gap: "16px" }}>
                        <QuaternaryButton fullWidth onClick={handleCancel}>
                            {cancelText || strings.cancel.toUpperCase()}
                        </QuaternaryButton>
                        <PrimaryButton fullWidth onClick={handleConfirm}>
                            {strings.confirm}
                        </PrimaryButton>
                    </Box>
                </Dialog>
                <Dialog open={openPopup}>
                    {PopupComponent && PopupComponent}
                    <Box display="flex" sx={{ p: 4, gap: "16px" }}>
                        <PrimaryButton fullWidth onClick={handleCancel}>
                            {continueText || strings.continue}
                        </PrimaryButton>
                    </Box>
                </Dialog>
            </>
        </PopupContext.Provider >
    )
}
